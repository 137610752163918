import { useEffect, useRef } from 'react'
import "./styles.scss"
import { useTranslation } from 'react-i18next'

const LoginCode = ({ code, setCode, codeState, onSendAgain }) => {
  const { t } = useTranslation()
  const inputRefs = useRef(new Array(4))

  useEffect(() => { inputRefs.current[0].focus() }, [])

  return <div className="login-code-container">
    <p className="row code-invalid">{codeState === "invalid" && t('login.wrongCode')}</p>
    <div className="code-container">
      <div className="row code-container-inner">
        {new Array(4).fill("").map((v, i) => (
          <input
            onChange={() => { }}
            key={`input-${i}`}
            ref={(ref) => (inputRefs.current[i] = ref)}
            className={`digit-container ${codeState === "invalid" ? "invalid" : ""} ${codeState === "valid" ? "valid" : ""}`}
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
            value={code[i] || ""}
            onKeyDown={({ key, keyCode }) => {
              const newCode = code.slice()
              if (
                (keyCode >= 48 && keyCode <= 57) ||
                (keyCode >= 96 && keyCode <= 105)
              ) {
                newCode.splice(i, 1, key)
                if (i < code.length - 1) inputRefs.current[i + 1].focus()
                else inputRefs.current[i].blur()
              } else if (keyCode === 8) {
                newCode.splice(i, 1, "")
                if (i > 0) inputRefs.current[i - 1].focus()
              }
              setCode(newCode)
            }}
          />
        ))}
      </div>
      <div className="code-resend" onClick={onSendAgain}>
        {t('login.sendNewCode')}
      </div>
    </div>
  </div>
}

export default LoginCode