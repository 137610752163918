import { default as LoginCode } from './LoginCode'
import { default as Table } from './Table'
import { default as Loading } from './Loading'
import { default as SortingComponent } from './SortingComponent'

const Shared = {
    LoginCode,
    Table,
    Loading,
    SortingComponent
}

export default Shared