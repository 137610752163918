/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Shared, Inputs, Buttons } from 'components'
import { startLoading, emit } from 'actions'
import "./styles.scss"
import { useTranslation } from 'react-i18next'

const Login = () => {
    const { i18n, t } = useTranslation();

    const [language, setLanguage] = useState(undefined)
    useEffect(() => { if (language) i18n.changeLanguage(language) }, [i18n, language])

    const [showCode, setShowCode] = useState(false)
    const [code, setCode] = useState(new Array(4).fill(''))
    const [codeState, setCodeSate] = useState(null)

    const [phoneNumber, setPhoneNumber] = useState('+359')

    const dispatch = useDispatch()
    const handleRequest = () => {
        dispatch(startLoading())
        emit({
            action: 'user/requestCode',
            payload: { phoneNumber, forceRole: "admin" },
            onSuccess: () => setShowCode(true),
            startLoading: true,
            stopLoading: true,
        })
    }
    const handleLogin = () => {
        emit({
            startLoading: true,
            stopLoading: true,
            action: "user/login",
            payload: { phoneNumber, code: code.join(''), language }
        })
    }

    useEffect(() => {
        if (!code?.includes('')) emit({
            action: "user/activateCode",
            payload: { phoneNumber, code: code.join('') },
            onSuccess: () => setCodeSate('valid'),
            onFailed: () => setCodeSate('invalid'),
        })
        else setCodeSate(null)
    }, [code])

    useEffect(() => {
        const onEnter = ({ key }) => key === 'Enter' && handleLogin()
        if (codeState === "valid") document.addEventListener('keydown', onEnter);
        else document.removeEventListener('keydown', onEnter)
        return () => document.removeEventListener('keydown', onEnter)
    }, [codeState])

    return <div className="screen-login-container row">
        <div className="screen-login-inner-container col">
            <div className="row row-language">
                <div className="icon icon-language" />
                <p>BG</p>
                <Inputs.Switch
                    labelOn=' '
                    labelOff=' '
                    value={language === 'en'}
                    onChange={() => setLanguage(language === 'en' ? 'bg' : 'en')} />
                <p>EN</p>
            </div>
            <img src={require('../../assets/images/logo.jpeg')} alt="logo" />
            <div className="screen-login-content col">
                <p className="header">{showCode ? t('login.phoneVerification') : t('login.loginToTheSystem')}</p>
                <p>{showCode ? t('login.enterCode') : t('login.enterPhoneNumber')}:</p>
                <div className="screen-login-form col">
                    {!showCode && <span>{t('login.phoneNumber')}</span>}
                    <Inputs.Text
                        value={phoneNumber || ''}
                        onChange={({ target: { value } }) => {
                            if (value.length < 4 || value.length > 13) return
                            if (!/^[+]?$|(^[+]{1}[0-9]{1,12}$)/.test(value)) return
                            setPhoneNumber(value)
                        }}
                        disabled={showCode}
                        onKeyDown={({ key }) => key === 'Enter' && phoneNumber.length === 13 && handleRequest()}
                    />
                    {showCode && (
                        <Shared.LoginCode
                            code={code}
                            setCode={(value) => setCode(value)}
                            codeState={codeState}
                            onSendAgain={() => {
                                handleRequest()
                                setCode(new Array(4).fill(''))
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="screen-login-footer">
                {showCode ? (
                    <Buttons.Raised
                        onClick={() => handleLogin()}
                        disabled={codeState !== 'valid'}
                        className="btn-login"
                        text={t('login.login')}
                    />
                ) : (
                    <Buttons.Raised
                        onClick={() => handleRequest()}
                        disabled={!phoneNumber?.includes('+359') || phoneNumber.length !== 13}
                        className="btn-code"
                        text={t('shared.continue')}
                    />
                )}
                <p>{showCode && (
                    <>{t('login.wrongNumber')}{' '}
                        <span
                            onClick={() => {
                                setShowCode(false)
                                setCode(new Array(4).fill(''))
                            }}
                            className="btn-edit">
                            {t('login.changePhoneNumber')}
                        </span>
                    </>
                )}</p>
            </div>
        </div>

    </div>
}

export default Login