import Shared from "components/Shared"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const DiscountCodeUsers = ({ close, discountCode }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return <div className="modal-discount-code-users-container">
        <div className="modal-discount-code-users-header row">
            <h2>{t('modalDiscountCodeUsers.title')}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-discount-code-users-content">
            <Shared.Table
                containerStyle={{ maxHeight: 500 }}
                data={discountCode?.usedBy}
                columns={[
                    { label: 'modalDiscountCodeUsersFields.phoneNumber', value: 'phoneNumber' },
                    { label: 'modalDiscountCodeUsersFields.fullName', value: 'fullName' },
                ]}
                renderCell={({ fullName, phoneNumber }, field) => {
                    if (field === 'fullName') return fullName
                    if (field === "phoneNumber") return <div
                        className="row"
                        style={{ cursor: 'pointer', width: '100%' }}
                        onClick={() => { navigate(`/?filter=%7B"phoneNumber"%3A"%2B${phoneNumber.slice(1)}"%7D`); close() }}>
                        <div className="icon icon-history" style={{ height: 25, width: 25, marginRight: 8 }} />
                        {phoneNumber}
                    </div>
                }}
            />
        </div>
    </div>
}

export default DiscountCodeUsers