import AutoComplete from 'react-google-autocomplete'

import "./styles.scss"
import { GOOGLE_API_KEY } from 'config/settings';
import { forwardRef } from 'react';

const Address = forwardRef(({ className = "", onPlaceSelected, ...props }, ref) => {
    return <div className={`inputs-address-container ${className}`}>
        <AutoComplete
            ref={ref}
            placeholder=''
            apiKey={GOOGLE_API_KEY}
            onPlaceSelected={(response) => onPlaceSelected(response)}
            options={{ types: [], language: "bg" }}
            {...props}
        />
    </div>
})

export default Address