import { emit } from "actions";
import { Buttons, Inputs, CartGroupForm } from "components";
import { cartStatuses, cartTypes } from "config/constants";
import { useQuery } from "hooks";
import { mapValues, pick, times } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm, Controller, useWatch, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
// import { Alerts } from "utilities";
import { useTranslation } from "react-i18next"
import "./styles.scss"

const MenuList = props => <>
    <components.MenuList {...props}>{props.children}</components.MenuList>
    {props.selectProps.components.MenuListFooter}
</>

const CartForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { control, register, setValue, handleSubmit, formState: { errors }, reset, trigger } = useForm();
    const { isSubmitted } = useFormState({ control });

    const { _id } = useQuery()
    const [cachedCart, setCachedCart] = useState()
    useEffect(() => {
        if (_id) emit({
            action: 'cart/getOne',
            payload: { _id },
            startLoading: true,
            stopLoading: true,
            onSuccess: (response) => {
                setCachedCart(response.cart)
                const { cart: { status, cartType, cartGroup, vehicle, city, ...cart } } = response || {}
                reset({
                    ...pick(cart, ['identifier', 'files', 'workingHours']),
                    status: status ? { value: status, label: t(cartStatuses[status]) } : status,
                    cartType: cartType ? { value: cartType, label: t(cartTypes[cartType]) } : cartType,
                    cartGroup: cartGroup ? { value: cartGroup._id, label: cartGroup.name } : cartGroup,
                    vehicle: vehicle ? { value: vehicle._id, label: `${vehicle.carMake} |  ${vehicle.licensePlate}` } : vehicle,
                    city: city ? { value: city._id, label: city.name } : city,
                })
            }
        })
    }, [_id, reset, t])

    const [cartGroups, setCartGroups] = useState()
    useEffect(() => { emit({ action: 'cartGroup/browse', payload: { pagination: false }, onSuccess: ({ cartGroups }) => setCartGroups(cartGroups) }) }, [])
    const { value: cartGroup } = useWatch({ control, name: 'cartGroup' }) || {}
    const selectedCartGroup = useMemo(() => cartGroups?.find(({ _id }) => _id === cartGroup), [cartGroup, cartGroups])

    const [vehicles, setVehicles] = useState()
    useEffect(() => { emit({ action: 'vehicle/list', onSuccess: ({ vehicles }) => setVehicles(vehicles) }) }, [])
    const { value: vehicle } = useWatch({ control, name: 'vehicle' }) || {}

    const [cities, setCities] = useState()
    useEffect(() => { emit({ action: 'city/browse', payload: { pagination: false }, onSuccess: ({ result: { docs } }) => setCities(docs) }) }, [])

    const { value: cartType } = useWatch({ control, name: 'cartType' }) || {}
    const workingHours = useWatch({ control, name: "workingHours", });
    const files = useWatch({ control, name: 'files' })

    useEffect(() => {
        if (!_id) setValue('workingHours', times(7).map(i => ({ isoWeekday: i + 1, open: true, opensAt: "08:00", closesAt: "22:00" })))
    }, [_id, setValue, cartType])

    const getPayload = (data) => {
        const payload = mapValues(data, (value, key) => {
            return ['status', 'cartType', 'cartGroup', 'vehicle', 'city'].includes(key) ? value?.value : value
        })
        if (payload.cartType !== 'mobile') payload.workingHours = null
        return payload
    }


    const handleCreate = data => emit({
        action: "cart/add",
        payload: getPayload(data),
        startLoading: true,
        onSuccess: ({ cart }) => navigate(`/cart?_id=${cart._id}`)
    })

    const handleEdit = data => emit({
        action: 'cart/edit',
        payload: { _id, ...getPayload(data) },
        startLoading: true,
        onSuccess: () => navigate(-1)
    })

    // const handleDelete = () => Alerts.confirm({
    //     title: t('shared.warning'),
    //     text: t('cart.deleteCart'),
    //     cancelButtonText: t('shared.cancel'),
    //     confirmButtonText: t('shared.delete'),
    //     // onSuccess: () => emit({
    //     //     action: 'cart/deleteCart',
    //     //     payload: { _id },
    //     //     startLoading: true,
    //     //     onSuccess: () => navigate(-2)
    //     // })
    // })

    const [showCartGroupForm, setShowCartGroupForm] = useState(false)

    return <div className={`screen-cart-form-container ${showCartGroupForm ? "cart-group-form" : ""}`}>
        <div className="screen-cart-form-inner-container">
            <div className="screen-cart-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('cart.cart')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        {/* <Buttons.Raised text={t('shared.delete')} className="btn-delete" onClick={handleDelete} /> */}
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-cart-form-content">
                <h2>{t('cart.generalInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>ID <span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors.identifier ? "invalid" : ''}
                            {...register("identifier", { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('cart.status')} <span className="icon icon-required" /></span>
                        <Controller
                            control={control}
                            name={`status`}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('cart.statusPlaceholder')}
                                    value={value}
                                    onChange={onChange}
                                    classNamePrefix={error ? "invalid" : ''}
                                    options={Object.entries(cartStatuses).reduce((acc, [key, value]) => [...acc, { value: key, label: t(value) }], [])}
                                />
                            }
                            rules={{ required: true }}
                        />
                    </div>
                    <div className="col">
                        <span>{t('cart.cartType')} <span className="icon icon-required" /></span>
                        <Controller
                            control={control}
                            name={`cartType`}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('cart.cartTypePlaceholder')}
                                    value={value}
                                    onChange={(v) => {
                                        onChange(v)
                                        if (v.value === 'stationary') {
                                            setValue('vehicle', null)
                                            if (cachedCart?.cartGroup) setValue('cartGroup', { value: cachedCart.cartGroup._id, label: cachedCart.cartGroup.name })
                                            if (cachedCart?.city) setValue('city', { value: cachedCart.city._id, label: cachedCart.city.name })
                                        }
                                        else if (v.value === 'mobile') {
                                            setValue('cartGroup', null)
                                            setValue('city', null)
                                            if (cachedCart?.vehicle) setValue('vehicle', { value: cachedCart.vehicle._id, label: `${cachedCart.vehicle.carMake} |  ${cachedCart.vehicle.licensePlate}` })
                                        }
                                    }}
                                    classNamePrefix={error ? "invalid" : ''}
                                    options={Object.entries(cartTypes).reduce((acc, [key, value]) => [...acc, { value: key, label: t(value) }], [])}
                                />
                            }
                            rules={{ required: true }}
                        />

                    </div>
                    {cartType === 'stationary' && <div className="col">
                        <span>{t('cart.cartGroup')} <span className="icon icon-required" /></span>
                        <Controller
                            control={control}
                            name={`cartGroup`}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('cart.cartGroupPlaceholder')}
                                    options={cartGroups?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                    value={value}
                                    onChange={onChange}
                                    components={{
                                        MenuList,
                                        MenuListFooter: <Buttons.Raised
                                            text={t('cart.addCartGroup')}
                                            onClick={() => setShowCartGroupForm(true)}
                                            style={{
                                                border: '1px dashed #0099ff',
                                                color: '#0099ff',
                                                backgroundColor: '#ffffff',
                                                margin: '10px 16px'
                                            }}
                                        />
                                    }}
                                    classNamePrefix={error ? "invalid" : ''}
                                />
                            }
                            rules={{ required: cartType === 'stationary' }}
                        />

                    </div>}
                    {cartType === 'mobile' && <>
                        <div className="col">
                            <span>{t('cart.city')} <span className="icon icon-required" /></span>
                            <Controller
                                control={control}
                                name={`city`}
                                render={({ field: { value, onChange, }, fieldState: { error } }) =>
                                    <Inputs.Dropdown
                                        placeholder={t('cart.cityPlaceholder')}
                                        options={cities?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                        value={value}
                                        onChange={onChange}
                                        classNamePrefix={error ? "invalid" : ''}
                                    />
                                }
                                rules={{ required: cartType === 'mobile' }}
                            />
                        </div>
                        <div className="col">
                            <span>{t('cart.vehicle')} <span className="icon icon-required" /></span>
                            <Controller
                                control={control}
                                name={`vehicle`}
                                render={({ field: { value, onChange, }, fieldState: { error } }) =>
                                    <Inputs.Dropdown
                                        placeholder={t('cart.vehiclePlaceholder')}
                                        options={vehicles?.map(({ _id, carMake, licensePlate }) => ({ value: _id, label: `${carMake} |  ${licensePlate}` }))}
                                        value={value}
                                        onChange={onChange}
                                        classNamePrefix={error ? "invalid" : ''}
                                    />
                                }
                                rules={{ required: cartType === 'mobile' }}
                            />
                        </div>
                    </>}
                </div>
                {cartType === 'stationary' && cartGroup && <>
                    <div className="cart-group-details row">
                        <div className="col">
                            <div className="row">
                                <span>{t('cart.address')}: <span>{selectedCartGroup?.address}</span></span>
                                <div className="line" />
                                <span>{t('cart.partnerTax')}: <span>{selectedCartGroup?.locationCommission} {selectedCartGroup?.locationCommissionType === 'fixed' ? t('shared.lv') : '%'}</span></span>
                            </div>
                            <div className="row">
                                <span style={{ marginRight: 10 }}>{t('cart.workingHours')}:</span>
                                {selectedCartGroup?.workingHours?.map((d, i) => <span key={`working-hours-${i}`} className="working-hour-single row">
                                    {i !== 0 && <div className="line" />}
                                    {moment().startOf('week').add(d.isoWeekday - 1, 'days').format('ddd')}:{" "}
                                    {d.open ? <span>{d.opensAt} - {d.closesAt}</span> : <span>{t('shared.no')}</span>}
                                </span>)}
                            </div>
                        </div>
                        <div className="icon icon-pen" onClick={() => setShowCartGroupForm(true)} />
                    </div>
                </>}
                {cartType === 'mobile' && vehicle &&
                    <div className="working-hours-container">
                        <span>{t('cart.workingHours')} <span className="icon icon-required" /></span>
                        <div className="row">
                            {times(7).map((i, index) => <div
                                key={`single-day-${index}`}
                                className="single-day-container row">
                                <span>{moment().startOf('week').add(i, 'days').format('ddd')}</span>
                                <Controller
                                    control={control}
                                    name={`workingHours.${index}.opensAt`}
                                    rules={{ required: workingHours?.[index]?.open, pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]/ }}
                                    render={({ field: { onChange, value, ...rest } }) => <Inputs.Datepicker
                                        value={value ? moment(value, 'HH:mm') : null}
                                        placeholder="-- : --"
                                        dateFormat="HH:mm"
                                        className={errors?.workingHours?.[index]?.opensAt ? "invalid" : ''}
                                        disabled={!workingHours?.[index]?.open}
                                        onChange={(value) => onChange(moment(value).format("HH:mm"))}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                    />}
                                />
                                - <Controller
                                    control={control}
                                    name={`workingHours.${index}.closesAt`}
                                    rules={{ required: workingHours?.[index]?.open, pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]/ }}
                                    render={({ field: { onChange, value, ...rest } }) => <Inputs.Datepicker
                                        value={value ? moment(value, 'HH:mm') : null}
                                        placeholder="-- : --"
                                        dateFormat="HH:mm"
                                        className={errors?.workingHours?.[index]?.closesAt ? "invalid" : ''}
                                        disabled={!workingHours?.[index]?.open}
                                        onChange={(value) => onChange(moment(value).format("HH:mm"))}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    name={`workingHours.${index}.open`}
                                    render={({ field: { value, onChange } }) => <Inputs.Switch
                                        value={value}
                                        onChange={() => {
                                            onChange(!value)
                                            if (isSubmitted) setTimeout(() => trigger('workingHours'), 100)
                                        }} />}
                                />
                                <div className="placeholder" />
                            </div>)}
                        </div>
                    </div>
                }
                {showCartGroupForm && <CartGroupForm
                    close={() => setShowCartGroupForm(false)}
                    onSuccess={({ cartGroup: c, edit }) => {
                        setValue('cartGroup', { value: c._id, label: c.name })
                        if (edit) setCartGroups(cartGroups.map((group) => group._id !== c._id ? group : c))
                        else setCartGroups([...cartGroups, c])

                    }}
                    cartGroup={selectedCartGroup}
                />}
                <div className="row row-files">
                    <h2>{t('shared.files')}</h2>
                    <Controller
                        control={control}
                        name={`files`}
                        render={({ field: { value, onChange } }) =>
                            <Buttons.Upload
                                onChange={(files) => onChange([...files, ...(value || [])])}
                                inputProps={{ multiple: true }}
                            >
                                <div className="icon icon-plus" />
                            </Buttons.Upload>
                        }
                    />
                    {files?.map(({ name, url }, i) => <div
                        key={`file-${i}`}
                        className="single-file row">
                        <a href={url} target="_blank" rel="noreferrer">{name}</a>
                        <Controller control={control} name={`files`}
                            render={({ field: { value, onChange } }) =>
                                <div className="icon icon-remove" onClick={() => {
                                    const newTasks = [...value]
                                    newTasks.splice(i, 1)
                                    onChange(newTasks)
                                }} />
                            }
                        />
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default CartForm