/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { minBy, maxBy } from 'lodash'
import { MAPBOX_API_KEY } from "config/settings";
import 'mapbox-gl/dist/mapbox-gl.css';
import { getRouteColor } from "utilities";
import "./styles.scss"

const Map = ({ route, isActive }) => {
  const coordinates = useMemo(() => route.map(({ coordinates }) => coordinates), [route])
  const points = useMemo(() => route.map(({ coordinates: [longitude, latitude], speed }, i) => i === route.length - 1 ? ({}) : ({
    'type': 'Feature',
    'properties': { 'color': getRouteColor(speed) },
    'geometry': { 'type': 'LineString', 'coordinates': [[longitude, latitude], [route?.[i + 1]?.coordinates[0], route?.[i + 1]?.coordinates[1],]] }
  })), [route])

  const [hasBeenMoved, setHasBeenMoved] = useState(false)
  const [hasBeenZoomed, setHasBeenZoomed] = useState(false)

  const map = useRef(null)
  const mapContainer = useRef(null)
  const markerOrigin = useRef(null)
  const markerDestination = useRef(null)
  const markerActive = useRef(null)

  useEffect(() => {
    if (map.current) return
    const center = ["longitude", 'latitude'].map(f => (minBy(coordinates, (c) => c[0])[0] + maxBy(coordinates, (c) => c[1])[1]) / 2)
    map.current = new mapboxgl.Map({ accessToken: MAPBOX_API_KEY, container: mapContainer.current, style: 'mapbox://styles/mapbox/streets-v11', center: center, zoom: 8, attributionControl: false })
    map.current.addControl(new mapboxgl.FullscreenControl());
  }, [])

  useEffect(() => {
    if (!map.current) return
    map.current.on('load', () => {
      markerOrigin.current = new mapboxgl.Marker(getMarker("origin")).setLngLat(coordinates[0]).addTo(map.current);
      markerDestination.current = new mapboxgl.Marker(getMarker('destination')).setLngLat(coordinates[coordinates.length - 1])
      markerActive.current = new mapboxgl.Marker(getMarker('active')).setLngLat(coordinates[coordinates.length - 1])

      map.current.addSource('route', { 'type': 'geojson', 'lineMetrics': true, 'data': { 'type': 'FeatureCollection', 'features': points }, tolerance: 0.00001 })
      map.current.addLayer({ 'id': 'route', 'type': 'line', 'source': 'route', 'layout': { 'line-join': 'round', 'line-cap': 'round' }, 'paint': { 'line-color': ['get', 'color'], 'line-width': 8, } })

      if (!isActive) {
        const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
        for (const coord of coordinates) { bounds.extend(coord); }
        map.current.fitBounds(bounds, { padding: 50 });
        markerDestination.current.addTo(map.current);

      } else {
        map.current.flyTo({ center: coordinates[coordinates.length - 1], zoom: 15 })
        markerActive.current.addTo(map.current);
      }
    })
    map.current.on('drag', () => setHasBeenMoved(true))
    map.current.on('zoom', () => setHasBeenZoomed(true))
  }, [])

  useEffect(() => {
    if (!map.current) return
    if (!map.current.getSource('route')) return
    map.current.getSource('route').setData({ 'type': 'FeatureCollection', 'features': points });
    markerActive.current.setLngLat(coordinates[coordinates.length - 1])
    if (!hasBeenMoved) map.current.flyTo({ center: coordinates[coordinates.length - 1] })
    if (!hasBeenMoved && !hasBeenZoomed) map.current.flyTo({ zoom: 15 })
  }, [points])

  useEffect(() => {
    if (!map.current) return
    if (!map.current.getSource('route')) return
    if (!isActive) {
      markerDestination.current.setLngLat(coordinates[coordinates.length - 1]).addTo(map.current);
      markerActive.current.remove();
    }
  }, [isActive])

  const getMarker = (type) => {
    const el = document.createElement('div')
    el.classList.add('marker', `marker-${type}`)
    return el
  }


  let animationCounter = 0
  let isAnimating = false
  let stopStep = 0

  const handleAnimation = () => {
    if (!isAnimating) {
      if (stopStep) {
        animationCounter = stopStep
        stopStep = 0
      }
      animateLine()
    }
    else {
      stopStep = animationCounter
      animationCounter = points.length
    }
  }

  const animateLine = () => {
    isAnimating = true
    document.getElementById("btn-play-control").classList = 'icon icon-pause'
    if (animationCounter < points.length) {
      const newPoints = [...points].slice(0, animationCounter)
      map.current.getSource('route').setData({ 'type': 'FeatureCollection', 'features': newPoints });
      animationCounter++
      requestAnimationFrame(animateLine);
    } else {
      isAnimating = false
      document.getElementById("btn-play-control").classList = 'icon icon-play'
      animationCounter = 0
    }
  }

  const handleRecenter = () => {
    setHasBeenMoved(false)
    map.current.flyTo({ center: coordinates[coordinates.length - 1], zoom: 15 })
  }

  return <div className="sessions-map-container" style={{ height: '100%', width: '100%' }}>
    <div ref={mapContainer} className="map-container" style={{ height: '100%', borderRadius: "6px" }} />
    <div className="row row-buttons">
      <div className="btn-play row" onClick={handleAnimation}><div id="btn-play-control" className="icon icon-play" /></div>
      {isActive ? <div className="btn-center row" onClick={handleRecenter}><div className="icon icon-center" /></div> : null}
    </div>
  </div>
}

export default Map