import { isString } from "lodash"
import "./styles.scss"

const RadioButtons = ({ className, col, options, value, reversed, disabled, onChange }) => {
    return <div className={`inputs-radio-buttons-container ${className} ${col ? 'col' : 'row'}`}>
        {options?.map((opt, i) => <div
            key={`single-radio-button-${i}`}
            className={`single-radio-button row ${value === (opt?.value ?? opt) && 'selected'} ${reversed && 'reversed'} ${disabled && 'disabled'}`}
            onClick={() => {
                if (disabled || !onChange || value === (opt?.value || opt)) return
                onChange(opt?.value || opt)
            }}
        >
            <div className="radio-button-outer" style={{ borderColor: opt?.color }} >
                <div className={`radio-button-inner `} style={{ backgroundColor: value === opt.value && opt?.color }} />
            </div>
            <div className="label-container row">
                {opt?.icon && <div className="button-image" style={{ backgroundImage: opt?.icon }} />}
                <p>{isString(opt) ? opt : opt?.label || opt?.value}</p>
            </div>
        </div>)}
    </div>
}

export default RadioButtons