import { setGeneralFields } from "actions"

export const login = ({ token, user }) => (dispatch) => {
    window.localStorage.clear()
    localStorage.setItem('token', token)
    dispatch(setGeneralFields({ user }))
    window.location.href = '/'
}

export const logout = () => {
    localStorage.clear()
    window.location.href = '/login'
}