import { emit, setBusinessClientsFields } from "actions"
import { Buttons, Inputs } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { useQuery } from "hooks"
import moment from "moment"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const BusinessClient = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { coverPhoto } = useSelector(({ general }) => general?.user) || {}
    const { organization } = useSelector(({ businessClients }) => businessClients) || {}
    const { name, _clients, _comments, files } = organization || {}

    const { _id } = useQuery()
    const dispatch = useDispatch()
    const fetch = useCallback(() => emit({
        action: 'organization/getOne',
        payload: { _id },
        startLoading: true,
        stopLoading: true,
    }), [_id])

    useEffect(() => {
        fetch();
        return () => dispatch(setBusinessClientsFields({ organization: {} }))
    }, [dispatch, fetch])

    const renderMessage = ({ _id, createdAt, text, user }, i) => <Fragment key={_id}>
        <div className={`message ${isEdit && isEdit !== _id && 'is-edit'}`}>
            <p>{user?.fullName} <span className="time">{moment(createdAt).format("HH:mm")}</span></p>
            <div className="message-content row">
                <div className={`placeholder logo`}
                    style={{ backgroundImage: `url(${user?.coverPhoto || DEFAULT_AVATAR_URL})` }}
                />
                <p className="text">{text}</p>
                <Popup
                    className='popup-screen-reservation-notes-options-container'
                    trigger={<div className="icon icon-options" />}
                    position="left"
                    offsetY={5}
                >
                    {close => <>
                        <div className="popup-screen-reservation-notes-options-header row">
                            <h3>{t('shared.options')}</h3>
                            <div className="icon icon-close" onClick={close} />
                        </div>
                        <div className="popup-screen-reservation-notes-options-content">
                            <div className="row row-action" onClick={() => {
                                inputRef.current.setText(text)
                                setIsEdit(_id)
                                close()
                            }}>
                                <div className="icon icon-pen" />
                                <p>{t('shared.edit')}</p>
                            </div>
                            <div className="line" />
                            <div className="row row-action" onClick={() => {
                                handleDeleteComment(_id)
                                close()
                            }}>
                                <div className="icon icon-delete" />
                                <p>{t('shared.delete')}</p>
                            </div>
                        </div>
                    </>}
                </Popup>

            </div>
        </div>
        {(!moment(createdAt).isSame(moment(_comments?.[i + 1]?.createdAt), 'day') || i === _comments.length - 1) && (
            <div className="row days-divider">
                <p>{moment(createdAt).isSame(moment(), 'day') ? t('shared.yesterday') : moment(createdAt).isSame(moment().subtract(1, 'd'), 'day') ? t('shared.today') : moment(createdAt).format('DD.MM.YYYY')}</p>
                <span className="line" />
            </div>
        )}
    </Fragment>


    const inputRef = useRef(null)
    const [isEdit, setIsEdit] = useState("")
    const handleAddComment = (comment) => emit({
        action: 'organization/addComment', payload: { organization: _id, comment },
        startLoading: true, stopLoading: true, onSuccess: () => fetch()
    })
    const handleEditComment = (comment) => emit({
        action: 'organization/editComment', payload: { _id: isEdit, comment },
        startLoading: true, stopLoading: true, onSuccess: () => { fetch(); setIsEdit("") }
    })
    const handleDeleteComment = (_id) => emit({
        action: 'organization/deleteComment', payload: { _id },
        startLoading: true, stopLoading: true, onSuccess: () => fetch()
    })

    return <div className="screen-business-client-container">
        <div className="screen-business-client-inner-container col">
            <div className="screen-business-client-header row">
                <Buttons.Back text={`${t('businessClient.overview')} - ${name}`} />
                <Buttons.Raised text={t('shared.edit')} onClick={() => navigate(`/business-client/form?_id=${_id}`)} />
            </div>
            <div className="screen-business-client-content">
                <h2>{t('employeesInformation')}</h2>
                {_clients?.map(({ _id, fullName, phoneNumber }) => <div key={_id} className="row row-user">
                    <div className="col">
                        <span>{t('businessClient.fullName')}</span>
                        <Inputs.Text value={fullName} disabled />
                    </div>
                    <div className="col">
                        <span>{t('businessClient.phone')}</span>
                        <Inputs.Text value={phoneNumber} disabled />
                    </div>
                    <div
                        className="icon icon-history"
                        onClick={() => navigate(`/?filter=%7B"phoneNumber"%3A"%2B${phoneNumber.slice(1)}"%7D`)}
                    />
                </div>)}
                <div className="divider" />
                <div className="row row-files">
                    <h2>{t('shared.files')}</h2>
                    {files?.map(({ url, name }, i) => <a key={i} href={url} target="_blank" rel="noreferrer">{name}</a>)}
                </div>
                <div className="divider" />
                <div className="comments-container">
                    <h2>{t('shared.notes')}</h2>
                    <Inputs.ChatInput
                        ref={inputRef}
                        coverPhoto={coverPhoto || DEFAULT_AVATAR_URL}
                        hideFileUpload
                        handleSend={({ text }) => {
                            if (isEdit) handleEditComment(text)
                            else handleAddComment(text)
                        }}
                    />
                    <div className="comments-content col">
                        {_comments?.map(renderMessage)}
                    </div>
                </div>

            </div>
        </div>
    </div>
}

export default BusinessClient