import { DEFAULT_AVATAR_URL } from "config/settings"
import { useRef } from "react"
import { uploadFiles } from 'utilities'
import "./styles.scss"

const CoverPhoto = ({ value, onChange, inputProps, disabled }) => {

    const uploadRef = useRef()
    const handleUpload = async ({ target: { files } }) => {
        const [uploaded] = await uploadFiles(files)
        if (onChange) onChange(uploaded.location)
    }


    return <div className={`inputs-cover-photo-container ${disabled ? 'disabled' : ''}`} onClick={() => uploadRef.current.click()}>
        {value
            ? <>
                <div className="icon icon-avatar" style={{ backgroundImage: `url(${value})` }} />
                <div className="icon icon-camera no-active" />
            </> : <div className="icon icon-add-photo" style={{ backgroundImage: `url(${DEFAULT_AVATAR_URL})` }} />}
        <input
            ref={uploadRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleUpload}
            disabled={disabled}
            {...inputProps}
        />
    </div>
}

export default CoverPhoto