const SERVERS = {
    DEV: 'https://geowash-dev.herokuapp.com',
    PROD: 'https://geowash.herokuapp.com',
    MEXUS: 'https://tests.mexus.org',
    LOCAL: "http://localhost:5000",
}

export const URL = process.env.REACT_APP_URL || SERVERS.DEV

export const s3Credentials = {
    bucketName: 'dev-fidweb',
    dirName: 'geowash-web',
    region: 'eu-central-1',
    accessKeyId: 'AKIA5L7PZ7W5KYJIFZTM',
    secretAccessKey: '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',
}

export const MAPBOX_API_KEY = 'pk.eyJ1IjoiaW9ua28iLCJhIjoiY2wwcnQzd205MDUxZDNqczBtZ2YxeXdhNSJ9.obIklcld90kwyuwcNNSWjA'
export const GOOGLE_API_KEY = 'AIzaSyDdBW7VTixyC_HnHKR_f4qRYf7nVNWxaQs'

export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL || 'https://customer-support-dev.herokuapp.com'

export const DEFAULT_AVATAR_URL = 'https://amarant2.s3.eu-central-1.amazonaws.com/geowashResources/default-user-avatar.jpg'
