import S3FileUpload from 'react-s3';
import { nanoid } from 'nanoid'
import * as XLSX from 'xlsx'
import { s3Credentials } from 'config/settings';
import { isEmpty, isString } from 'lodash';
import moment from 'moment';
export const isAuthenticated = localStorage.getItem('token') !== null
window.Buffer = window.Buffer || require("buffer").Buffer;

export const getRouteColor = (speed) => {
    if (speed > 140) return "#D814A9"
    else if (speed > 89) return "#0D242E"
    else if (speed > 59) return "#FF5800"
    else if (speed > 29) return "#103CB5"
    else return "#0BD84F"
}

export const xlsxExport = ({ fields, data, render, fileName = 'export', t }) => {
    const wb = XLSX.utils.book_new()
    const [headerLabels, headerValues] = fields.reduce(([labels, values], { label, value }) => [[...labels, t(label)], [...values, value]], [[], []])
    const formattedData = data.map((row) => headerValues.map((key) => render(row, key, { forExport: true, t })))
    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([headerLabels, ...formattedData]))
    XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export const uploadFiles = (files) => {
    const promises = []
    Array.from(files).forEach((f) =>
        promises.push(S3FileUpload.uploadFile(new File([f], f?.name || nanoid(), {
            type: f?.type,
            lastModified: f?.lastModified || new Date(),
        }), s3Credentials))
    )
    return Promise.all(promises)
}

export const mapSearchQuery = (sort, filter, availableFilters) => {
    const payload = isEmpty(sort) ? {} : { sort }
    if (!availableFilters) return payload
    Object.entries(filter).forEach(([key, value]) => {
        if (availableFilters?.[key]?.auto) payload.otherParams = { ...payload.otherParams, [key]: isString(value) ? value.trim() : value }
        else if (availableFilters?.[key]?.type === 'date') payload[key] = { $gte: moment(value?.startDate).toDate(), $lte: moment(value?.endDate).endOf('day').toDate() }
        else if (availableFilters?.[key]?.type === 'priceRange') payload[key] = { $gte: value?.min, $lte: value?.max }
        else payload[key] = isString(value) ? value.trim() : value
    })
    return payload
}

const deg2rad = (deg) => deg * (Math.PI / 180)

export const getDistanceFromLatLonInKm = (latitude1, longitude1, latitude2, longitude2) => {
    const earthRadius = 6371
    const dLat = deg2rad(latitude2 - latitude1)
    const dLon = deg2rad(longitude2 - longitude1)
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(latitude1)) * Math.cos(deg2rad(latitude2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = earthRadius * c
    return d
}