import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import { Header, Modal, Overlay, Shared } from 'components'
import {
    Reservation,
    Reservations,
    ReservationChat,
    ReservationNotes,
    ReservationForm,
    Cart,
    CartForm,
    CartHistory,
    Carts,
    Employee,
    EmployeeForm,
    Employees,
    Fleet,
    Login,
    Services,
    Session,
    User,
    UserForm,
    Users,
    Vehicle,
    VehicleForm,
    Support,
    DiscountCodes,
    DiscountCode,
    DiscountCodeForm,
    BusinessClients,
    BusinessClient,
    BusinessClientForm
} from 'screens'
import { isAuthenticated } from 'utilities'


function PrivateRoute({ children }) {
    let location = useLocation()
    if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />;
    return children;
}
function AuthRoute({ children }) {
    let location = useLocation()
    if (isAuthenticated) return <Navigate to="/" state={{ from: location }} replace />;
    return children;
}

const RoutesComp = () => {
    return <>
        {isAuthenticated && <Header />}
        <Routes>
            <Route exact path="/login" element={<AuthRoute><Login /></AuthRoute>} />
            <Route exact path="/" element={<PrivateRoute><Reservations /></PrivateRoute>} />
            <Route exact path="/reservation" element={<PrivateRoute><Reservation /></PrivateRoute>} />
            <Route exact path="/reservation/form" element={<PrivateRoute><ReservationForm /></PrivateRoute>} />
            <Route exact path="/reservation/chat" element={<PrivateRoute><ReservationChat /></PrivateRoute>} />
            <Route exact path="/reservation/notes" element={<PrivateRoute><ReservationNotes /></PrivateRoute>} />
            <Route exact path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} />
            <Route exact path="/cart/form" element={<PrivateRoute><CartForm /></PrivateRoute>} />
            <Route exact path="/cart/history" element={<PrivateRoute><CartHistory /></PrivateRoute>} />
            <Route exact path="/carts" element={<PrivateRoute><Carts /></PrivateRoute>} />
            <Route exact path="/services" element={<PrivateRoute><Services /></PrivateRoute>} />
            <Route exact path="/employee" element={<PrivateRoute><Employee /></PrivateRoute>} />
            <Route exact path="/employee/form" element={<PrivateRoute><EmployeeForm /></PrivateRoute>} />
            <Route exact path="/employees" element={<PrivateRoute><Employees /></PrivateRoute>} />
            <Route exact path="/fleet" element={<PrivateRoute><Fleet /></PrivateRoute>} />
            <Route exact path="/user" element={<PrivateRoute><User /></PrivateRoute>} />
            <Route exact path="/user/form" element={<PrivateRoute><UserForm /></PrivateRoute>} />
            <Route exact path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route exact path="/vehicle" element={<PrivateRoute><Vehicle /></PrivateRoute>} />
            <Route exact path="/vehicle/form" element={<PrivateRoute><VehicleForm /></PrivateRoute>} />
            <Route exact path="/session" element={<PrivateRoute><Session /></PrivateRoute>} />
            <Route exact path="/discount-code" element={<PrivateRoute><DiscountCode /></PrivateRoute>} />
            <Route exact path="/discount-code/form" element={<PrivateRoute><DiscountCodeForm /></PrivateRoute>} />
            <Route exact path="/discount-codes" element={<PrivateRoute><DiscountCodes /></PrivateRoute>} />
            <Route exact path="/business-client" element={<PrivateRoute><BusinessClient /></PrivateRoute>} />
            <Route exact path="/business-client/form" element={<PrivateRoute><BusinessClientForm /></PrivateRoute>} />
            <Route exact path="/business-clients" element={<PrivateRoute><BusinessClients /></PrivateRoute>} />
            <Route exact path="/support" element={<PrivateRoute><Support /></PrivateRoute>} />
        </Routes>
        <Modal />
        <Overlay />
        <Shared.Loading />
    </>
}

export default RoutesComp