import { createSlice } from '@reduxjs/toolkit'
import { pick } from 'lodash'

const initialState = {
    vehicles: [],
    vehicle: {},
    session: {}
}

export const vehicles = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        setVehiclesField: (state, { payload }) => ({ ...state, ...payload }),
        setVehicles: (state, { payload: { vehicles } }) => ({ ...state, vehicles }),
        setVehicleInUse: (state, { payload: { workDay } }) => ({ ...state, vehicles: state.vehicles.map((v) => v._id !== workDay.vehicle ? v : { ...v, workingStatus: 'inUse' }) }),
        setVehicleNotInUse: (state, { payload: { workDay } }) => ({ ...state, vehicles: state.vehicles.map((v) => v._id !== workDay.vehicle ? v : { ...v, workingStatus: 'available' }) }),
        startSession: (state, { payload: { drivingSession } }) => ({ ...state, vehicles: state.vehicles.map((v) => v._id !== drivingSession.vehicle ? v : { ...v, lastDrivingSession: drivingSession }) }),
        endSession: (state, { payload: { drivingSession } }) => ({
            ...state,
            vehicles: state.vehicles.map((v) => v._id !== drivingSession.vehicle ? v : { ...v, lastDrivingSession: drivingSession }),
            session: state?.session?._id !== drivingSession._id ? state.session : { ...state.session, ...pick(drivingSession, ['averageSpeed', 'distance', 'drivingEnd', 'endAddress', 'maximumSpeed', 'route', 'updatedAt']) }
        }),
        setVehicle: (state, { payload }) => ({ ...state, vehicle: payload.vehicle }),
        setSessions: (state, { payload }) => ({
            ...state, vehicle: {
                ...state.vehicle,
                sessions: payload.page > 1 ? [...state.vehicle.sessions, ...payload.drivingSessions] : payload.drivingSessions,
                ...pick(payload, ['hasNextPage', 'nextPage', 'stats'])
            }
        }),
        setSession: (state, { payload: { drivingSession } }) => ({ ...state, session: drivingSession }),
        updateLocations: (state, { payload }) => {
            return ({
                ...state,
                vehicles: state.vehicles.map((v) => {
                    return v._id !== payload.vehicle ? v : { ...v, lastDrivingSession: { ...v.lastDrivingSession, lastLocation: payload.lastLocation } }
                }),
                session: state?.session?._id !== payload.drivingSession ? state.session : {
                    ...state.session,
                    distance: payload.request.distance,
                    averageSpeed: payload.request.averageSpeed,
                    maximumSpeed: payload.request.maximumSpeed,
                    lastLocation: payload.lastLocation,
                    route: [...state.session.route, { ...pick(payload, ['batteryLevel', 'speed', 'timestamp']), ...payload.lastLocation }]
                }
            })
        },
        addReservationToVehicle: (state, { payload: { reservation } }) => ({
            ...state,
            vehicles: state.vehicles.map((v) => {
                return v._id !== reservation.vehicle ? v : { ...v, _reservations: [...v._reservations, reservation] }
            })
        }),
        updateReservationToVehicle: (state, { payload: { reservation } }) => ({
            ...state,
            vehicles: state.vehicles.map((v) => {
                return v._id !== reservation.vehicle ? v : { ...v, _reservations: v._reservations.map((r) => r._id !== reservation._id ? r : reservation) }
            })
        }),
        removeReservationToVehicle: (state, { payload: { reservation } }) => ({
            ...state,
            vehicles: state.vehicles.map((v) => {
                return v._id !== reservation.vehicle ? v : { ...v, _reservations: v._reservations.filter(({ _id }) => _id !== reservation._id) }
            })
        })
    },
})

export const {
    setVehiclesField,
    setVehicles,
    setVehicle,
    updateLocations,
    setSessions,
    setSession,
    setVehicleInUse,
    setVehicleNotInUse,
    startSession,
    endSession,
    addReservationToVehicle,
    updateReservationToVehicle,
    removeReservationToVehicle,
} = vehicles.actions

export default vehicles.reducer