import { emit, setGeneralFields } from "actions";
import { Buttons, Inputs } from "components"
import { useQuery } from "hooks";
import { pick } from "lodash";
import { useEffect } from "react";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alerts } from "utilities";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const UserForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm({});
    const { fields, append, remove, } = useFieldArray({ control, name: "users" })
    const users = useWatch({ control, name: "users", });

    const { _id, myProfile } = useQuery()
    useEffect(() => {
        if (_id) emit({
            action: 'user/info',
            payload: { _id },
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ user }) => {
                setValue('users', [{ ...pick(user, ['coverPhoto', 'fullName', 'role', 'phoneNumber', 'email']) }])
            }
        })
        else setValue('users', [{ phoneNumber: "+359" }])
    }, [_id, setValue])

    const handleCreate = payload => emit({
        action: 'user/addMany',
        payload,
        startLoading: true,
        onSuccess: () => navigate(-1)
    })

    const handleEdit = (payload) => emit({
        action: 'user/edit',
        payload: { _id, ...payload.users[0] },
        startLoading: true,
        onSuccess: ({ user }) => {
            navigate(-1)
            if (myProfile) dispatch(setGeneralFields({ user }))
        }
    })
    const handleDelete = () => Alerts.confirm({
        title: t('shared.warning'),
        text: t('user.deleteUser'),
        cancelButtonText: t('shared.cancel'),
        confirmButtonText: t('shared.delete'),
        onSuccess: () => emit({
            action: 'user/deleteUser',
            payload: { _id },
            startLoading: true,
            onSuccess: () => navigate(-2)
        })
    })


    return <div className="screen-user-form-container">
        <div className="screen-user-form-inner-container col">
            <div className="screen-user-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('user.user')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        <Buttons.Raised text={t('shared.delete')} className="btn-delete" onClick={handleDelete} />
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-user-form-content">
                {fields.map((field, index) => <div key={`user-${index}`} className="screen-user-form">
                    <div className="row row-header">
                        <h2>{t('user.singleUser')} {index + 1}</h2>
                        {index > 0 && <div className="icon icon-remove" onClick={() => remove(index)} />}
                    </div>
                    <div className="row row-form">
                        <Controller
                            control={control}
                            name={`users.${index}.coverPhoto`}
                            render={({ field: { value, onChange } }) => <Inputs.CoverPhoto
                                value={value}
                                onChange={onChange}
                            />}
                        />
                        <div className="col">
                            <span>{t('user.fullName')}</span>
                            <Inputs.Text
                                key={field.id}
                                className={errors.users?.[index]?.fullName ? "invalid" : ''}
                                {...register(`users.${index}.fullName`, { required: true, })}
                            />
                        </div>
                        <div className="col">
                            <span>{t('user.role')}</span>
                            <Controller
                                control={control}
                                name={`users.${index}.role`}
                                render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.RadioButtons
                                    value={value}
                                    onChange={onChange}
                                    className={error ? "invalid" : ''}
                                    options={[{ label: t('users.admin'), value: "admin" }, { label: t('users.team'), value: "cartOperator" }]}
                                />}
                                rules={{ required: true }}
                            />
                        </div>
                        <div className="col">
                            <span>{t('user.phoneNumber')}</span>
                            <Inputs.Text
                                key={field.id}
                                placeholder="+359"
                                className={errors.users?.[index]?.phoneNumber ? "invalid" : ''}
                                {...register(`users.${index}.phoneNumber`, {
                                    required: true,
                                    minLength: 13,
                                    maxLength: 13,
                                    pattern: /^[+]?$|(^[+]{1}[0-9]{1,12}$)/
                                })}
                            />
                        </div>
                        <div className="col">
                            <span>{t('user.email')}</span>
                            <Inputs.Text
                                key={field.id}
                                className={errors.users?.[index]?.email ? "invalid" : ''}
                                {...register(`users.${index}.email`, { required: true, })}
                            />
                        </div>
                    </div>
                    {users?.[index]?.role && <div className="row row-role">
                        {t('user.roleDescription')}
                    </div>}
                </div>)}
            </div>
            {!_id && <div className="screen-user-form-footer row">
                <Buttons.Raised text={t('user.addUser')} onClick={() => append({ phoneNumber: "+359" })} />
            </div>}
        </div>
    </div>
}

export default UserForm