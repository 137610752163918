import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    discountCampaigns: [],
    nextPage: 1,
    hasNextPage: true,
    totalDocs: 0,
    discountCampaign: {}
}

export const discountCodes = createSlice({
    name: 'discountCodes',
    initialState,
    reducers: {
        setDiscountCodesFields: (state, { payload }) => ({ ...state, ...payload }),
        setDiscountCodes: (state, { payload }) => {
            const discountCampaigns = payload.page > 1 ? [...state.discountCampaigns, ...payload.discountCampaigns] : payload.discountCampaigns
            return { ...state, ...payload, discountCampaigns, }
        },
    }
})

export const {
    setDiscountCodesFields,
    setDiscountCodes
} = discountCodes.actions

export default discountCodes.reducer