import { useSelector, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup';
import { setModal } from 'actions'
import 'reactjs-popup/dist/index.css';
import "./styles.scss"
import { Modals } from '.';

const Modal = () => {
    const { type, isOpen, props: allProps } = useSelector(({ general }) => general.modal)
    const dispatch = useDispatch()

    const props = { ...allProps, close: () => dispatch(setModal({ isOpen: false })) }

    const renderContent = () => {
        switch (type) {
            case "DiscountCodeUsers":
                return <Modals.DiscountCodeUsers {...props} />
            case "UploadOdometerData":
                return <Modals.UploadOdometerData {...props} />
            case "UploadOdometerDataReason":
                return <Modals.UploadOdometerDataReason {...props} />
            case "ReservationForward":
                return <Modals.ReservationForward {...props} />
            default:
                return null
        }
    }

    return <Popup
        modal
        className={`modal-container ${type}`}
        open={isOpen}
        onClose={() => {
            dispatch(setModal({ isOpen: false }))
            if (props?.onRequestClose) props.onRequestClose()
        }}
    >{renderContent()}</Popup>
}

export default Modal