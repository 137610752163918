import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './actions'
import App from 'App';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";
import translationBG from "./translations/bg.json";

import './index.scss';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    bg: { translation: translationBG },
  },
  fallbackLng: "bg",
});

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)