import { useNavigate } from "react-router-dom"
import "./styles.scss"

const Back = ({ children, text, onClick }) => {
    const navigate = useNavigate()
    return <div className="button-back-container row" onClick={() => {
        if (onClick) onClick()
        else navigate(-1)
    }}>
        <div className="icon icon-back no-active" />
        {children || text || ''}
    </div>
}

export default Back