import { emit } from "actions"
import { Buttons, Shared } from "components"
import { cartHistoryColumns } from "config/constants"
import { useQuery } from "hooks"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { renderCell } from "utilities"
import { useTranslation } from "react-i18next"
import "./styles.scss"

const CartHistory = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { _id } = useQuery()
    const [history, setHistory] = useState()
    useEffect(() => {
        emit({
            action: 'cart/history',
            payload: { _id },
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ cartHistory }) => setHistory(cartHistory)
        })
    }, [_id])

    return <div className="screen-cart-history-container">
        <div className="screen-cart-history-inner-container col">
            <div className="screen-cart-history-header">
                <Buttons.Back text={t('cart.history')} />
            </div>
            <div className="screen-cart-history-content">
                <Shared.Table
                    fixedWidth
                    columns={cartHistoryColumns}
                    data={history}
                    renderCell={(history, field) => renderCell.cartHistory(history, field, { navigate, t })}
                />
            </div>
        </div>
    </div>
}

export default CartHistory