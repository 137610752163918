import { useEffect, useRef } from "react"
import "./styles.scss"

const Raised = ({ disabled, text, children = "text", className = "", onClick = () => { }, ...props }) => {
    const buttonRef = useRef(null)

    const ripple = ({ clientX, clientY, target: { offsetTop, offsetLeft } }) => {
        const circle = document.createElement("span");
        circle.classList.add("circle");
        circle.style.top = `${clientY - offsetTop}px`;
        circle.style.left = `${clientX - offsetLeft}px`;
        buttonRef.current.appendChild(circle);
        setTimeout(() => { circle.remove(); }, 500);
    }

    useEffect(() => {
        const button = buttonRef.current
        button.addEventListener('click', ripple)
        return () => button.removeEventListener('click', ripple)
    }, [])

    return <div
        ref={buttonRef}
        className={`buttons-raised-container ${disabled ? 'disabled' : ''} ${className}`}
        onClick={onClick}
        {...props}
    >
        {text || children}
    </div>
}

export default Raised