import { default as DiscountCodeUsers } from './DiscountCodeUsers'
import { default as UploadOdometerData } from './UploadOdometerData'
import { default as UploadOdometerDataReason } from './UploadOdometerDataReason'
import { default as ReservationForward } from './ReservationForward'

export const Modals = {
    DiscountCodeUsers,
    UploadOdometerData,
    UploadOdometerDataReason,
    ReservationForward,
}

export { default } from './Modal'