import { Inputs, Buttons } from 'components'
import { useQuery } from "hooks"
import { isEmpty, omit } from "lodash"
import moment from 'moment'
import React, { useMemo, useState } from "react"
import "./styles.scss"
import { useTranslation } from 'react-i18next'


const SortingComponent = ({ column, hide, availableSort = [], availableFilters = [], keyMap = {} }) => {
  const { t } = useTranslation()
  const sortButtons = [{ label: t('shared.ascending'), value: '1' }, { label: t('shared.descending'), value: '-1' }]
  const key = useMemo(() => Object.entries(keyMap).find((arr) => arr.includes(column))?.at(0) || column, [column, keyMap])

  const { sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChange } = useQuery()
  const [sort, setSort] = useState(JSON.parse(sortParam)[key] || {})
  const [filter, setFilter] = useState(JSON.parse(filterParam) || {})
  const [query, setQuery] = useState('')

  const allowFilter = useMemo(() => Object.keys(availableFilters)?.map((key) => keyMap[key] || key)?.includes(column), [availableFilters, column, keyMap])
  const allowSort = useMemo(() => availableSort?.map((key) => keyMap[key] || key)?.includes(column), [availableSort, column, keyMap])

  const handleApply = () => {
    if (!isEmpty(filter)) handleUrlChange({ filter: JSON.stringify(filter) })
    else handleUrlChange({ filter: null })
    if (!isEmpty(sort)) handleUrlChange({ sort: JSON.stringify({ [key]: sort }) })
    else if (isEmpty(omit(JSON.parse(sortParam), key))) handleUrlChange({ sort: null })
  }
  const renderFilter = () => {
    const { type, single, min, max } = availableFilters[key]
    switch (type) {
      case 'regex':
        return <Inputs.Text
          placeholder="Search:"
          value={filter[key] || ''}
          onChange={({ target: { value } }) => setFilter({ ...filter, [key]: value })}
        />
      case 'date':
        const { startDate, endDate } = filter[key] || {}
        return <Inputs.Datepicker
          selected={startDate ? moment(startDate).toDate() : null}
          startDate={startDate ? moment(startDate).toDate() : null}
          endDate={endDate ? moment(endDate).toDate() : null}
          selectsRange
          onChange={([startDate, endDate]) => setFilter({ ...filter, [key]: { startDate, endDate } })}
          inline
        />
      case 'dropdown':
        return single ? <>
          <Inputs.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.RadioButtons
            value={filter[key]}
            onChange={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            options={availableFilters[key]?.values?.filter((option) => !query || option?.includes(query))}
            col
          />
        </> : <>
          <Inputs.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.Checkboxes
            value={filter[key]}
            onChange={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            options={availableFilters[key]?.values?.filter((option) => !query || option?.includes(query))}
            col
          />
        </>
      case 'idDropdown':
        return single ? <>
          <Inputs.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.RadioButtons
            value={filter[key]}
            onChange={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            options={availableFilters[key]?.values?.map(({ _id, name }) => ({ value: _id, label: name }))?.filter(({ label }) => !query || label?.includes(query))}
            col
          />
        </> : <>
          <Inputs.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.Checkboxes
            value={filter[key]}
            onChange={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            options={availableFilters[key]?.values?.map(({ _id, name }) => ({ value: _id, label: name }))?.filter(({ label }) => !query || label?.includes(query))}
            col
          />
        </>
      case 'boolean':
        return <Inputs.RadioButtons
          options={[t('shared.yes'), t('shared.no')]}
          value={filter[key] === false ? t('shared.no') : filter[key] === true ? t('shared.yes') : null}
          onChange={(value) => setFilter({ ...filter, [key]: value === t('shared.yes') })}
        />
      case 'number':
      case 'priceRange':
        return min < max && <Inputs.NumberRange
          minValue={Math.round(min)}
          maxValue={Math.round(max)}
          onChange={({ min, max }) => setFilter({ ...filter, [key]: { min: min || Math.round(min), max: max || Math.round(max) } })}
          value={filter[key] || { min: Math.round(min), max: Math.round(max) }}
        />
      default:
        return null
    }
  }

  return <div className="shared-sorting-component-container col">
    {allowSort && (
      <div className="sort">
        <h3>{t('shared.orderBy')}</h3>
        <Inputs.RadioButtons col buttons={sortButtons} onClick={(data) => setSort(data)} value={sort} />
      </div>
    )}
    {allowFilter && (
      <div className="filter">
        <h3>{t('shared.filterBy')}</h3>
        {renderFilter()}
      </div>
    )}
    {(allowSort || allowFilter) && (
      <div className="buttons row">
        <p
          className="btn-clear"
          onClick={() => {
            setFilter(omit(filter, key))
            setSort('')
            setQuery('')
          }}
        >
          {t('shared.clearFilters')}
        </p>
        <Buttons.Raised
          text={t('shared.apply')}
          onClick={() => {
            handleApply()
            hide()
          }}
        />
      </div>
    )}
  </div>
}

export default SortingComponent