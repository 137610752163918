import { useTranslation } from "react-i18next"
import "./styles.scss"

const Switch = ({ value, label, labelOn, labelOff, onChange = () => { } }) => {
    const { t } = useTranslation()
    return <div className={`inputs-switch-container ${value ? 'switch-on' : ''}`} onClick={onChange}>
        <span className={`inputs-switch-label`}>{label}</span>
        <div className={'inputs-switch-inner-container'}>
            <span className={`label-on ${value ? 'fade-in' : 'fade-out'}`}>{labelOn || t('shared.yes')}</span>
            <div className='inputs-switch-circle' />
            <span className={`label-off ${!value ? 'fade-in' : 'fade-out'}`}>{labelOff || t('shared.no')}</span>
        </div>
    </div>
}

export default Switch