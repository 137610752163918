import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    services: [],
}

export const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setServicesFields: (state, { payload }) => ({ ...state, ...payload }),
        setServices: (state, { payload }) => ({ ...state, services: payload.services })
    }
})

export const {
    setServicesFields,
    setServices
} = services.actions

export default services.reducer