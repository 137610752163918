import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    organizations: [],
    nextPage: 1,
    hasNextPage: true,
    totalDocs: 0,
    organization: {}
}

export const businessClients = createSlice({
    name: 'businessClients',
    initialState,
    reducers: {
        setBusinessClientsFields: (state, { payload }) => ({ ...state, ...payload }),
        setBusinessClient: (state, { payload: { organization } }) => ({ ...state, organization }),
        setBusinessClients: (state, { payload }) => {
            const organizations = payload.page > 1 ? [...state.organizations, ...payload.organizations] : payload.organizations
            return { ...state, ...payload, organizations, }
        },
    }
})

export const {
    setBusinessClientsFields,
    setBusinessClients,
    setBusinessClient
} = businessClients.actions

export default businessClients.reducer