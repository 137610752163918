import { useEffect, useRef } from 'react'
import Popup from 'reactjs-popup';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import "./styles.scss"
import { emit, logout, setGeneralFields } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_AVATAR_URL } from 'config/settings';
import { Inputs } from 'components';
import { useTranslation } from "react-i18next";

import { setDefaultLocale } from 'react-datepicker'
import bg from 'date-fns/locale/bg'
import en from 'date-fns/locale/en-US'
import moment from 'moment'

const Header = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(({ general }) => general)
    const { _id, coverPhoto, fullName, email, language } = user || {}

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language)
            setDefaultLocale(language === 'en' ? en : bg)
            moment.locale(language)

        }
    }, [i18n, language])

    let linksRef = useRef({})
    const activeLinkRef = useRef(null)

    const { pathname } = useLocation()
    useEffect(() => { setTimeout(() => detectRoute(pathname), 100) }, [pathname])

    const detectRoute = (pathname) => {
        let currentLoc = linksRef?.current?.[pathname]
        if (currentLoc) {
            const left = currentLoc.offsetLeft
            const { width } = currentLoc.getBoundingClientRect()
            activeLinkRef?.current?.setAttribute('style', `left: ${left}px; width: ${width}px;`)
            if (activeLinkRef?.current?.offsetHeight === 0) setTimeout(() => { activeLinkRef.current?.classList.add('selector-add-height') }, 200)
        } else activeLinkRef?.current?.setAttribute('style', `left: ${0}px; width: ${0}px;`)
    }

    return <div className="header-container row">
        <div className="header-left row">
            <NavLink to="/" ref={(ref) => { linksRef.current['/'] = ref }}>
                <img src={require('../../assets/images/logo.jpeg')} alt="logo" />
            </NavLink>
        </div>
        <div className="header-links row">
            <NavLink to="/" ref={(ref) => { linksRef.current['/'] = ref }}>{t('header.reservations')}</NavLink>
            <NavLink to="/carts" ref={(ref) => { linksRef.current['/carts'] = ref }}>{t('header.carts')}</NavLink>
            {/* <NavLink to="/services" ref={(ref) => { linksRef.current['/services'] = ref }}>{t('header.services')}</NavLink> */}
            <NavLink to="/employees" ref={(ref) => { linksRef.current['/employees'] = ref }}>{t('header.employees')}</NavLink>
            <NavLink to="/fleet" ref={(ref) => { linksRef.current['/fleet'] = ref }}>{t('header.fleetManagement')}</NavLink>
            <NavLink to="/discount-codes" ref={(ref) => { linksRef.current['/discount-codes'] = ref }}>{t('header.campaigns')}</NavLink>
            <NavLink to="/business-clients" ref={(ref) => { linksRef.current['/business-clients'] = ref }}>{t('header.businessClients')}</NavLink>
            <div ref={activeLinkRef} className="active-route-selector" />
        </div>
        <div className="header-right row">
            <Popup
                className='popup-header-options-container'
                trigger={<div className="icon icon-avatar" style={{ backgroundImage: `url(${coverPhoto || DEFAULT_AVATAR_URL})`, backgroundSize: 'cover' }} />}
                position="bottom right"
                offsetY={5}
            >
                {close => <>
                    <div className="popup-header-options-header row">
                        <h3>{t('header.profileAndSettings')}</h3>
                        <div className="icon icon-close" onClick={close} />
                    </div>
                    <div className="popup-header-options-content">
                        <div className="row row-profile">
                            <div className="col">
                                <p className="bold">{fullName}</p>
                                <p>{email}</p>
                            </div>
                        </div>
                        <div className="line" />
                        <div className="row row-language">
                            <div className="icon icon-language" />
                            <p>BG</p>
                            <Inputs.Switch
                                labelOn=' '
                                labelOff=' '
                                value={i18n.language === 'en'}
                                onChange={() => emit({
                                    action: 'user/edit',
                                    startLoading: true,
                                    stopLoading: true,
                                    payload: { _id, language: i18n.language === 'en' ? 'bg' : 'en' },
                                    onSuccess: ({ user }) => dispatch(setGeneralFields({ user }))
                                })} />
                            <p>EN</p>
                        </div>
                        <div className="line" />
                        <div className="row row-action" onClick={() => {
                            navigate("/users")
                            close()
                        }}>
                            <div className="icon icon-users" />
                            <p>{t('header.profiles')}</p>
                        </div>
                        <div className="line" />
                        <div className="row row-action" onClick={() => {
                            navigate("/support")
                            close()
                        }}>
                            <div className="icon icon-support" />
                            <p>{t('header.inquiriesFromUsers')}</p>
                        </div>
                        <div className="line" />
                        <div className="row row-action" onClick={logout}>
                            <div className="icon icon-logout" />
                            <p>{t('header.logout')}</p>
                        </div>
                    </div>
                </>}
            </Popup>

        </div>
    </div>
}

export default Header