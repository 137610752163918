import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    user: null,
    socket: null,
    loading: false,
    loadingText: 'Loading...',
    modal: { isOpen: false, type: '', props: {} },
    overlay: { isOpen: false, type: '', props: {} },
    inSocket: []
}

export const general = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setGeneralFields: (state, { payload }) => ({ ...state, ...payload }),
        startLoading: (state) => ({ ...state, loading: true }),
        stopLoading: (state) => ({ ...state, loading: false }),
        setLoadingText: (state, { payload }) => ({ ...state, loadingText: payload }),
        setModal: (state, { payload }) => ({ ...state, modal: payload }),
        setOverlay: (state, { payload }) => ({ ...state, overlay: payload }),
        addUserInSocket: (state, { payload }) => ({ ...state, inSocket: [...(state?.inSocket || []), payload.user] }),
        removeUserInSocket: (state, { payload }) => ({ ...state, inSocket: state?.inSocket?.filter((_id) => _id !== payload.user) || [] })
    }
})

export const {
    setGeneralFields,
    startLoading,
    stopLoading,
    setLoadingText,
    setModal,
    setOverlay,
    addUserInSocket,
    removeUserInSocket
} = general.actions

export default general.reducer;