import { Support, useSupportSocket } from '@fidweb/customer-support'
import { emit } from 'actions'
import { useSelector } from 'react-redux'
import "./styles.scss"

const SupportScreen = () => {
    const { user } = useSelector(({ general }) => general)

    const socket = useSupportSocket({
        URL: 'https://customer-support-dev.herokuapp.com',
        platform: 'geowash',
        currentUser: user,
        handleNewUser: (payload) => emit({ action: 'user/edit', payload })
    })

    return <div className="screen-support-container">
        {user && socket && <Support
            {...socket}
            currentUser={user}
            language={user?.language ?? 'bg'}
            logo={require('../../assets/images/logo.jpeg')}
            colors={{
                '--main-color': '#0099ff',
                '--secondary-color': '#00ce14',
                '--light-color': '#cbe1f0'
            }}
        />}
    </div>
}

export default SupportScreen