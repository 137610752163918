/* eslint-disable react-hooks/exhaustive-deps */
import { emit } from 'actions'
import { Buttons, Fleet as FleetComps } from 'components'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import "./styles.scss"
import { useTranslation } from 'react-i18next'

const Fleet = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { vehicles } = useSelector(({ vehicles }) => vehicles)

  const fetch = () => emit({ action: 'vehicle/list', startLoading: isEmpty(vehicles), stopLoading: true })
  useEffect(() => { fetch() }, [])
  const joinRoomReservations = () => emit({ action: "room/join", payload: { room: 'reservations' } })
  useEffect(() => { emit({ action: 'room/join', payload: { room: "drivingSessions" }, onSuccess: joinRoomReservations }) }, [])
  useEffect(() => () => {
    emit({ action: 'room/leave', payload: { room: "drivingSessions" } })
    emit({ action: 'room/leave', payload: { room: "reservations" } })
  }, [])

  return <div className="screen-fleet-container">
    <div className="screen-fleet-inner-container row">
      <div className="screen-fleet-map">
        {!isEmpty(vehicles) && <FleetComps.Map vehicles={vehicles} />}
      </div>
      <div className="screen-fleet-vehicles-container">
        <div className="screen-fleet-vehicles-header row">
          <h3>{t('fleet.addedVehicles')}</h3>
          <Buttons.Raised onClick={() => navigate('/vehicle/form')} text={t('shared.create')} />
        </div>
        <div className="screen-fleet-vehicles-content" >
          {vehicles.map(vehicle => <FleetComps.VehicleInfo key={vehicle._id} vehicle={vehicle} />)}
        </div>
      </div>
    </div>
  </div>
}

export default Fleet