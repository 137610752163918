/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setEmployeesFields } from "actions"
import { Shared, Buttons } from "components"
import { employeesColumns } from "config/constants"
import { debounce } from "lodash"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { renderCell } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const Employees = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { employees, nextPage, hasNextPage, totalDocs } = useSelector(({ employees }) => employees)

  const fetch = () => emit({
    action: 'user/browse',
    payload: { query: { role: { $nin: ['admin'] } }, pageNumber: nextPage || 1 },
    startLoading: true,
    stopLoading: true,
  })
  const fetchDebounced = debounce(fetch, 300)

  const dispatch = useDispatch()
  useEffect(() => {
    fetch()
    return () => dispatch(setEmployeesFields({ nextPage: 1, hasNextPage: true }))
  }, [])

  return <div className="screen-employees-container">
    <div className="screen-employees-inner-container">
      <div className="screen-employees-content">
        <Shared.Table
          columns={employeesColumns}
          data={employees}
          renderCell={(emp, field) => renderCell.employees(emp, field, { navigate, t })}
          handlePagination={() => hasNextPage && fetchDebounced()}
          headerHight={65}
        />
      </div>
      <div className="screen-employees-footer row">
        <div className="row row-stats">
          <p>{t('employees.count')}: <span>{totalDocs}</span></p>
        </div>
        <Buttons.Raised
          text={t('employees.createEmployee')}
          onClick={() => navigate('/employee/form')}
        />
      </div>
    </div>
  </div>
}

export default Employees