import Select from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable';

const Dropdown = ({ async, ...props }) => {
    if (async) return <div className="inputs-dropdown-container">
        <AsyncCreatableSelect
            styles={{
                container: (provided) => ({ ...provided, margin: '10px 0' }),
                control: (provided) => ({
                    ...provided,
                    borderColor: 'rgba(0, 52, 112, 0.3)',
                    boxShadow: 'none',
                    '&:hover': { borderColor: 'rgba(0, 52, 112, 0.3)' },
                }),
                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                menu: (provided) => ({ ...provided, margin: 0 }),
                menuList: (provided) => ({ ...provided, fontSize: 13, }),
            }}
            {...props} />
    </div>

    return <div className="inputs-dropdown-container">
        <Select
            styles={{
                container: (provided) => ({ ...provided, margin: '10px 0' }),
                control: (provided) => ({
                    ...provided,
                    borderColor: 'rgba(0, 52, 112, 0.3)',
                    boxShadow: 'none',
                    '&:hover': { borderColor: 'rgba(0, 52, 112, 0.3)' },
                }),
                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                menu: (provided) => ({ ...provided, margin: 0 }),
                menuList: (provided) => ({ ...provided, fontSize: 13, }),
            }}
            {...props} />
    </div>
}

export default Dropdown