
import store, {
    startLoading,
    stopLoading,
    setGeneralFields,
    login,
    setSessions,
    setSession,
    setVehicles,
    setVehicle,
    updateLocations,
    setReservations,
    setCarts,
    setCart,
    setServices,
    setEmployees,
    setVehicleInUse,
    setVehicleNotInUse,
    startSession,
    endSession,
    addReservationToVehicle,
    updateReservationToVehicle,
    removeReservationToVehicle,
    setReservationChat,
    setReservationNotes,
    createReservationNotes,
    updateReservationNotes,
    removeReservationNotes,
    addUserInSocket,
    removeUserInSocket,
    setDiscountCodes,
    setBusinessClient,
    setBusinessClients,
    updateCartBySystem,
    handleNewMessageReservationsSlice
} from "actions"
import { Alerts } from "utilities"


export const emit = (config) => {
    const {
        startLoading: startLoadingParam,
        stopLoading: stopLoadingParam,
        onSuccess,
        onFailed,
        retry = 0,
        ...payload
    } = config || {}
    const { socket } = store.getState().general

    if (!socket) {
        if (retry < 3) setTimeout(() => emit({ ...config, retry: retry + 1 }), 500)
        else Alerts.error({ text: 'Socket not found' })
        return
    }

    if (startLoadingParam) store.dispatch(startLoading())
    console.log('Socket out: ', payload);
    socket.emit('socket-message', payload)

    const onResponse = ({ action, payload: response }) => {
        if (action === `${payload.action}Success`) {
            if (onSuccess) onSuccess(response)
            if (stopLoadingParam) store.dispatch(stopLoading())
            socket.off('socket-message', onResponse)
        } else if (action === `${payload.action}Failed`) {
            if (onFailed) onFailed(response)
            if (!['user/activateCodeFailed'].includes(action)) Alerts.error({ text: response.error })
            store.dispatch(stopLoading())
            socket.off('socket-message', onResponse)
        }
    }

    socket.on('socket-message', onResponse)
}

const socketIn = ({ user, cartOperators }) => (dispatch) => dispatch(setGeneralFields({ user, inSocket: cartOperators }))

const getReservations = () => () => emit({ action: 'reservation/browse', payload: { pageSizeLimit: 100, pageNumber: 1 } })

const getReservationChat = (res) => () => {
    const { message: { room: { _id } } } = res
    const state = store.getState().reservationChat
    if (state.room === _id) emit({
        action: 'chat/getMessages',
        payload: { roomId: _id },
        startLoading: true,
        stopLoading: true,
    })
    else store.dispatch(handleNewMessageReservationsSlice(res))
}



export const mapSocketActions = {
    'socketInit/initSuccess': socketIn,
    'socketInit/initSuccessGlobal': addUserInSocket,
    'socketInit/disconnectSuccessGlobal': removeUserInSocket,
    'user/loginSuccess': login,
    'vehicle/listSuccess': setVehicles,
    'vehicle/getOneSuccess': setVehicle,
    'drivingSession/browseSuccess': setSessions,
    'drivingSession/getOneSuccess': setSession,
    'drivingSession/updateSessionSuccessGlobal': updateLocations,
    'reservation/browseSuccess': setReservations,
    'cart/browseSuccess': setCarts,
    'service/listSuccess': setServices,
    'user/browseSuccess': setEmployees,
    'cart/getOneSuccess': setCart,
    'work/startSuccessGlobal': setVehicleInUse,
    'work/endSuccessGlobal': setVehicleNotInUse,
    'drivingSession/startSessionSuccessGlobal': startSession,
    'drivingSession/endSessionSuccessGlobal': endSession,
    'reservation/createSuccessGlobal': getReservations,
    'reservation/startCleaningSuccessGlobal': [updateReservationToVehicle, getReservations],
    'reservation/endCleaningSuccessGlobal': [removeReservationToVehicle, getReservations],
    'reservation/extendCleaningSuccessGlobal': getReservations,
    'reservation/acceptReservationSuccessGlobal': [addReservationToVehicle, getReservations],
    'reservation/cancelSuccessGlobal': getReservations,
    'reservation/employeeCancelSuccessGlobal': getReservations,
    'reservation/rateSuccessGlobal': getReservations,
    'reservation/paidCashSuccessGlobal': getReservations,
    'payment/SuccessGlobal': getReservations,
    'payment/FailedGlobal': getReservations,
    'chat/getMessagesSuccess': setReservationChat,
    'chat/sendMessageSuccessGlobal': getReservationChat,
    'note/getReservationNotesSuccess': setReservationNotes,
    'note/createSuccessGlobal': createReservationNotes,
    'note/updateSuccessGlobal': updateReservationNotes,
    'note/removeSuccessGlobal': removeReservationNotes,
    'discount/listCampaignsSuccess': setDiscountCodes,
    'organization/listSuccess': setBusinessClients,
    'organization/getOneSuccess': setBusinessClient,
    'reservation/changeCartBySystemSuccessGlobal': updateCartBySystem
}
