import { emit } from "actions"
import { Buttons, Shared } from "components"
import { businessClientsColumns } from "config/constants"
import { debounce } from "lodash"
import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { renderCell } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const BusinessClients = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { organizations, nextPage, hasNextPage, totalDocs } = useSelector(({ businessClients }) => businessClients) || {}

    const fetch = useCallback((payload = {}) => emit({
        action: 'organization/list',
        payload,
        startLoading: true,
        stopLoading: true,
    }), [])

    const fetchDebounced = debounce(fetch, 300)

    useEffect(() => { fetch() }, [fetch])

    return <div className="screen-business-clients-container">
        <div className="screen-business-clients-inner-container">
            <div className="screen-business-clients-content">
                <Shared.Table
                    fixedWidth
                    columns={businessClientsColumns}
                    data={organizations}
                    renderCell={(organization, field) => renderCell.businessClients(organization, field, { navigate })}
                    handlePagination={() => hasNextPage && fetchDebounced({ pageNumber: nextPage })}
                    headerHight={65}
                />
            </div>
            <div className="screen-business-clients-footer row"><div className="row row-stats">
                <p>{t('businessClients.count')}: <span>{totalDocs ?? 0}</span> </p>
            </div>
                <div className="row row-buttons">
                    <Buttons.Raised text={t('businessClients.createBusinessClient')} onClick={() => navigate(`/business-client/form`)} />
                </div>
            </div>
        </div>
    </div>
}

export default BusinessClients