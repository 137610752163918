import { emit, setReservationsFields } from "actions"
import { Inputs } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { useQuery } from "hooks"
import { isEmpty } from "lodash"
import moment from "moment"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const ReservationNotes = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(({ general }) => general)
    const { _id } = useQuery()
    const { notes } = useSelector(({ reservations }) => reservations)

    const fetch = useCallback((payload) => { emit({ action: 'note/getReservationNotes', payload, startLoading: true, stopLoading: true, }) }, [])

    useEffect(() => {
        if (_id) fetch({ reservation: _id })
        return () => dispatch(setReservationsFields({ notes: [] }))
    }, [_id, dispatch, fetch])

    const inputRef = useRef(null)
    const [isEdit, setIsEdit] = useState("")

    const renderMessage = ({ _id, createdAt, messageVariant, message, sender }, i) => <Fragment key={_id}>
        <div className={`message ${isEdit && isEdit !== _id && 'is-edit'}`}>
            <p>{sender?.fullName} <span className="time">{moment(createdAt).format("HH:mm")}</span></p>
            <div className="message-content row">
                <div className={`placeholder logo`}
                    style={{ backgroundImage: `url(${sender?.coverPhoto || DEFAULT_AVATAR_URL})` }}
                />
                {messageVariant === 'photo' ? <a href={message} target="_blank" rel="noopener noreferrer"><img src={message} alt={""} /></a>
                    : messageVariant === 'video' ? <video className="video" width="426" height="240" src={message} controls />
                        : messageVariant === 'document' ? <a href={message} target="_blank" rel="noopener noreferrer"><p className="text">{message}</p></a>
                            : <p className="text">{message}</p>}
                <Popup
                    className='popup-screen-reservation-notes-options-container'
                    trigger={<div className="icon icon-options" />}
                    position="left"
                    offsetY={5}
                >
                    {close => <>
                        <div className="popup-screen-reservation-notes-options-header row">
                            <h3>{t('shared.options')}</h3>
                            <div className="icon icon-close" onClick={close} />
                        </div>
                        <div className="popup-screen-reservation-notes-options-content">
                            <div className="row row-action" onClick={() => {
                                inputRef.current.setText(message)
                                setIsEdit(_id)
                                close()
                            }}>
                                <div className="icon icon-pen" />
                                <p>{t('shared.edit')}</p>
                            </div>
                            <div className="line" />
                            <div className="row row-action" onClick={() => {
                                emit({ action: 'note/remove', payload: { noteId: _id }, })
                                close()
                            }}>
                                <div className="icon icon-delete" />
                                <p>{t('shared.delete')}</p>
                            </div>
                        </div>
                    </>}
                </Popup>

            </div>
        </div>
        {(!moment(createdAt).isSame(moment(notes?.[i + 1]?.createdAt), 'day') || i === notes.length - 1) && (
            <div className="row days-divider">
                <p>{moment(createdAt).isSame(moment(), 'day') ? t('shared.today') : moment(createdAt).isSame(moment().subtract(1, 'd'), 'day') ? t('shared.yesterday') : moment(createdAt).format('DD.MM.YYYY')}</p>
                <span className="line" />
            </div>
        )}
    </Fragment>

    return <div className={`screen-reservation-notes-container `}>
        <div className="screen-reservation-notes-inner-container col">
            <div className="screen-reservation-notes-header">
                <div className="row row-close">
                    <div className="icon icon-close" onClick={() => navigate(-1)} />
                    {t('reservationNotes.notes')}
                </div>
            </div>
            {isEmpty(notes)
                ? <div className="row empty"><p>{t('reservationNotes.empty')}</p></div>
                : <div className="screen-reservation-notes-content col">
                    {notes?.map(renderMessage)}
                </div>
            }
            <div className="screen-reservation-notes-input">
                <Inputs.ChatInput
                    ref={inputRef}
                    coverPhoto={user?.coverPhoto || DEFAULT_AVATAR_URL}
                    hideFileUpload
                    handleSend={({ text: message, variant: messageType }) => {
                        if (isEdit) {
                            emit({ action: 'note/update', payload: { noteId: isEdit, message } })
                            setIsEdit("")
                        }
                        else emit({ action: 'note/create', payload: { reservation: _id, message, messageType }, })
                    }}
                />
            </div>
        </div>
    </div>
}

export default ReservationNotes