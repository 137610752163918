import { emit } from "actions"
import { Buttons, Inputs } from "components"
import { discountCodeTypes, discountConditionTypes } from "config/constants"
import { useQuery } from "hooks"
import { pick } from "lodash"
import moment from "moment"
import { useEffect, useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const DiscountCodeForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { _id } = useQuery()
    const { control, register, setValue, handleSubmit, formState: { errors }, setError, clearErrors, reset } = useForm()
    const { value: codeType } = useWatch({ control, name: 'codeType' }) || {}
    const campaignStart = useWatch({ control, name: 'campaignStart' })
    const campaignEnd = useWatch({ control, name: 'campaignEnd' })

    const [cachedCodesToGenerate, setCachedCodesToGenerate] = useState(null)

    useEffect(() => {
        if (_id) emit({
            action: 'discount/getCampaign', payload: { _id },
            startLoading: true, stopLoading: true,
            onSuccess: ({ discountCampaign: { codeType, campaignStart, campaignEnd, discountCondition, discountAmount, ...discountCampaign } }) => {
                if (codeType === "personal") setCachedCodesToGenerate(discountCampaign?._discountCodes?.length)
                reset({
                    ...pick(discountCampaign, ['name', 'discountInPercent', 'discountConditionEvery']),
                    campaignStart: moment(campaignStart),
                    campaignEnd: moment(campaignEnd),
                    codeType: { value: codeType, label: t(discountCodeTypes.find(({ value }) => value === codeType)?.label) },
                    discountCondition: { value: discountCondition.toString(), label: t(discountConditionTypes.find(({ value }) => value === discountCondition.toString())?.label) },
                    discountAmount: discountCampaign?.discountInPercent ? discountAmount * 100 : discountAmount,
                    genericCode: codeType === "generic" ? discountCampaign?._discountCodes?.[0]?.code : undefined,
                    codesToGenerate: codeType === "personal" ? discountCampaign?._discountCodes?.length : undefined
                })
            }
        })
    }, [_id, reset, t])

    const formatPayload = (payload) => {
        if (payload.discountInPercent) payload.discountAmount = Number(payload.discountAmount) / 100
        else payload.discountInPercent = false
        payload.codeType = payload.codeType.value
        payload.discountCondition = payload.discountCondition.value
        return payload
    }

    const handleCreate = (payload) => emit({
        action: 'discount/createCampaign',
        payload: formatPayload(payload),
        startLoading: true,
        onSuccess: () => navigate(-1)
    })

    const handleEdit = (payload) => {
        if (cachedCodesToGenerate && payload?.codesToGenerate < cachedCodesToGenerate) setError('codesToGenerate', {
            type: "custom",
            message: t('discountCode.editError')
        })
        else emit({
            action: 'discount/editCampaign',
            payload: { _id, ...formatPayload(payload) },
            startLoading: true,
            onSuccess: () => navigate(-1)
        })
    }

    return <div className="screen-discount-code-form-container">
        <div className="screen-discount-code-form-inner-container">
            <div className="screen-discount-code-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('discountCode.campaign')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-discount-code-form-content">
                <h2>{t('discountCode.generalInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('discountCode.name')} <span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors?.name ? "invalid" : ''}
                            {...register("name", { required: true, })}
                            disabled={_id && moment(campaignStart).isSameOrBefore(moment())}
                        />
                    </div>
                    <div className="col">
                        <span>{t('discountCode.startDate')} <span className="icon icon-required" /></span>
                        <Controller name={`campaignStart`} control={control} rules={{ required: true }}
                            render={({ field: { onChange, value } }) => <Inputs.Datepicker
                                className={errors?.campaignStart ? "invalid" : ''}
                                value={value ?? null}
                                onChange={(value) => {
                                    value = moment(value).startOf('day').toDate()
                                    onChange(value)
                                    if (campaignEnd && moment(value).isSameOrAfter(moment(campaignEnd))) setValue('campaignEnd', null)
                                }}
                                minDate={new Date()}
                                maxDate={campaignEnd}
                                disabled={_id && moment(campaignStart).isSameOrBefore(moment())}
                            />}
                        />
                    </div>
                    <div className="col">
                        <span>{t('discountCode.endDate')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`campaignEnd`} rules={{ required: true }}
                            render={({ field: { onChange, value } }) => <Inputs.Datepicker
                                value={value ?? null}
                                className={errors?.campaignEnd ? "invalid" : ''}
                                onChange={(value) => {
                                    value = moment(value).endOf('day').toDate()
                                    onChange(value)
                                    if (campaignStart && moment(value).isSameOrBefore(moment(campaignStart))) setValue("campaignStart", null)
                                }}
                                minDate={campaignStart || new Date()}
                                disabled={_id && moment(campaignStart).isSameOrBefore(moment())}
                            />}
                        />
                    </div>
                </div>
                <div className="divider" />
                <h2>{t('discountCode.discount')}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('discountCode.discountType')} <span className="icon icon-required" /></span>
                        <div className="row">
                            <Inputs.Text
                                className={errors.discountAmount ? "invalid" : ''}
                                {...register('discountAmount', {
                                    required: true,
                                    pattern: /^\d*$/
                                })}
                            />
                            <Controller control={control} name={`discountInPercent`}
                                render={({ field: { value, onChange } }) => <Inputs.Switch
                                    labelOff="%"
                                    labelOn={t('shared.lv')}
                                    value={value}
                                    onChange={() => onChange(!value)} />}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <span>{t('discountCode.discountCondition')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`discountCondition`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('discountCode.discountConditionPlaceholder')}
                                    options={discountConditionTypes.map(({ value, label }) => ({ value, label: t(label) }))}
                                    value={value}
                                    onChange={(value) => {
                                        onChange(value)
                                        if (value.value === '5') setValue("discountConditionEvery", true)
                                        else setValue("discountConditionEvery", false)
                                    }}
                                    classNamePrefix={error ? "invalid" : ''}
                                />
                            }
                        />
                    </div>
                    <div className="col">
                        <span>{t('discountCode.codeType')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`codeType`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('discountCode.codeTypePlaceholder')}
                                    options={discountCodeTypes.map(({ value, label }) => ({ value, label: t(label) }))}
                                    value={value}
                                    onChange={(value) => {
                                        onChange(value)
                                        setValue("codesToGenerate", undefined)
                                        setValue("genericCode", undefined)
                                    }}
                                    classNamePrefix={error ? "invalid" : ''}
                                    isDisabled={_id}
                                />
                            }
                        />
                    </div>
                    {codeType === "personal" && <div className="col col-codesToGenerate col-double">
                        <span className="error">{errors?.codesToGenerate?.type === 'custom' && errors?.codesToGenerate.message}</span>
                        <span>{t('discountCode.numberOfCodes')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={"codesToGenerate"} rules={{ required: true, pattern: /^\d*$/ }}
                            render={({ field: { value, onChange } }) => <Inputs.Text
                                className={errors?.codesToGenerate ? "invalid" : ''}
                                value={value}
                                onChange={(value) => {
                                    onChange(value)
                                    if (cachedCodesToGenerate) {
                                        if (Number(value.target.value) < cachedCodesToGenerate) setError('codesToGenerate', {
                                            type: "custom",
                                            message: t('discountCode.editError')
                                        })
                                        else if (Number(value.target.value) >= cachedCodesToGenerate) clearErrors("codesToGenerate")
                                    }
                                }}
                            />}
                        />
                    </div>}
                    {codeType === "generic" && <div className="col">
                        <span>{t('discountCode.code')} <span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors?.genericCode ? "invalid" : ''}
                            {...register("genericCode", { required: true })}
                            disabled={_id}
                        />
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default DiscountCodeForm