import { emit } from "actions";
import { Buttons, Inputs } from "components";
import { useQuery } from "hooks";
import { pick } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alerts } from "utilities";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const VehicleForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const { _id } = useQuery()
  useEffect(() => {
    if (_id) emit({
      action: 'vehicle/getOne',
      payload: { _id },
      startLoading: true,
      stopLoading: true,
      onSuccess: ({ vehicle }) => {
        reset({
          ...pick(vehicle, [
            'certificateRegistration',
            'licensePlate',
            'role',
            "VIN",
            "carMake",
            "color",
            "productionYear",
            "odometer",
            'averageConsumption',
            "fuelPrice"
          ])
        })
      }
    })
  }, [_id, reset])



  const handleCreate = payload => emit({
    action: 'vehicle/add',
    payload: { ...payload, odometerActual: payload.odometer },
    startLoading: true,
    onSuccess: () => navigate(-1)
  })
  const handleEdit = (payload) => emit({
    action: 'vehicle/edit',
    payload: { _id, ...payload },
    startLoading: true,
    onSuccess: () => navigate(-1)
  })
  const handleDelete = () => Alerts.confirm({
    title: t('shared.warning'),
    text: t('vehicle.deleteVehicle'),
    cancelButtonText: t('shared.cancel'),
    confirmButtonText: t('shared.delete'),
    onSuccess: () => emit({
      action: 'vehicle/deleteVehicle',
      payload: { _id },
      startLoading: true,
      onSuccess: () => navigate(-2)
    })
  })


  return <div className="screen-vehicle-form-container">
    <div className="screen-vehicle-form-inner-container">
      <div className="screen-vehicle-form-header row">
        <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('vehicle.vehicle')}`} />
        <div className="row row-buttons">
          {_id ? <>
            <Buttons.Raised text={t('shared.delete')} className="btn-delete" onClick={handleDelete} />
            <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
          </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
        </div>
      </div>
      <div className="screen-vehicle-form-content">
        <h2>{t('vehicle.vehicleInformation')}</h2>
        <div className="row">
          <div className="col">
            <span>{t('vehicle.certificateRegistration')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.certificateRegistration ? "invalid" : ''}
              {...register("certificateRegistration", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.licensePlate')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.licensePlate ? "invalid" : ''}
              {...register("licensePlate", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.VIN')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.VIN ? "invalid" : ''}
              {...register("VIN", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.carMake')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.carMake ? "invalid" : ''}
              {...register("carMake", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.color')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.color ? "invalid" : ''}
              {...register("color", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.productionYear')} <span className="icon icon-required" /></span>
            <Inputs.Text
              className={errors.productionYear ? "invalid" : ''}
              {...register("productionYear", { required: true, })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.odometer')} <span className="icon icon-required" /></span>
            <Inputs.Text
              label={t('shared.km')}
              className={errors.currentOdometer ? "invalid" : ''}
              {...register("odometer", { required: true, pattern: /^\d*$/ })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>{t('vehicle.averageConsumption')} <span className="icon icon-required" /></span>
            <Inputs.Text
              label={t('shared.l')}
              className={errors.averageConsumption ? "invalid" : ''}
              {...register("averageConsumption", { required: true, pattern: /^\d+(\.\d{1,2})?$/ })}
            />
          </div>
          <div className="col">
            <span>{t('vehicle.fuelPrice')} <span className="icon icon-required" /></span>
            <Inputs.Text
              label={t('shared.lv')}
              className={errors.fuelPrice ? "invalid" : ''}
              {...register("fuelPrice", { required: true, pattern: /^\d+(\.\d{1,2})?$/ })}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default VehicleForm