import { createSlice } from '@reduxjs/toolkit'
import { pick } from 'lodash'

const initialState = {
    reservations: [],
    nextPage: 1,
    hasNextPage: true,
    stats: {},
    notes: []
}

export const reservations = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setReservationsFields: (state, { payload }) => ({ ...state, ...payload }),
        setReservations: (state, { payload }) => {
            const reservations = payload.page > 1 ? [...state.reservations, ...payload.reservations] : payload.reservations
            return { ...state, reservations, ...pick(payload, ["nextPage", "hasNextPage", 'stats']) }
        },
        setReservationNotes: (state, { payload: { notes } }) => ({ ...state, notes: notes.reverse() }),
        createReservationNotes: (state, { payload: { note } }) => ({ ...state, notes: [note, ...state.notes] }),
        updateReservationNotes: (state, { payload: { note } }) => ({ ...state, notes: state.notes.map((n) => n._id !== note._id ? n : note) }),
        removeReservationNotes: (state, { payload }) => ({ ...state, notes: state.notes.filter(({ _id }) => _id !== payload._id) }),
        updateCartBySystem: (state, { payload: { reservation } }) => ({ ...state, reservations: state.reservations.map((r) => r._id === reservation._id ? { ...r, cartBySystem: reservation.cartBySystem } : r) }),
        handleNewMessageReservationsSlice: (state, { payload: { message } }) => ({ ...state, reservations: state.reservations.map((r) => r._id === message.room.reservation._id ? { ...r, chat: { ...r.chat, messageCount: r.chat.messageCount + 1, unreadMessages: r.chat.unreadMessages + 1 } } : r) }),
    }
})

export const {
    setReservationsFields,
    setReservations,
    setReservationNotes,
    createReservationNotes,
    updateReservationNotes,
    removeReservationNotes,
    updateCartBySystem,
    handleNewMessageReservationsSlice
} = reservations.actions

export default reservations.reducer