import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    room: "",
    docs: [],
    nextPage: 1,
    hasNextPage: true,
}

export const reservationChat = createSlice({
    name: 'reservationChat',
    initialState,
    reducers: {
        setReservationChatFields: (state, { payload },) => ({ ...state, ...payload }),
        setReservationChat: (state, { payload: { messages, request } }) => {
            if (state.room && state.room !== request.roomId) return { ...state }
            const docs = messages.page > 1 ? [...state.docs, ...messages.docs] : messages.docs
            return { ...state, ...messages, docs , room: request.roomId}
        }
    }
})

export const {
    setReservationChatFields,
    setReservationChat
} = reservationChat.actions


export default reservationChat.reducer