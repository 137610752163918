import { useMemo, useCallback, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTable, useFlexLayout } from "react-table"
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useTranslation } from "react-i18next";

import "./styles.scss"

const Table = forwardRef(({
    columns: columnsProp = [],
    data: dataProp = [],
    renderCell,
    initialState = {},
    className = '',
    containerStyle = {},
    emptyState: emptyStateProp,
    handlePagination = () => { },
    fixedWidth = false,
    rowHeight: rowHeightProp = 45,
    rowMargin = 8,
    headerHight = 50,
    headerWidth: headerWidthProp,
    sortingComponent = {},
}, ref) => {
    const { t } = useTranslation()
    const emptyState = emptyStateProp ?? t('shared.emptyStateTable')
    const columns = useMemo(() => columnsProp.map((col, i) => ({
        ...col,
        Header: col.label,
        accessor: col.value || `col-${i}`,
        width: col.size || 300,
        id: col.value || `col-${i}`,
    })), [columnsProp])
    const data = useMemo(() => dataProp || [], [dataProp])
    const getRowId = useCallback(({ _id }, relativeIndex) => _id || relativeIndex, [])
    const {
        headerGroups,
        rows,
        getTableProps,
        getTableBodyProps,
        prepareRow,
        totalColumnsWidth,
    } = useTable({
        columns,
        data,
        initialState,
        getRowId,
    }, useFlexLayout)

    const scrollBarSize = useMemo(() => {
        const scrollDiv = document.createElement('div')
        scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
        document.body.appendChild(scrollDiv)
        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
        document.body.removeChild(scrollDiv)
        return scrollbarWidth
    }, [])

    const rowHeight = useMemo(() => rowHeightProp + rowMargin, [rowHeightProp, rowMargin])

    const headerWidth = useMemo(() => columns.reduce((acc, { width }) => acc + width, 0), [columns])

    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            const rowProps = row.getRowProps()
            return <div key={rowProps.key} style={{ ...style, paddingTop: rowMargin / 2 }} className={`table-row-container ${row.isExpanded && 'active'}`} >
                <div {...rowProps} className="table-row-inner-container row" style={{ height: rowHeightProp }}>
                    {row.cells.map(cell => <div
                        {...cell.getCellProps()}
                        className={`table-row-cell row table-cell ${cell.column.value}`}
                        style={fixedWidth ? { ...cell.getCellProps().style, width: 'auto', flexBasis: '0' } : { ...cell.getCellProps().style, }}
                    >
                        {renderCell
                            ? renderCell(cell.row.original, cell.column.id, { index: row.index, isExpanded: row.isExpanded })
                            : typeof cell.row.original[cell.column.id] === 'string' ? cell.row.original[cell.column.id] : '--'}
                    </div>
                    )}
                </div>
            </div>
        },
        [fixedWidth, prepareRow, renderCell, rowHeightProp, rowMargin, rows]
    )

    return <div {...getTableProps()} className={`table-container ${className}`} style={{ height: rows.length ? rows.length * rowHeight + headerHight : '100%', ...containerStyle }} >
        <AutoSizer style={{ width: 'auto', height: '100%' }}>
            {({ height, width }) => <>
                {headerGroups.map(headerGroup => (
                    <div
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-header"
                        style={{ ...headerGroup.getHeaderGroupProps().style, height: headerHight, width: fixedWidth ? 'auto' : headerWidth }}
                    >
                        {headerGroup.headers.map(({ value, label, sortable = true, ...column }) => <div
                            {...column.getHeaderProps()}
                            className={`table-cell ${value} row`}
                            style={fixedWidth ? { ...column.getHeaderProps().style, width: 'auto', flexBasis: '0' } : { ...column.getHeaderProps().style }}
                        >
                            <span>{t(label)}</span>
                            {sortable && sortingComponent && <div className="sorting-component-container">{sortingComponent[value]}</div>}
                        </div>
                        )}
                    </div>
                ))}
                {rows.length ?
                    <div {...getTableBodyProps()} className="table-content" style={{ ...getTableBodyProps().style, height: height - headerHight }}>
                        <List
                            height={height - headerHight}
                            itemCount={rows.length}
                            itemSize={rowHeight}
                            width={fixedWidth ? width : totalColumnsWidth + scrollBarSize}
                            onItemsRendered={({ overscanStopIndex }) => {
                                if (handlePagination && overscanStopIndex > rows?.length - 4) handlePagination()
                            }}
                        >{RenderRow}</List>
                    </div>
                    : <div style={{ height: height - headerHight }} className="table-content-empty row">{emptyState}</div>}
            </>
            }
        </AutoSizer>
    </div >
})

export default Table


Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    renderCell: PropTypes.func,
    initialState: PropTypes.object,
    className: PropTypes.string,
    containerStyle: PropTypes.object,
    emptyState: PropTypes.node,
    outlines: PropTypes.objectOf(PropTypes.string.isRequired),
    children: PropTypes.func,
    handlePagination: PropTypes.func,

    fixedWidth: PropTypes.bool,
    rowHeight: PropTypes.number,
    rowMargin: PropTypes.number,
    headerHight: PropTypes.number,
    headerWidthProp: PropTypes.number,

    sortingComponent: PropTypes.objectOf(PropTypes.node),
}