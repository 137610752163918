import { emit } from "actions"
import { Buttons, Inputs } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { useQuery } from "hooks"
import { useEffect } from "react"
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Alerts } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const BusinessClientForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { _id } = useQuery()
    const { coverPhoto } = useSelector(({ general }) => general?.user) || {}
    const { control, register, setValue, handleSubmit, formState: { errors }, reset } = useForm()
    const { fields, append, remove, } = useFieldArray({ control, name: "organizationClients" })

    const files = useWatch({ control, name: 'files' }) || []

    useEffect(() => {
        if (_id) emit({
            action: 'organization/getOne', payload: { _id },
            startLoading: true, stopLoading: true,
            onSuccess: ({ organization: { name, files, _clients } }) => reset({ name, organizationClients: _clients, files })
        })
        else setValue('organizationClients', [{}])
    }, [_id, reset, setValue])


    const handleCreate = (payload) => emit({
        action: 'organization/create',
        payload,
        startLoading: true,
        onSuccess: () => navigate(-1)
    })
    const handleEdit = (payload) => emit({
        action: 'organization/edit',
        payload: { _id, ...payload },
        startLoading: true,
        onSuccess: () => navigate(-1)
    })
    const handleDelete = () => Alerts.confirm({
        title: t('shared.warning'),
        text: t('businessClient.deleteBusinessClient'),
        cancelButtonText: t('shared.cancel'),
        confirmButtonText: t('shared.delete'),
        onSuccess: () => emit({
            action: 'organization/deleteOrganization',
            payload: { _id },
            startLoading: true,
            onSuccess: () => navigate(-2)
        })
    })

    return <div className="screen-business-client-form-container">
        <div className="screen-business-client-form-inner-container col">
            <div className="screen-business-client-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('businessClient.businessClient')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        <Buttons.Raised text={t('shared.delete')} className="btn-delete" onClick={handleDelete} />
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-business-client-form-content">
                <h2>{t('businessClient.clientInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('businessClient.name')} <span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors?.name ? "invalid" : ''}
                            {...register("name", { required: true, })}
                        />
                    </div>
                </div>
                <div className="divider" />
                <h2>{t('businessClient.employeesInformation')}</h2>
                <div className="users-container">
                    {fields.map((field, index) => <div key={field.id} className="row row-user">
                        <div className="col">
                            <span>{t('businessClient.fullName')} <span className="icon icon-required" /></span>
                            <Inputs.Text
                                key={field.id}
                                className={errors.organizationClients?.[index]?.fullName ? "invalid" : ''}
                                {...register(`organizationClients.${index}.fullName`, { required: true, })}
                            />
                        </div>
                        <div className="col">
                            <span>{t('businessClient.phoneNumber')} <span className="icon icon-required" /></span>
                            <Inputs.Text
                                key={field.id}
                                placeholder="+359"
                                className={errors.organizationClients?.[index]?.phoneNumber ? "invalid" : ''}
                                {...register(`organizationClients.${index}.phoneNumber`, {
                                    required: true,
                                    minLength: 13,
                                    maxLength: 13,
                                    pattern: /^[+]?$|(^[+]{1}[0-9]{1,12}$)/
                                })}
                            />
                        </div>
                        {index > 0 && <div className="icon icon-remove" onClick={() => remove(index)} />}
                    </div>)}
                </div>
                <div className="row row-add-user" onClick={() => append({})}><div className="icon icon-plus" />{t('businessClient.addEmployee')}</div>
                <div className="divider" />
                <div className="row row-files-container">
                    <h2>{t('shared.files')}</h2>
                    <Controller
                        control={control}
                        name={`files`}
                        render={({ field: { value, onChange } }) =>
                            <Buttons.Upload
                                onChange={(files) => onChange([...files, ...(value || [])])}
                                inputProps={{ multiple: true }}
                            >
                                <div className="icon icon-plus" />
                            </Buttons.Upload>
                        }
                    />
                    {files?.map(({ name, url }, i) => <div
                        key={`file-${i}`}
                        className="single-file row">
                        <a href={url} target="_blank" rel="noreferrer">{name}</a>
                        <Controller control={control} name={`files`}
                            render={({ field: { value, onChange } }) =>
                                <div className="icon icon-remove" onClick={() => {
                                    const newFiles = [...value]
                                    newFiles.splice(i, 1)
                                    onChange(newFiles)
                                }} />
                            }
                        />
                    </div>)}
                </div>
                <div className="divider" />
                <h2>{t('shared.notes')}</h2>
                <div className="comments-container">
                    <div className="row row-comments-input">
                        <div className="icon icon-avatar" style={{ backgroundImage: `url(${coverPhoto || DEFAULT_AVATAR_URL})`, backgroundSize: 'cover' }} />
                        <Inputs.Text placeholder={t('businessClient.addNote')} {...register("comment")} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default BusinessClientForm