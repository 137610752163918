/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setEmployeesFields } from "actions";
import { Buttons, Inputs, Shared } from "components";
import { cartTypes, employeeStatsColumns } from "config/constants";
import { useQuery } from "hooks";
import { pick } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { renderCell } from "utilities";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const Employee = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { employee } = useSelector(({ employees }) => employees)
    const { coverPhoto, fullName, phoneNumber, email, holidays, grossSalary, netSalary, cartType, cartGroup, stats, nextPage, hasNextPage } = employee || {}


    const { _id } = useQuery()
    const dispatch = useDispatch()
    const fetch = () => emit({
        action: 'user/stats',
        payload: { user: _id, pageNumber: nextPage },
        startLoading: true,
        stopLoading: true,
        onSuccess: ({ workDays, user, ...payload }) => {
            const newDocs = payload.page > 1 ? [...(employee.stats || []), ...workDays] : workDays
            dispatch(setEmployeesFields({
                employee: {
                    ...user,
                    stats: newDocs,
                    ...pick(payload, ['nextPage', 'hasNextPage'])
                }
            }))

        }
    })
    useEffect(() => {
        fetch()
        return () => dispatch(setEmployeesFields({ employee: {} }))
    }, [])

    return <div className="screen-employee-container">
        <div className="screen-employee-inner-container col">
            <div className="screen-employee-header row">
                <Buttons.Back text={t('employee.overview')} />
                <Buttons.Raised text={t('shared.edit')} onClick={() => navigate(`/employee/form?_id=${_id}`)} />
            </div>
            <div className="screen-employee-content">
                <div className="screen-employee-data">
                    <div className="row">
                        <Inputs.CoverPhoto value={coverPhoto} disabled />
                        <div className="col">
                            <span>{t('employee.fullName')}</span>
                            <Inputs.Text value={fullName || ""} disabled />
                        </div>
                        <div className="col">
                            <span>{t('employee.phoneNumber')}</span>
                            <Inputs.Text value={phoneNumber || ""} disabled />
                        </div>
                        <div className="col">
                            <span>{t('employee.email')}</span>
                            <Inputs.Text value={email || ""} disabled />
                        </div>
                        <div className="col">
                            <span>{t('employee.holidays')}</span>
                            <Inputs.Text value={holidays || ""} label={t('shared.days')} disabled />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span>{t('employee.grossSalary')}</span>
                            <Inputs.Text value={grossSalary || ""} label={t('shared.lv')} disabled />
                        </div>
                        <div className="col">
                            <span>{t('employee.netSalary')}</span>
                            <Inputs.Text value={netSalary || ""} label={t('shared.lv')} disabled />
                        </div>
                        <div className="col">
                            <span>{t('employee.cartType')}</span>
                            <Inputs.Text value={t(cartTypes[cartType]) || ""} disabled />
                        </div>
                        {cartGroup && <div className="col">
                            <span>{t('employee.cartGroup')}</span>
                            <Inputs.Text value={cartGroup?.name || ""} disabled />
                        </div>}
                    </div>

                </div>
                <div className="screen-employee-stats">
                    <h2>{t('employee.dailyStats')}</h2>
                    <div>
                        <Shared.Table
                            fixedWidth
                            data={stats}
                            columns={employeeStatsColumns}
                            renderCell={(emp, field) => renderCell.employeeStats(emp, field, { t })}
                            handlePagination={() => hasNextPage && fetch()}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Employee