/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setEmployeesFields } from "actions"
import { Buttons, Shared } from "components"
import { usersColumns } from 'config/constants'
import { DEFAULT_AVATAR_URL } from "config/settings"
import { debounce } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { renderCell } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const Users = () => {
    const { t } = useTranslation()
    const { user } = useSelector(({ general }) => general)
    const { _id, coverPhoto, fullName, email, phoneNumber, role } = user || {}

    const navigate = useNavigate()
    const { employees, nextPage, hasNextPage } = useSelector(({ employees }) => employees)
    const fetch = () => emit({
        action: 'user/browse',
        payload: { pageNumber: nextPage || 1 },
        startLoading: true,
        stopLoading: true,
    })
    const fetchDebounced = debounce(fetch, 300)

    const dispatch = useDispatch()
    useEffect(() => {
        fetch()
        return () => dispatch(setEmployeesFields({ nextPage: 1, hasNextPage: true }))
    }, [])

    return <div className="screen-users-container">
        <div className="screen-users-inner-container row">
            <div className="screen-users-left-container">
                <div className="screen-users-left-header">
                    <div className="row">
                        <h2>{t('users.myProfile')}</h2>
                        <div
                            className="icon icon-pen"
                            onClick={() => navigate(`/user/form?_id=${_id}&myProfile=true`)}
                        />
                    </div>
                    <p>{t('users.myProfileDescription')}</p>
                </div>
                <div className="screen-users-left-content">
                    <div className="row">
                        <div
                            className="icon icon-avatar"
                            style={{ backgroundImage: `url(${coverPhoto || DEFAULT_AVATAR_URL})` }} />
                        <div className="col">
                            <p>{t('users.name')}: <span>{fullName}</span></p>
                            <p>{t('users.email')}: <span>{email}</span></p>
                        </div>
                    </div>
                    <div className="row">
                        <p>{t('users.phoneNumber')}: <span>{phoneNumber}</span></p>
                        <p>{t('users.role')}: <span>{role === "admin" ? t('users.admin') : t('users.team')}</span></p>
                    </div>
                </div>
            </div>
            <div className="screen-users-right-container">
                <div className="screen-users-right-header row">
                    <h2>{t('users.users')}</h2>
                    <Buttons.Raised
                        text={t('shared.create')}
                        onClick={() => navigate(`/user/form`)}
                    />
                </div>
                <div className="screen-users-right-content">
                    <Shared.Table
                        fixedWidth
                        columns={usersColumns}
                        data={employees}
                        renderCell={(user, field) => renderCell.users(user, field, { navigate, t })}
                        handlePagination={() => hasNextPage && fetchDebounced()}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default Users