import { emit } from "actions";
import { Buttons, Inputs } from "components";
import { cartTypes } from "config/constants";
import { useQuery } from "hooks";
import { mapValues, pick } from "lodash";
import { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alerts } from "utilities";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const EmployeeForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm();
    const { value: cartType } = useWatch({ control, name: 'cartType' }) || {}

    const [cartGroups, setCartGroups] = useState()
    useEffect(() => { emit({ action: 'cartGroup/browse', payload: { pagination: false }, onSuccess: ({ cartGroups }) => setCartGroups(cartGroups) }) }, [])

    const { _id } = useQuery()
    useEffect(() => {
        if (_id) emit({
            action: 'user/info',
            payload: { _id },
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ user: { cartGroup, cartType, ...user } }) => {
                reset({
                    ...pick(user, ['coverPhoto', 'fullName', 'phoneNumber', 'email', 'holidays', 'grossSalary', 'netSalary']),
                    cartType: cartType ? { value: cartType, label: t(cartTypes[cartType]) } : cartType,
                    cartGroup: cartGroup ? { value: cartGroup._id, label: cartGroup.name } : cartGroup,
                })
            }
        })
        else reset({ phoneNumber: "+359", holidays: 20 })
    }, [_id, reset, t])

    const getPayload = (data) => {
        const payload = mapValues(data, (value, key) => ['cartType', 'cartGroup'].includes(key) ? value?.value : value)
        if (payload.cartType !== 'stationary') payload.cartGroup = undefined
        return payload
    }

    const handleCreate = data => emit({
        action: 'user/addMany',
        payload: { users: [getPayload(data)] },
        startLoading: true,
        onSuccess: () => navigate(-1)
    })

    const handleEdit = data => emit({
        action: 'user/edit',
        payload: { _id, ...getPayload(data) },
        startLoading: true,
        onSuccess: () => navigate(-1)
    })

    const handleDelete = () => Alerts.confirm({
        title: t('shared.warning'),
        text: t('employee.deleteEmployee'),
        cancelButtonText: t('shared.cancel'),
        confirmButtonText: t('shared.delete'),

        onSuccess: () => emit({
            action: 'user/deleteUser',
            payload: { _id },
            startLoading: true,
            onSuccess: () => navigate(-2)
        })
    })

    return <div className="screen-employee-form-container">
        <div className="screen-employee-form-inner-container">
            <div className="screen-employee-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('employee.employee')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        <Buttons.Raised text={t('shared.delete')} className="btn-delete" onClick={handleDelete} />
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-employee-form-content">
                <h2>{t('employee.generalInformation')}</h2>
                <div className="row">
                    <Controller
                        control={control}
                        name={`coverPhoto`}
                        render={({ field: { value, onChange } }) => <Inputs.CoverPhoto
                            value={value}
                            onChange={onChange}
                        />}
                    />
                    <div className="col">
                        <span>{t('employee.fullName')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors?.fullName ? "invalid" : ''}
                            {...register(`fullName`, { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.phoneNumber')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            placeholder="+359"
                            className={errors?.phoneNumber ? "invalid" : ''}
                            {...register(`phoneNumber`, {
                                required: true,
                                minLength: 13,
                                maxLength: 13,
                                pattern: /^[+]?$|(^[+]{1}[0-9]{1,12}$)/
                            })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.email')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            className={errors?.email ? "invalid" : ''}
                            {...register(`email`, { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.holidays')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            label={t('shared.days')}
                            className={errors?.holidays ? "invalid" : ''}
                            {...register(`holidays`, { required: true, })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span>{t('employee.grossSalary')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            label={t('shared.lv')}
                            className={errors?.grossSalary ? "invalid" : ''}
                            {...register(`grossSalary`, { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.netSalary')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            label={t('shared.lv')}
                            className={errors?.netSalary ? "invalid" : ''}
                            {...register(`netSalary`, { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.cartType')}<span className="icon icon-required" /></span>
                        <Controller
                            control={control}
                            name={`cartType`}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('employee.cartTypePlaceholder')}
                                    value={value}
                                    onChange={onChange}
                                    classNamePrefix={error ? "invalid" : ''}
                                    options={Object.entries(cartTypes).reduce((acc, [key, value]) => [...acc, { value: key, label: t(value) }], [])}
                                />
                            }
                            rules={{ required: true }}
                        />
                    </div>
                    {cartType === 'stationary' && <div className="col">
                        <span>{t('employee.cartGroup')}<span className="icon icon-required" /></span>
                        <Controller
                            control={control}
                            name={`cartGroup`}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('employee.cartGroupPlaceholder')}
                                    options={cartGroups?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                    value={value}
                                    onChange={onChange}
                                    classNamePrefix={error ? "invalid" : ''}
                                />
                            }
                            rules={{ required: cartType === 'stationary' }}
                        />
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default EmployeeForm