import { useCallback, useMemo, useEffect } from "react"
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from 'lodash'
import { emit, setSession } from "actions"
import { Buttons, Inputs, Sessions } from "components"
import { useQuery } from 'hooks'
import "./styles.scss"
import moment from "moment"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Session = () => {
    const { t } = useTranslation()
    const { _id } = useQuery()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { inSocket } = useSelector(({ general }) => general)
    const { session } = useSelector(({ vehicles }) => vehicles)
    const {
        averageSpeed,
        distance,
        drivingEnd,
        drivingStart,
        endAddress,
        lastLocation,
        maximumSpeed,
        route,
        startAddress,
        user,
        // vehicle,
        // workDay,
        _comments
    } = session || {}

    const isActive = useMemo(() => !!route?.length && !drivingEnd, [route, drivingEnd])
    const battery = useMemo(() => (!!route?.length && route[route?.length - 1]?.batteryLevel) || 0, [route])
    const isOnline = useMemo(() => {
        const userInSocket = inSocket?.find((userInSocket) => userInSocket === user?._id)
        return !!userInSocket
    }, [inSocket, user])

    const fetch = useCallback(() => emit({ action: 'drivingSession/getOne', payload: { _id } }), [_id])
    useEffect(() => { fetch() }, [fetch])
    useEffect(() => { emit({ action: 'room/join', payload: { room: "drivingSessions" } }) }, [])
    useEffect(() => () => {
        emit({ action: 'room/leave', payload: { room: "drivingSessions" } })
        dispatch(setSession({ payload: { drivingSession: {} } }))
    }, [dispatch])

    const hours = moment(drivingEnd).diff(moment(drivingStart), 'hours')
    const minutes = moment(drivingEnd).diff(moment(drivingStart), 'minutes') - (hours * 60)

    return <div className="screen-session-container">
        <div className="screen-session-inner-container">
            <div className="screen-session-header">
                <Buttons.Back text={t('session.overview')} />
            </div>
            <div className="screen-session-content row">
                <div className="screen-session-map">
                    {!isEmpty(route)
                        ? <Sessions.Map route={route} isActive={isActive} />
                        : !isEmpty(lastLocation)
                            ? <Sessions.Map route={[lastLocation]} isActive={isActive} />
                            : null}
                </div>
                <div className="screen-session-info-container col">
                    <div className="screen-session-info-header row">
                        <h2>{moment(drivingStart).format("DD MMM - dddd")}</h2>
                        {isActive && (isOnline ?
                            <Popup
                                className="popup-session-online-container"
                                trigger={<div className="is-online"><p>{t('session.online')}</p></div>}
                                on={['hover', 'focus']}
                                position="bottom right"
                            >
                                <div className="battery-status-container">
                                    <p className="title">{t('session.statusOnline')}</p>
                                    <p>{t('session.statusOnlineDescription')}</p>
                                    <div className="row">
                                        <div className="icon icon-battery" />
                                        <p>{t('session.deviceBattery')}: {battery}%</p>
                                    </div>
                                </div>

                            </Popup> : <Popup
                                className="popup-session-online-container"
                                trigger={<div className="is-online offline"><p>{t('session.offline')}</p></div>}
                                on={['hover', 'focus']}
                                position="bottom right"
                            >
                                <div className="battery-status-container">
                                    <p className="title offline">{t('session.statusOffline')}</p>
                                    <p>{t('session.statusOfflineDescription')}</p>
                                    <div className="row">
                                        <div className="icon icon-battery-red" />
                                        <p>{t('session.deviceBattery')}: {battery}%</p>
                                    </div>
                                </div>

                            </Popup>)}
                    </div>
                    <div className="screen-session-info-content col">
                        <div className="screen-session-info-inner-content row">
                            {[
                                { label: 'sessionColumns.drivingStart', value: moment(drivingStart).format("HH:mm") },
                                { label: 'sessionColumns.drivingEnd', value: moment(drivingEnd).format("HH:mm") },
                                { label: 'sessionColumns.duration', value: `${hours} ${t('shared.h')} ${minutes} ${t('shared.min')}` },
                                { label: 'sessionColumns.user', value: user?.fullName },
                                { label: 'sessionColumns.distance', value: distance + ` ${t('shared.km')}` },
                                { label: 'sessionColumns.averageSpeed', value: averageSpeed?.toFixed(2) + ` ${t('shared.kmh')}` },
                                { label: 'sessionColumns.maximumSpeed', value: maximumSpeed?.toFixed(2) + ` ${t('shared.kmh')}` },
                                { label: 'sessionColumns.startAddress', value: startAddress },
                                { label: 'sessionColumns.endAddress', value: endAddress },
                            ]
                                .filter(({ label }) => drivingEnd || !['sessionColumns.drivingStart', 'sessionColumns.endAddress'].includes(label))
                                .map(({ label, value }) => <div className="row" key={`session-data-${label}`}>
                                    <p>{t(label)}: <span>{value}</span></p>
                                    {['sessionColumns.user'].includes(label) && (
                                        <div
                                            className="icon icon-profile"
                                            onClick={() => navigate(`/employee?_id=${user?._id}`)}
                                        />
                                    )}
                                </div>)}
                        </div>
                        <div className={`session-legend row ${isEmpty(route) && 'blur'}`}>
                            <h2>{t('session.speedLegend')}</h2>
                            {[
                                { label: '0-30', color: '#0BD84F', value: 0 },
                                { label: '30-60', color: '#103CB5', value: 29 },
                                { label: '60-90', color: '#FF5800', value: 59 },
                                { label: '90-140', color: '#0D242E', value: 89 },
                                { label: '>140', color: '#D814A9', value: 140 },
                            ].map(({ label, color, value }, i) => (
                                <div key={`range-${i}`} className={`range ${value > maximumSpeed && 'blur'}`} style={{ borderColor: color }}>
                                    {label}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="screen-session-info-comments">
                        <h2>{t('shared.notes')} ({_comments?.length ?? 0})</h2>
                        <Inputs.Comments />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Session