/* eslint-disable react-hooks/exhaustive-deps */
import { emit } from "actions"
import { Shared, Buttons } from "components"
import { servicesColumns } from "config/constants"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { renderCell, Alerts } from "utilities"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const Services = () => {
  const { t } = useTranslation()
  const { services } = useSelector(({ services }) => services)
  const fetch = () => emit({
    action: 'service/list',
    startLoading: isEmpty(services),
    stopLoading: true,
  })
  useEffect(() => { fetch() }, [])

  return <div className="screen-services-container">
    <div className="screen-services-inner-container">
      <div className="screen-services-content">
        <Shared.Table
          fixedWidth
          columns={servicesColumns}
          data={services}
          renderCell={(service, field) => renderCell.services(service, field, { t })}
        />
      </div>
      <div className="screen-services-footer row">
        <div className="row row-stats">
          <p>{t('services.numberOfServices')}: <span>{services?.length || 0}</span></p>
        </div>
        <Buttons.Raised text={t('services.createService')} onClick={() => Alerts.comingSoon()} />
      </div>
    </div>
  </div>
}

export default Services