import { DEFAULT_AVATAR_URL } from "config/settings"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { uploadFiles } from "utilities"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const ChatInput = forwardRef(({ handleSend: handleSendProp, hideFileUpload, coverPhoto }, ref) => {
    const { t } = useTranslation()
    const uploadRef = useRef(null)

    const [text, setText] = useState(null)
    const [files, setFiles] = useState(null)

    const isDisabled = !text && !files

    const handleUpload = async ({ target: { files } }) => {
        const uploaded = await uploadFiles(files)
        setFiles(uploaded.map(({ location }, i) => ({ location, name: [...(files || [])][i].name, type: [...(files || [])][i].type })))
    }

    const handleSend = () => {
        if (isDisabled) return
        if (files) files.forEach(({ location, type }) => handleSendProp({
            text: location,
            variant: ['image', 'photo'].some(opt => type.includes(opt)) ? 'photo'
                : ['video'].some(opt => type.includes(opt)) ? 'video'
                    : ['pdf'].some(opt => type.includes(opt)) ? 'document'
                        : undefined
        }))
        else handleSendProp({ text, variant: 'text' })
        setText('')
        setFiles(null)
    }

    useImperativeHandle(ref, () => ({ setText }), [])

    return <div className="inputs-chat-input-container row">
        <div className='cover-photo' style={{ backgroundImage: `url(${coverPhoto || DEFAULT_AVATAR_URL})` }} />
        {files ? <div className="row row-files">
            {files.map(({ name }, i) => <div
                key={`file-${i}`}
                className="row single-file">
                <span>{name}</span>
                <div
                    className="icon icon-remove"
                    onClick={() => {
                        const newFiles = [...(files || [])]
                        newFiles.splice(i, 1)
                        setFiles(newFiles.length ? newFiles : null)
                    }}
                />
            </div>)}
        </div> : <div className="text-area-container">
            <textarea
                className={`${isDisabled && 'disabled'}`}
                placeholder={t('shared.enterTextHere')}
                value={text || ""}
                rows={Math.min(4, text?.split("\n")?.length || 0 + 1)}
                onChange={({ target: { value } }) => setText(value)}
                onKeyDown={(e) => {
                    const { key, shiftKey } = e || {}
                    if (key === 'Enter' && shiftKey) {
                        e.preventDefault()
                        handleSend()
                    }
                }}
            />
        </div>}
        <div
            style={{ height: Math.min(4, text?.split("\n")?.length || 1) * 18 + 22 }}
            className={`icon icon-send ${isDisabled && 'disabled'}`}
            onClick={handleSend}
        />
        {!hideFileUpload && <div
            className={`icon icon-attach ${text && 'disabled'}`}
            onClick={() => {
                if (text) return
                uploadRef.current.click()
            }}
        />}
        <input
            style={{ display: 'none' }}
            ref={uploadRef}
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={handleUpload}
        />
    </div>
})

export default ChatInput