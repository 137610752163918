import { default as Text } from './Text'
import { default as Comments } from './Comments'
import { default as CoverPhoto } from './CoverPhoto'
import { default as RadioButtons } from './RadioButtons'
import { default as Checkboxes } from './Checkboxes'
import { default as Dropdown } from './Dropdown'
import { default as Datepicker } from './Datepicker'
import { default as Switch } from './Switch'
import { default as Address } from './Address'
import { default as ChatInput } from './ChatInput'

const Inputs = {
    Text,
    Comments,
    CoverPhoto,
    RadioButtons,
    Checkboxes,
    Dropdown,
    Datepicker,
    Switch,
    Address,
    ChatInput
}

export default Inputs