import { emit } from "actions"
import { Buttons, Inputs } from "components"
import { cartStatuses, cartTypes } from "config/constants"
import { useQuery } from "hooks"
import { isEmpty } from "lodash"
import moment from "moment"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import "./styles.scss"

const Cart = () => {
    const { t } = useTranslation()
    const { _id } = useQuery()
    const navigate = useNavigate()
    const { cart } = useSelector(({ carts }) => carts)
    const { identifier, status, cartType, vehicle, cartGroup, workingHours, files } = cart || {}

    useEffect(() => {
        emit({
            action: 'cart/getOne',
            payload: { _id },
            startLoading: true,
            stopLoading: true
        })
    }, [_id])

    return <div className="screen-cart-container">
        <div className="screen-cart-inner-container">
            <div className="screen-cart-header row">
                <Buttons.Back text={t('cart.overview')} />
                <div className="row-buttons row">
                    <Buttons.Raised className="btn-edit" text={t('shared.edit')} onClick={() => navigate(`/cart/form?_id=${_id}`)} />
                    <Buttons.Raised text={t('cart.history')} onClick={() => navigate(`/cart/history?_id=${_id}`)} />
                </div>
            </div>
            <div className="screen-cart-content">
                <h2>{t('cart.generalInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>ID</span>
                        <Inputs.Text value={identifier || ""} disabled />
                    </div>
                    <div className="col">
                        <span>{t('cart.status')}</span>
                        <Inputs.Text value={t(cartStatuses[status]) || ""} disabled />
                    </div>
                    <div className="col">
                        <span>{t('cart.cartType')}</span>
                        <Inputs.Text value={t(cartTypes[cartType]) || ""} disabled />
                    </div>
                    {cartType === 'stationary' &&
                        <div className="col">
                            <span>{t('cart.cartGroup')}</span>
                            <Inputs.Text value={cartGroup?.name || ""} disabled />
                        </div>}
                    {cartType === 'mobile' &&
                        <div className="col">
                            <span>{t('cart.vehicle')}</span>
                            <Inputs.Text value={[vehicle.carMake, vehicle.licensePlate].filter(Boolean).join(' | ') || ""} disabled />
                        </div>}
                </div>
                <div className="cart-group-details col">
                    {cartType === 'stationary' &&
                        <div className="row">
                            <span>{t('cart.address')}: <span>{cartGroup?.address}</span></span>
                            <div className="line" />
                            <span>{t('cart.partnerTax')}: <span>{cartGroup?.locationCommission} {cartGroup?.locationCommissionType === 'fixed' ? t('shared.lv') : '%'}</span></span>
                        </div>}
                    <div className="row">
                        <span style={{ marginRight: 10 }}>{t('cart.workingHours')}:</span>
                        {(isEmpty(workingHours) ? cartGroup?.workingHours : workingHours)?.map((d, i) => <span key={`working-hours-${i}`} className="working-hour-single row">
                            {i !== 0 && <div className="line" />}
                            {moment().startOf('week').add(d.isoWeekday - 1, 'days').format('ddd')}:{" "}
                            {d.open ? <span>{d.opensAt} - {d.closesAt}</span> : <span>{t('shared.no')}</span>}
                        </span>)}
                    </div>
                </div>
                <div className="row-files row">
                    <h2>{t('shared.files')}</h2>
                    {files?.map(({ name, url }, i) => <div
                        key={`file-${i}`}
                        className="single-file row">
                        <a href={url} target="_blank" rel="noreferrer">{name}</a>
                    </div>)}
                </div>
                <h2>{t('shared.notes')}</h2>
            </div>
        </div>
    </div>
}

export default Cart