import { useRef } from "react"
import { uploadFiles } from 'utilities'
import Buttons from ".."
import "./styles.scss"
import { useTranslation } from "react-i18next"

const Upload = ({ inputProps, children, onChange }) => {
    const { t } = useTranslation()
    const uploadRef = useRef()
    const handleUpload = async ({ target: { files } }) => {
        const uploaded = await uploadFiles(files)
        if (onChange) onChange(uploaded.map(({ location }, i) => ({ url: location, name: files[i].name })))
    }

    return <div className="buttons-upload-container" onClick={() => uploadRef.current.click()}>
        {children || <Buttons.Raised text={t('shared.uploadFile')} />}
        <input
            ref={uploadRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleUpload}
            {...inputProps}
        />
    </div>
}

export default Upload