import { configureStore } from '@reduxjs/toolkit'
import general from "./general"
import vehicles from "./vehicles"
import reservations from "./reservations"
import carts from "./carts"
import services from "./services"
import employees from "./employees"
import reservationChat from "./reservationChat"
import discountCodes from "./discountCodes"
import businessClients from "./businessClients"

export * from './auth'
export * from "./general"
export * from './socket'
export * from "./vehicles"
export * from "./reservations"
export * from "./carts"
export * from "./services"
export * from "./employees"
export * from "./reservationChat"
export * from "./discountCodes"
export * from "./businessClients"

const store = configureStore({
    reducer: {
        general,
        vehicles,
        reservations,
        carts,
        services,
        employees,
        reservationChat,
        discountCodes,
        businessClients
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['general/setGeneralFields', 'general/setModal'],
            ignoredPaths: ['general.socket', 'general.modal'],
        }
    })
})

export default store