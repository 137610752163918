import { createSlice } from '@reduxjs/toolkit'
import { pick } from 'lodash'

const initialState = {
    carts: [],
    nextPage: 1,
    hasNextPage: true,
    stats: {},
    cart: {}
}

export const carts = createSlice({
    name: 'carts',
    initialState,
    reducers: {
        setCartsFields: (state, { payload }) => ({ ...state, ...payload }),
        setCarts: (state, { payload }) => {
            const carts = payload.page > 1 ? [...state.carts, ...payload.carts] : payload.carts
            return { ...state, carts, ...pick(payload, ["nextPage", "hasNextPage", 'stats']) }
        },
        setCart: (state, { payload }) => ({ ...state, cart: payload.cart })
    }
})

export const {
    setCartsFields,
    setCarts,
    setCart
} = carts.actions

export default carts.reducer