import { emit } from "actions"
import { Buttons, Inputs } from "components"
import { cartTypes, reservationStatuses } from "config/constants"
import { useQuery } from "hooks"
import moment from "moment"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const Reservation = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { _id } = useQuery()
    const [reservation, setReservation] = useState({})
    useEffect(() => {
        emit({
            action: 'reservation/getOne', payload: { _id },
            startLoading: true, stopLoading: true,
            onSuccess: ({ reservation }) => setReservation(reservation)
        })
    }, [_id])

    return <div className="screen-reservation-container">
        <div className="screen-reservation-inner-container">
            <div className="screen-reservation-header row">
                <Buttons.Back text={`${t('reservation.overview')} - ${reservation.status === 'created' && reservation?.user ? t('reservationStatuses.taken') : t(reservationStatuses[reservation.status])}`} />
                {reservation?.status === 'created' && <Buttons.Raised text={t('shared.edit')} onClick={() => navigate(`/reservation/form?_id=${reservation?._id}`)} />}
            </div>
            <div className="screen-reservation-content">
                <h2>{t('reservation.generalInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('reservation.cartType')}</span>
                        <Inputs.Text disabled value={t(cartTypes[reservation?.reservationType]) || ""} />
                    </div>
                    {reservation?.reservationType === 'stationary' && <>
                        <div className="col">
                            <span>{t('reservation.cartGroup')}</span>
                            <Inputs.Text disabled value={reservation?.cartGroup?.name || ""} />
                        </div>
                    </>}
                    {reservation?.reservationType === 'mobile' && <>
                        <div className="col">
                            <span>{t('reservation.address')}</span>
                            <Inputs.Text disabled value={reservation?.address || ""} />
                        </div>
                        <div className="col col-double">
                            <span>{t('reservation.addressDescription')}</span>
                            <Inputs.Text disabled value={reservation?.addressDescription || ""} />
                        </div>
                    </>}
                    <div className="col col-half">
                        <span>{t('reservation.date')}</span>
                        <Inputs.Text disabled value={reservation?.reservationStart ? moment(reservation?.reservationStart).format("DD.MM") : ""} />
                    </div>
                    <div className="col col-half">
                        <span>{t('reservation.startTime')}</span>
                        <Inputs.Text disabled value={reservation?.reservationStart ? moment(reservation?.reservationStart).format("HH.mm") : ""} />
                    </div>
                    <div className="col col-half">
                        <span>{t('reservation.price')}</span>
                        <Inputs.Text disabled value={reservation?.price ?? ""} />
                    </div>
                </div>
                <div className="divider" />
                <h2>{t('reservation.vehicleInformation')}</h2>
                <div className="vehicles-container">
                    {reservation?.clientVehicles?.map((vehicle) => <div key={vehicle?.licensePlate} className="row row-vehicle">
                        <div className="col">
                            <span>{t('reservation.vehicleType')}</span>
                            <Inputs.Text disabled value={vehicle?.vehicleType?.name || ""} />
                        </div>
                        <div className="col">
                            <span>{t('reservation.service')}</span>
                            <Inputs.Text disabled value={vehicle?.services?.[0]?.name || ""} />
                        </div>
                        <div className="col">
                            <span>{t('reservation.licensePlate')}</span>
                            <Inputs.Text disabled value={vehicle?.licensePlate || ""} />
                        </div>
                        <div className="col">
                            <span>{t('reservation.carMake')}</span>
                            <Inputs.Text disabled value={vehicle?.carMake || ""} />
                        </div>
                    </div>)}
                </div>
                <div className="divider" />
                <h2>{t('reservation.clientInformation')}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('reservation.fullName')}</span>
                        <Inputs.Text disabled value={reservation?.clientInfo?.fullName || reservation?.client?.fullName || ""} />
                    </div>
                    <div className="col">
                        <span>{t('reservation.phoneNumber')}</span>
                        <Inputs.Text disabled value={reservation?.clientInfo?.phoneNumber || reservation?.client?.phoneNumber || ""} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Reservation
