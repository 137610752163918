import axios from 'axios';
import store, { stopLoading } from 'actions';
import { Alerts } from 'utilities';

const httpClient = axios.create({ baseURL: process.env.REACT_APP_API_URL });

httpClient.interceptors.request.use(
    async (config) => {
        const tokenStorage = localStorage.getItem('token') ?? ''
        config.headers = { ...(tokenStorage && { Authorization: tokenStorage }) }
        return config
    },
    (error) => { Promise.reject(error) }
)

httpClient.interceptors.response.use(
    function (response) { return response },
    function (error) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
            store.dispatch(stopLoading())
            Alerts.error({ message: errorMessage })
        }
        return Promise.reject(error)
    });

export default httpClient;
