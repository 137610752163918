/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setModal, setOverlay } from "actions"
import { Buttons, Inputs, Sessions, Shared } from "components"
import { useQuery } from "hooks"
import { debounce } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const Vehicle = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { vehicle } = useSelector(({ vehicles }) => vehicles)
    const { carMake, licensePlate, productionYear, odometer, odometerActual, odometerUpdateDate, odometerActualUpdateDate, _cart, sessions, stats, nextPage, hasNextPage, _odometerHistory } = vehicle || {}
    const { distance, distanceAvg, duration, durationAvg, fuelConsumed, fuelCosts, sessions: totalSessions } = stats || {}

    const { _id } = useQuery()
    const [employees, setEmployees] = useState([])
    const [employee, setEmployee] = useState('')
    const [period, setPeriod] = useState({})

    useEffect(() => {
        emit({
            action: 'user/browse',
            payload: { query: { role: { $nin: ['admin'] } }, pagination: false },
            onSuccess: ({ users }) => setEmployees(users)
        })
    }, [])

    const query = useMemo(() => ({
        user: employee?.value,
        from: period.dateStart ? moment(period.dateStart).startOf('day').toDate() : undefined,
        to: period.dateEnd ? moment(period.dateEnd).endOf('day').toDate() : undefined
    }), [employee, period])

    const fetchSessions = useCallback((payload) => emit({
        action: 'drivingSession/browse',
        payload: { vehicle: _id, ...query, ...payload },
        stopLoading: true
    }), [emit, _id, query])

    const fetch = useCallback((onSuccess) => emit({
        action: 'vehicle/getOne',
        payload: { _id },
        startLoading: true,
        onSuccess
    }), [emit, _id])

    useEffect(() => { fetch() }, [fetch])
    useEffect(() => { fetchSessions() }, [fetchSessions])

    const fetchSessionsDebounced = debounce(fetchSessions, 300)
    const handlePagination = (scrollTop, height, scrollHeight) => {
        if (scrollTop + height >= scrollHeight - 50 && hasNextPage) fetchSessionsDebounced({ pageNumber: nextPage })
    }

    const handleUpdateOdometer = (payload) => emit({
        action: 'vehicle/updateOdometer',
        payload: { vehicle: _id, ...payload, odometerOld: odometer, odometerActualOld: odometerActual },
        startLoading: true,
        stopLoading: true,
        onSuccess: () => {
            fetch(fetchSessions)
            dispatch(setModal({ isOpen: false }))
        }
    })

    return <div className="screen-vehicle-container">
        <div className="screen-vehicle-inner-container">
            <div className="screen-vehicle-header">
                <Buttons.Back text={t('vehicle.overview')} />
            </div>
            <div className="screen-vehicle-content row">
                <div className="screen-vehicle-content-left col">
                    <div className="screen-vehicle-main-container">
                        <div className="screen-vehicle-main-header row">
                            <h2>{carMake} | {licensePlate}</h2>
                            <div className="row">
                                <div className="icon icon-pen" onClick={() => navigate(`/vehicle/form?_id=${_id}`)} />
                                <div className="icon icon-notes" onClick={() => dispatch(setOverlay({ isOpen: true }))} />
                            </div>
                        </div>
                        <div className="screen-vehicle-main-content">
                            <p>{t('vehicle.year')}: <span>{productionYear}</span></p>
                            <p>{t('vehicle.cartId')}: <span>{_cart?.identifier}</span></p>
                        </div>
                    </div>
                    {/* <div className="screen-vehicle-additional-container">
                        <div className="screen-vehicle-files">
                            <div className="row">
                                <h2>{t('shared.documents')}</h2>
                                <Buttons.Upload
                                    inputProps={{ accept: '*', multiple: true }}
                                    onChange={(files) => emit({
                                        action: 'vehicle/edit',
                                        payload: { _id, files: [...(files || []), ...(files || [])] },
                                        startLoading: true,
                                        onSuccess: () => emit({ action: 'vehicle/getOne', payload: { _id }, stopLoading: true, })
                                    })} >
                                    <div className="icon icon-plus" />
                                </Buttons.Upload>
                            </div>
                            <div className="row row-files">{files?.map(({ name, url }, i) => <div
                                key={`file-${i}`}
                                className="single-file row">
                                <a href={url} target="_blank" rel="noreferrer">{name}</a>
                                <div className="icon icon-remove" onClick={() => {
                                    const newFiles = [...files]
                                    newFiles.splice(i, 1)
                                    emit({
                                        action: 'vehicle/edit',
                                        payload: { _id, files: newFiles },
                                        startLoading: true,
                                        onSuccess: () => emit({ action: 'vehicle/getOne', payload: { _id }, stopLoading: true })
                                    })
                                }} />
                            </div>)}</div>
                        </div>
                        <div className="screen-vehicle-comments">
                            <h2>{t('shared.notes')}</h2>
                        </div>
                    </div> */}
                    <div className="screen-vehicle-odometer-container">
                        <h2>{t('vehicle.odometer')}</h2>
                        <div className="row">
                            <p>{t('vehicle.millageByApp')}: <span>{odometer} {t('shared.km')}</span></p>
                            {odometerUpdateDate && <p>{moment(odometerUpdateDate).format("DD.MM.YYYY | HH:mm")}</p>}
                        </div>
                        <div className="row">
                            <p>{t('vehicle.millageByOdometer')}: <span>{Math.round(Number(odometerActual))} {t('shared.km')}</span></p>
                            {odometerActualUpdateDate && <p>{moment(odometerActualUpdateDate).format("DD.MM.YYYY | HH:mm")}</p>}
                        </div>
                    </div>
                    <div className="screen-vehicles-odometer-history-container">
                        <div className="row">
                            <h2>{t('vehicle.millageHistory')}</h2>
                            <div className="icon icon-plus" onClick={() => dispatch(setModal({
                                isOpen: true, type: 'UploadOdometerData', props: {
                                    onSuccess: (payload) => {
                                        if (Math.abs(Number(payload.odometer) - odometer) >= 3) dispatch(setModal({
                                            isOpen: true, type: 'UploadOdometerDataReason', props: {
                                                onSuccess: (reason) => handleUpdateOdometer({ ...payload, reason })
                                            }
                                        }))
                                        else handleUpdateOdometer(payload)
                                    }
                                }
                            }))} />
                        </div>
                        <Shared.Table
                            containerStyle={{ height: 1 }}
                            columns={[
                                { label: 'odometerHistoryColumns.createdAt', value: 'createdAt', size: 150 },
                                { label: 'odometerHistoryColumns.odometer', value: 'odometer', size: 200 },
                                { label: 'odometerHistoryColumns.odometerOld', value: 'odometerOld', size: 140 },
                                { label: 'odometerHistoryColumns.odometerActualOld', value: 'odometerActualOld', size: 140 },
                            ]}
                            data={_odometerHistory}
                            renderCell={(row, field) => {
                                switch (field) {
                                    case 'createdAt':
                                        return moment(row.createdAt).format("DD.MM.YYYY | HH:mm")
                                    case "odometer":
                                        return <div className="row" style={{ width: '100%', justifyContent: 'space-between', paddingRight: 14 }}>
                                            {Number(row?.[field] ?? 0).toFixed(2) + ` ${t('shared.km')}`}
                                            {row?.reason &&
                                                <Popup
                                                    trigger={<div className="icon icon-comment" style={{ height: 20, width: 20 }} />}
                                                    on={['hover', 'focus']}
                                                >
                                                    <p style={{ margin: 0, textAlign: 'center' }}>{row?.reason}</p>
                                                </Popup>
                                            }
                                            <a href={row?.pictures?.[0]?.url} target="_blank" rel="noreferrer">
                                                <div className="icon icon-picture" style={{ height: 20, width: 20 }} />
                                            </a>
                                        </div>
                                    case 'odometerOld':
                                    case 'odometerActualOld':
                                        return Number(row?.[field] ?? 0).toFixed(2) + ` ${t('shared.km')}`
                                    default:
                                        return null
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="screen-vehicle-sessions-container col">
                    <div className="screen-vehicle-sessions-header row">
                        <h2>{t('vehicle.sessions')}</h2>
                        <div className="row">
                            <span>{t('vehicle.employee')}:</span>
                            <Inputs.Dropdown
                                placeholder={t('vehicle.employeePlaceholder')}
                                options={employees?.map(({ _id, fullName }) => ({ value: _id, label: fullName }))}
                                value={employee}
                                onChange={setEmployee}
                                isClearable
                            />
                        </div>
                        <div className="row">
                            <span>{t('vehicle.periodFrom')}:</span>
                            <Inputs.Datepicker
                                selected={period.dateStart}
                                onChange={([dateStart, dateEnd]) => setPeriod({ dateStart, dateEnd })}
                                startDate={period.dateStart}
                                endDate={period.dateEnd}
                                selectsRange
                                isClearable
                                className={period.dateStart || period.dateEnd ? "has-value" : ""}
                            />
                        </div>
                    </div>
                    <div
                        className="screen-vehicle-sessions-content"
                        onScroll={({ target }) =>
                            handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)
                        }
                    >
                        <div className="screen-vehicle-sessions-statistics-container col">
                            <div className="row">
                                <h2>{t('vehicle.generalInformation')}</h2>
                                {console.log()}
                                <div className="row">
                                    <p>{t('vehicle.time')}: {[
                                        moment.duration(duration, 'minutes').minutes(),
                                        moment.duration(duration, 'minutes').hours(),
                                        moment.duration(duration, 'minutes').days(),
                                    ]
                                        .filter(Boolean)
                                        .map((unit, i) => `${unit} ${i === 0 ? t('shared.min') : i === 1 ? t('shared.h') : t('shared.d')}`)
                                        .reverse()
                                        .join(" ") || `0 ${t('shared.min')}`}
                                    </p>
                                    <p>{t('vehicle.kilometers')}: {Number(distance ?? 0).toFixed(2)} {t('shared.km')}</p>
                                    <p>{t('vehicle.numberOfSessions')}: {totalSessions ?? 0} </p>
                                </div>
                            </div>
                            <div className="row">
                                <h2>{t('vehicle.averageSession')}</h2>
                                <div className="row">
                                    <p>{t('vehicle.time')}: {[
                                        moment.duration(durationAvg, 'minutes').minutes(),
                                        moment.duration(durationAvg, 'minutes').hours(),
                                        moment.duration(durationAvg, 'minutes').days(),
                                    ]
                                        .filter(Boolean)
                                        .map((unit, i) => `${unit} ${i === 0 ? t('shared.min') : i === 1 ? t('shared.h') : t('shared.d')}`)
                                        .reverse()
                                        .join(" ") || `0 ${t('shared.min')}`}
                                    </p>
                                    <p>{t('vehicle.kilometers')}: {Number(distanceAvg ?? 0).toFixed(2)}  {t('shared.km')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <h2>{t('vehicle.costs')}</h2>
                                <div className="row">
                                    <p>{t('vehicle.fuel')}: {Number(fuelConsumed ?? 0).toFixed(2)} {t('shared.l')}</p>
                                    <p>{t('vehicle.fuelCosts')}: {Number(fuelCosts ?? 0).toFixed(2)} {t('shared.lv')}</p>
                                </div>
                            </div>
                        </div>
                        {sessions?.map((session) => <Sessions.SessionInfo key={session._id} session={session} />)}</div>
                </div>
            </div>
        </div>
    </div>
}

export default Vehicle