import io from "socket.io-client"
import { BrowserRouter } from 'react-router-dom'
import { Routes } from 'components'
import { useEffect } from 'react';
import { URL } from "config/settings";
import { useDispatch } from "react-redux";
import { logout, mapSocketActions, setGeneralFields, stopLoading } from "actions";
import { Alerts } from "utilities";
import 'moment/locale/bg'
import { isArray } from "lodash";

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('token')
    let socket
    if (token) socket = io(URL, { query: `token=${token}` })
    else socket = io(URL, { query: `guest=true` })
    if (socket) {
      socket.on('connect', () => dispatch(setGeneralFields({ socket })))
      socket.on('socket-message', ({ action, payload }) => {
        console.log("Socket in: ", { action, payload });
        if (mapSocketActions[action]) {
          if (isArray(mapSocketActions[action])) mapSocketActions[action].forEach((func) => func(payload))
          else dispatch(mapSocketActions[action](payload))
        }
        if (action.includes('generic')) {
          dispatch(stopLoading())
          Alerts.error({ text: payload.error })
        }
      })
      socket.on('error', (text) => {
        if (text === 'Authentication failed - 401') logout()
        Alerts.error({ text })
      })
    }
    return () => socket.disconnect()
  }, [dispatch])


  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
