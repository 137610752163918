import moment from "moment"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const SessionInfo = ({ session }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    _id,
    averageSpeed,
    distance,
    drivingEnd,
    drivingStart,
    endAddress,
    maximumSpeed,
    startAddress,
    user,
  } = session || {}

  const hours = moment(drivingEnd).diff(moment(drivingStart), 'hours')
  const minutes = moment(drivingEnd).diff(moment(drivingStart), 'minutes') - (hours * 60)

  return <div className="sessions-session-info-container" onClick={() => navigate(`/session?_id=${_id}`)}>
    <div className="sessions-session-info-header row">
      <h2>{moment(drivingStart).format("DD MMM - dddd")}</h2>
      <div className="icon icon-path" />
    </div>
    <div className="sessions-session-info-content">
      <div className="screen-session-info-inner-content row">
        {[
          { label: 'sessionColumns.drivingStart', value: moment(drivingStart).format("HH:mm") },
          { label: 'sessionColumns.drivingEnd', value: moment(drivingEnd).format("HH:mm") },
          { label: 'sessionColumns.duration', value: `${hours} ${t('shared.h')} ${minutes} ${t('shared.min')}` },
          { label: 'sessionColumns.user', value: user?.fullName },
          { label: 'sessionColumns.distance', value: distance + ` ${t('shared.km')}` },
          { label: 'sessionColumns.averageSpeed', value: averageSpeed?.toFixed(2) + ` ${t('shared.kmh')}` },
          { label: 'sessionColumns.maximumSpeed', value: maximumSpeed?.toFixed(2) + ` ${t('shared.kmh')}` },
          { label: 'sessionColumns.startAddress', value: startAddress },
          { label: 'sessionColumns.endAddress', value: endAddress },
        ].map(({ label, value }) => <div className="row" key={`session-data-${label}`}>
          <p>{t(label)}: <span>{value}</span></p>
          {['sessionColumns.user'].includes(label) && (
            <div
              className="icon icon-profile"
              onClick={() => navigate(`/employee?_id=${user?._id}`)}
            />
          )}
        </div>)}
      </div>
    </div>
  </div>
}

export default SessionInfo