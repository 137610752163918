import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import "./styles.scss"

const DatePickerComp = ({ value, placeholder, dateFormat = 'dd.MM.yyyy', className = '', inline, ...props }) => {
    try {
        new Date(value).toISOString()
    } catch (e) {
        value = null
    }

    return (
        <div className={`inputs-datepicker-container ${className}`}>
            <DatePicker
                placeholderText={placeholder}
                dateFormat={dateFormat}
                selected={value ? moment(value).toDate() : null}
                calendarStartDay={1}
                inline={inline}
                {...props}
            />
            {!inline && <div className={`icon icon-${props?.showTimeSelect ? 'clock' : 'calendar'}`} />}
        </div>
    )
}


export default DatePickerComp