import { emit, setEmployeesFields } from "actions"
import { Buttons, Inputs } from "components"
import { useQuery } from "hooks"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const User = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { employee } = useSelector(({ employees }) => employees)
    const { coverPhoto, fullName, role, phoneNumber, email } = employee || {}


    const { _id } = useQuery()
    const dispatch = useDispatch()
    useEffect(() => {
        emit({
            action: 'user/info',
            payload: { _id },
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ user }) => dispatch(setEmployeesFields({ employee: user }))
        })
        return () => dispatch(setEmployeesFields({ employee: {} }))
    }, [_id, dispatch])

    return <div className="screen-user-container">
        <div className="screen-user-inner-container">
            <div className="screen-user-header row">
                <Buttons.Back text={t('user.overview')} />
                <Buttons.Raised text={t('shared.edit')} onClick={() => navigate(`/user/form?_id=${_id}`)} />
            </div>
            <div className="screen-user-content">
                <div className="screen-user-form">
                    <div className="row row-header">
                        <h2>{t('user.singleUser')} 1</h2>
                    </div>
                    <div className="row row-form">
                        <Inputs.CoverPhoto value={coverPhoto} disabled />
                        <div className="col">
                            <span>{t('user.fullName')}</span>
                            <Inputs.Text value={fullName || ""} disabled />
                        </div>
                        <div className="col">
                            <span>{t('user.role')}</span>
                            <Inputs.RadioButtons
                                disabled
                                value={role}
                                options={[{ label: t('users.admin'), value: "admin" }, { label: t('users.team'), value: "cartOperator" }]}
                            />
                        </div>
                        <div className="col">
                            <span>{t('user.phoneNumber')}</span>
                            <Inputs.Text value={phoneNumber || ""} disabled />

                        </div>
                        <div className="col">
                            <span>{t('user.email')}</span>
                            <Inputs.Text value={email || ""} disabled />
                        </div>
                    </div>
                    <div className="row row-role">
                        {t('user.roleDescription')}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default User