/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setCartsFields } from "actions"
import { Shared, Buttons } from "components"
import { cartsColumns } from "config/constants"
import { isEmpty, debounce } from "lodash"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { renderCell } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const Carts = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { carts, nextPage, hasNextPage, stats } = useSelector(({ carts }) => carts)
    const { allCarts, mobile, stationary } = stats || {}

    const fetch = () => emit({
        action: 'cart/browse',
        payload: { pageNumber: nextPage, stats },
        startLoading: isEmpty(carts) || nextPage !== 1,
        stopLoading: true,
    })
    const fetchDebounced = debounce(fetch, 300)

    const dispatch = useDispatch()
    useEffect(() => {
        fetch()
        return () => dispatch(setCartsFields({ nextPage: 1, hasNextPage: true }))
    }, [])

    return <div className="screen-carts-container">
        <div className="screen-carts-inner-container">
            <div className="screen-carts-content">
                <Shared.Table
                    fixedWidth
                    columns={cartsColumns}
                    data={carts}
                    renderCell={(cart, field) => renderCell.carts(cart, field, { navigate, t })}
                    handlePagination={() => hasNextPage && fetchDebounced()}
                />
            </div>
            <div className="screen-carts-footer row">
                <div className="row row-stats">
                    <p>{t('carts.count')}: <span>{allCarts}</span> ({t('shared.mobile')} <span>{mobile}</span>, {t('shared.stationary')} <span>{stationary}</span>)</p>
                </div>
                <Buttons.Raised
                    text={t('carts.createCart')}
                    onClick={() => navigate('/cart/form')}
                />
            </div>
        </div>
    </div>
}

export default Carts