import { createSlice } from '@reduxjs/toolkit'
import { pick } from 'lodash'

const initialState = {
    employees: [],
    nextPage: 1,
    hasNextPage: true,
    totalDocs: 0,
    employee: {}
}

export const employees = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setEmployeesFields: (state, { payload }) => ({ ...state, ...payload }),
        setEmployees: (state, { payload }) => {
            const employees = payload.page > 1 ? [...state.employees, ...payload.users] : payload.users
            return { ...state, employees, ...pick(payload, ["nextPage", "hasNextPage", 'totalDocs']) }
        }
    }
})

export const {
    setEmployeesFields,
    setEmployees
} = employees.actions

export default employees.reducer