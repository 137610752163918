import { isEmpty } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const VehicleInfo = ({ vehicle }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { _id, carMake, licensePlate, workingStatus, lastDrivingSession, _reservations } = vehicle || {}
    const { _id: session_id, drivingStart, drivingEnd, startAddress, endAddress, user } = lastDrivingSession || {}
    const active = useMemo(() => lastDrivingSession && !drivingEnd, [lastDrivingSession, drivingEnd])

    const workingStatusText = workingStatus === 'inUse' ? t('fleet.inUse') : t('fleet.available')
    const reservationStatusText =
        _reservations.every(({ status }) => status === 'completed')
            ? ``
            : ` - ${_reservations.some(({ status }) => status === 'inProgress') ? t('fleet.inProgress') : t('fleet.notInProgress')}`

    return <div className={`fleet-vehicle-info-container ${active ? 'active' : ''}`}>
        <div className="fleet-vehicle-info-header row">
            <h3>{carMake} | {licensePlate}</h3>
            <div
                className={`icon icon-vehicle${active ? "-white" : ""}`}
                onClick={() => navigate(`/vehicle?_id=${_id}`)}
            />
        </div>
        {lastDrivingSession ? <>
            <div className="fleet-vehicle-info-content">
                {active ? <>
                    <p>{t('fleet.status')}: <span>{t('fleet.inTransit')}</span></p>
                    <p>{t('fleet.driver')}: <span>{user?.fullName}</span></p>
                    <p>{t('fleet.startedAt')}: <span>{moment(drivingStart).format("HH:mm | DD.MM.YYYY")}</span></p>
                    <p>{t('fleet.startedFrom')}: <span>{startAddress}</span></p>
                </> : <>
                    <p>{t('fleet.status')}: <span>{t('fleet.parked')} - {workingStatusText}{!isEmpty(_reservations) && reservationStatusText}</span></p>
                    <p>{t('fleet.location')}: <span>{endAddress}</span></p>
                    <p>{t('fleet.lastDriver')}: <span>{user?.fullName}</span></p>
                    <p>{t('fleet.startedAt')}: <span>{moment(drivingStart).format("HH:mm | DD.MM.YYYY")}</span></p>
                    <p>{t('fleet.startedFrom')}: <span>{startAddress}</span></p>
                    <p>{t('fleet.endedAt')}: <span>{moment(drivingEnd).format("HH:mm | DD.MM.YYYY")}</span></p>
                    <p>{t('fleet.parkedFrom')}: <span>{moment().diff(moment(drivingEnd), 'minutes')} {t('shared.minutes')}</span></p>
                </>}
            </div>
            <div className="fleet-vehicle-info-footer row">
                <div
                    className="fleet-vehicle-info-inner-footer row"
                    onClick={() => navigate(`/session?_id=${session_id}`)}
                >
                    <div className="icon" />
                    <p>{t('session.overview')}</p>
                    <div className="icon icon-path" />
                </div>
            </div>
        </> : <div className="fleet-vehicle-info-empty">{t('fleet.emptyState')}</div>}

    </div>
}

export default VehicleInfo