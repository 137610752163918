import { emit, setReservationChatFields } from "actions"
import { Inputs } from "components"
import { DEFAULT_AVATAR_URL } from "config/settings"
import { useQuery } from "hooks"
import { debounce, isEmpty } from "lodash"
import moment from "moment"
import { Fragment, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const ReservationChat = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(({ general }) => general)
    const { _id, reservation } = useQuery()
    const {
        docs,
        nextPage,
        hasNextPage
    } = useSelector(({ reservationChat }) => reservationChat)


    const fetch = useCallback((payload) => emit({
        action: 'chat/getMessages',
        payload: { limit: 30, ...payload },
        startLoading: true,
        stopLoading: true,
    }), [])
    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => {
        if (_id) fetch({ roomId: _id, })
        return () => dispatch(setReservationChatFields({ room: "" }))
    }, [_id, dispatch, fetch])

    const handlePagination = (scrollTop, height, scrollHeight) => {
        if (Math.abs(scrollTop) + height >= scrollHeight - 50 && hasNextPage) fetchDebounced({ roomId: _id, page: nextPage })
    }

    const renderMessage = ({ _id, createdAt, variant, text, senderType, sender }, i) => <Fragment key={_id}>
        <div className={`message ${sender._id === user._id && "me"}`}>
            {docs?.[i + 1]?.sender?._id !== sender?._id && <p>{sender?.fullName}{senderType === 'Client' && " | "}{senderType === 'Client' && <span>{t('shared.client')}</span>}</p>}
            <div className="message-content row">
                <div className="col">
                    <div className={`placeholder logo`}
                        style={{ backgroundImage: `url(${sender?.coverPhoto || DEFAULT_AVATAR_URL})` }}
                    />
                    {createdAt && <span className="time">{moment(createdAt).format("HH:mm")}</span>}
                </div>
                {variant === 'photo' ? <a href={text} target="_blank" rel="noopener noreferrer"><img src={text} alt={""} /></a>
                    : variant === 'video' ? <video className="video" width="426" height="240" src={text} controls />
                        : variant === 'document' ? <a href={text} target="_blank" rel="noopener noreferrer"><p className="text">{text}</p></a>
                            : <p className="text">{text}</p>}
            </div>
        </div>
        {(!moment(createdAt).isSame(moment(docs?.[i + 1]?.createdAt), 'day') || i === docs.length - 1) && (
            <div className="row days-divider">
                <p>{moment(createdAt).isSame(moment(), 'day') ? t('shared.today') : moment(createdAt).isSame(moment().subtract(1, 'd'), 'day') ? t('shared.yesterday') : moment(createdAt).format('DD.MM.YYYY')}</p>
                <span className="line" />
            </div>
        )}
    </Fragment>

    return <div className="screen-reservation-chat-container">
        <div className="screen-reservation-chat-inner-container col">
            <div className="screen-reservation-chat-header">
                <div className="row row-close">
                    <div className="icon icon-close" onClick={() => navigate(-1)} />
                    {t('reservationChat.chat')}
                </div>
            </div>
            {isEmpty(docs)
                ? <div className="row empty"><p>{t('reservationChat.empty')}</p></div>
                : <div
                    className="screen-reservation-chat-content col"
                    onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
                >{docs?.map(renderMessage)}</div>
            }
            <div className="screen-reservation-chat-input">
                <Inputs.ChatInput
                    coverPhoto={user?.coverPhoto || DEFAULT_AVATAR_URL}
                    handleSend={({ text, variant }) => emit({
                        action: 'chat/sendMessage',
                        payload: { roomId: _id, reservationId: reservation, text, variant }
                    })}
                />
            </div>
        </div>
    </div>
}

export default ReservationChat