import React, { useEffect, useState } from "react"
import "./styles.scss"
import { emit } from "actions"
import Inputs from "components/Inputs"
import moment from "moment"
import { useTranslation } from "react-i18next"
import Buttons from "components/Buttons"

const ReservationForward = ({ close, reservation = {} }) => {
    const { t } = useTranslation()
    const [slots, setSlots] = useState([])
    const [state, setState] = useState(null)

    useEffect(() => {
        emit({
            action: 'reservation/findSlot',
            payload: {
                reservationType: reservation?.reservationType,
                cartGroup: reservation?.cartGroup?._id ?? reservation?.cartGroup,
                date: reservation?.reservationStart,
                city: reservation?.city?.name,
                duration: reservation?.expectedDuration,
                returnWithCarts: true
            },
            onSuccess: ({ availableSlots }) => setSlots(
                Object.entries(availableSlots)
                    ?.find(([key]) => moment(key).format() === moment(reservation.reservationStart).format())
                    ?.[1]
                    ?.filter(({ cart }) => cart !== reservation.cartBySystem._id)
                ?? []
            )
        })
    }, [reservation])

    const handleReservationForward = () => emit({
        action: 'reservation/changeCartBySystem',
        payload: { _id: reservation._id, cartBySystem: state.value },
        onSuccess: () => close(),
        startLoading: true,
        stopLoading: true,
    })

    return <div className="modal-reservation-forward-container">
        <div className="modal-reservation-forward-header row">
            <h2>{t('reservationForward.reservationForward')}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        {slots.length
            ? <>
                <div className="modal-reservation-forward-content">
                    <Inputs.Dropdown
                        placeholder={t('reservationForward.dropdownPlaceholder')}
                        options={slots.map(({ cart, cartName }) => ({ value: cart, label: cartName }))}
                        value={state}
                        onChange={setState}
                        isOptionDisabled={({ value }) => value === reservation.cartBySystem._id}
                    />
                </div>
                <div className="modal-reservation-forward-footer row">
                    <Buttons.Raised className="cancel" text={t('shared.cancel')} onClick={close} />
                    <Buttons.Raised text={t('shared.save')} disabled={!state} onClick={handleReservationForward} />
                </div>
            </>
            : <>

                <div className="modal-reservation-forward-content">
                    <h3>{t('reservationForward.noAvailableCarts')}</h3>
                </div>
            </>
        }
    </div>
}

export default ReservationForward