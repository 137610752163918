export const reservationsColumns = [
    { label: 'reservationsColumns.status', value: 'status', size: 200, sortable: true },
    { label: 'reservationsColumns.reservationStartDate', value: 'reservationStartDate', size: 130, sortable: true },
    { label: 'reservationsColumns.orderedFrom', value: 'orderedFrom', size: 180, sortable: true },
    { label: 'reservationsColumns.reservationStart', value: 'reservationStart', size: 140 },
    { label: 'reservationsColumns.cleaningStart', value: 'cleaningStart', size: 140 },
    { label: 'reservationsColumns.expectedDuration', value: 'expectedDuration', size: 200 },
    { label: 'reservationsColumns.extended', value: 'extended', size: 140 },
    { label: 'reservationsColumns.city', value: 'city', size: 100, sortable: true },
    { label: 'reservationsColumns.duration', value: 'duration', size: 200 },
    { label: 'reservationsColumns.client', value: 'client', size: 250 },
    { label: 'reservationsColumns.phoneNumber', value: 'phoneNumber', size: 200, sortable: true },
    { label: 'reservationsColumns.clientVehicles', value: 'clientVehicles' },
    { label: 'reservationsColumns.user', value: 'user', size: 250 },
    { label: 'reservationsColumns.reservationType', value: 'reservationType', size: 180 },
    { label: 'reservationsColumns.cartGroup', value: 'cartGroup' },
    { label: 'reservationsColumns.vehicle', value: 'vehicle' },
    { label: 'reservationsColumns.cart', value: 'cart', size: 150 },
    { label: 'reservationsColumns.cartBySystem', value: 'cartBySystem', size: 190 },
    { label: 'reservationsColumns.paymentMethod', value: 'paymentMethod', size: 260 },
    { label: 'reservationsColumns.discountCampaign', value: 'discountCampaign', size: 140 },
    { label: 'reservationsColumns.organization', value: 'organization', size: 250 },
    { label: 'reservationsColumns.price', value: 'price', size: 130 },
    { label: 'reservationsColumns.stripeTax', value: 'stripeTax', size: 130 },
    { label: 'reservationsColumns.partnerTax', value: 'partnerTax', size: 140 },
    { label: 'reservationsColumns.amarantTax', value: 'amarantTax', size: 120 },
    { label: 'reservationsColumns.net', value: 'net', size: 120 },
    { label: 'reservationsColumns.ratings', value: 'ratings', size: 100 },
    { label: 'reservationsColumns.chat', value: 'chat', size: 200 },
    { label: 'reservationsColumns.notes', value: 'notes', size: 200 },
]

export const cartTypes = {
    'stationary': 'cartTypes.stationary',
    'mobile': 'cartTypes.mobile'
}

export const reservationPaymentMethods = {
    'card': 'reservationPaymentMethods.card',
    'cash': 'reservationPaymentMethods.cash'
}

export const reservationSelectedPaymentMethods = {
    'card': 'reservationSelectedPaymentMethods.card',
    'googlePay': 'reservationSelectedPaymentMethods.googlePay',
    'applePay': 'reservationSelectedPaymentMethods.applePay',
    'manual': 'reservationSelectedPaymentMethods.manual',
}

export const reservationStatuses = {
    'created': 'reservationStatuses.created',
    'taken': 'reservationStatuses.taken',
    'cancelled': 'reservationStatuses.cancelled',
    'completed': 'reservationStatuses.completed',
    'inProgress': 'reservationStatuses.inProgress'
}

export const cartsColumns = [
    { label: 'cartsColumns.identifier', value: 'identifier', size: 150 },
    { label: 'cartsColumns.status', value: 'status', size: 150 },
    // { label: 'cartsColumns.city', value: 'city' },
    { label: 'cartsColumns.cartType', value: 'cartType', size: 200 },
    { label: 'cartsColumns.cartGroup', value: 'cartGroup' }, //name
    { label: 'cartsColumns.vehicle', value: 'vehicle' },
    { label: 'cartsColumns.services', value: 'services', size: 200 },
    { label: 'cartsColumns.employee', value: 'employee' },
]

export const cartHistoryColumns = [
    { label: 'cartHistoryColumns.date', value: 'date', size: 150 },
    { label: 'cartHistoryColumns.time', value: 'time', size: 150 },
    { label: 'cartHistoryColumns.user', value: 'user', size: 150 },
    { label: 'cartHistoryColumns.status', value: 'status', size: 150 },

]

export const cartStatuses = {
    'operational': 'cartStatuses.operational',
    'outOfOrder': 'cartStatuses.outOfOrder'
}

export const servicesColumns = [
    { label: 'servicesColumns.name', value: 'name', size: 500 },
    { label: 'servicesColumns.vehicleType', value: 'vehicleType' },
    { label: 'servicesColumns.price', value: 'price', size: 100 },
    { label: 'servicesColumns.duration', value: 'duration', size: 200 },
    { label: 'servicesColumns.breakAfter', value: 'breakAfter', size: 200 },
]

export const employeesColumns = [
    { label: 'employeesColumns.fullName', value: 'fullName' },
    { label: 'employeesColumns.phoneNumber', value: 'phoneNumber', size: 200 },
    { label: 'employeesColumns.email', value: 'email', size: 200 },
    { label: 'employeesColumns.cartType', value: 'cartType', size: 200 },
    { label: 'employeesColumns.cartGroup', value: 'cartGroup' },
    { label: 'employeesColumns.lastCart', value: 'lastCart' },
    { label: 'employeesColumns.grossSalary', value: 'grossSalary', size: 150 },
    { label: 'employeesColumns.netSalary', value: 'netSalary', size: 150 },
    { label: 'employeesColumns.holidays', value: 'holidays', size: 170 },
]

export const usersColumns = [
    { label: 'usersColumns.fullName', value: 'fullName' },
    { label: 'usersColumns.role', value: 'role', size: 200 },
    { label: 'usersColumns.phoneNumber', value: 'phoneNumber', size: 200 },
    { label: 'usersColumns.email', value: 'email' },
]

export const employeeStatsColumns = [
    { label: 'employeeStatsColumns.day', value: 'day', size: 200 },
    { label: 'employeeStatsColumns.workStart', value: 'workStart', size: 200 },
    { label: 'employeeStatsColumns.workEnd', value: 'workEnd', size: 200 },
    { label: 'employeeStatsColumns.workDayDuration', value: 'workDayDuration' },
    { label: 'employeeStatsColumns.breaksDuration', value: 'breaksDuration' },
    { label: 'employeeStatsColumns.reservations', value: 'reservations' },
    { label: 'employeeStatsColumns.cart', value: 'cart' },
    { label: 'employeeStatsColumns.ratingsScore', value: 'ratingsScore', size: 200 },
    { label: 'employeeStatsColumns.ratings', value: 'ratings', size: 200 },
]

export const discountCodeTypes = [
    { label: 'discountCodeTypes.generic', value: 'generic' },
    { label: 'discountCodeTypes.personal', value: 'personal' },
]

export const discountConditionTypes = [
    { label: 'discountConditionTypes.1', value: '1' },
    { label: 'discountConditionTypes.0', value: '0' },
    { label: 'discountConditionTypes.5', value: '5' },
]

export const discountCodesColumns = [
    { label: 'discountCodesColumns.name', value: 'name' },
    { label: 'discountCodesColumns.status', value: 'status' },
    { label: 'discountCodesColumns.campaignStart', value: 'campaignStart' },
    { label: 'discountCodesColumns.campaignEnd', value: 'campaignEnd' },
    { label: 'discountCodesColumns.discountAmount', value: 'discountAmount' },
    { label: 'discountCodesColumns.discountCondition', value: 'discountCondition' },
    { label: 'discountCodesColumns.codeType', value: 'codeType' },
    { label: 'discountCodesColumns._discountCodesCount', value: '_discountCodesCount' },
]

export const discountCodeColumns = [
    { label: 'discountCodeColumns.code', value: 'code' },
    { label: 'discountCodeColumns._reservationsCount', value: '_reservationsCount' },
    { label: 'discountCodeColumns.phoneNumber', value: 'phoneNumber' },
    { label: 'discountCodeColumns.fullName', value: 'fullName' },
]

export const businessClientsColumns = [
    { label: 'businessClientsColumns.name', value: 'name', size: 500 },
    { label: 'businessClientsColumns._clientsCount', value: '_clientsCount', size: 100 },
    { label: 'businessClientsColumns.files', value: 'files', size: 100 },
    { label: 'businessClientsColumns._commentsCount', value: '_commentsCount', size: 100 },
    { label: '', value: 'open', size: 30 },
]

export const SOFIA_COORDS = {
    latitude: 42.698334,
    longitude: 23.319941
}

export const PLOVDIV_COORDS = {
    latitude: 42.136097,
    longitude: 24.742168
}





