import { emit, setDiscountCodesFields } from "actions"
import { Buttons, Shared } from "components"
import { discountCodeColumns } from "config/constants"
import { useQuery } from "hooks"
import moment from "moment"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Alerts, renderCell, xlsxExport } from "utilities"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const DiscountCode = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { _id } = useQuery()

    const { discountCampaign } = useSelector(({ discountCodes }) => discountCodes) || {}
    const { campaignEnd, _discountCodes } = discountCampaign || {}

    const fetch = useCallback((payload, onSuccess) => emit({
        action: 'discount/getCampaign',
        payload: { _id, ...payload },
        startLoading: true,
        stopLoading: true,
        onSuccess: onSuccess ? (payload) => onSuccess(payload) : ({ discountCampaign }) => dispatch(setDiscountCodesFields({ discountCampaign }))
    }), [_id, dispatch])
    useEffect(() => { fetch() }, [fetch])

    const handleDelete = (_id) => Alerts.confirm({
        title: t('discountCode.revokeTitle'),
        text: t('discountCode.revokeText'),
        cancelButtonText: t('shared.cancel'),
        confirmButtonText: t('shared.revoke'),
        onSuccess: () => emit({ action: 'discount/deleteCodes', payload: { ids: [_id] }, startLoading: true, onSuccess: fetch })
    })

    return <div className="screen-discount-code-container">
        <div className="screen-discount-code-inner-container">
            <div className="screen-discount-code-header row">
                <Buttons.Back text={`${t('discountCode.overview')} - ${discountCampaign?.name}`} />
                {moment(campaignEnd).isAfter(moment()) && <Buttons.Raised text={t('shared.edit')} onClick={() => navigate(`/discount-code/form?_id=${_id}`)} />}
            </div>
            <div className="screen-discount-code-content">
                <Shared.Table
                    fixedWidth
                    columns={discountCodeColumns}
                    data={_discountCodes}
                    renderCell={(discountCode, field) => renderCell.discountCode(discountCode, field, { navigate, handleDelete, t })}
                    headerHight={65}
                />
            </div>
            <div className="screen-discount-code-footer row">
                <div className="row row-stats">
                    <p>{t('discountCode.codes')}: <span>{_discountCodes?.length ?? 0}</span> </p>
                </div>
                <div className="row row-buttons">
                    <div
                        className="icon icon-export"
                        onClick={() => fetch({}, ({ discountCampaign }) => xlsxExport({
                            fields: discountCodeColumns,
                            data: discountCampaign._discountCodes,
                            render: renderCell.discountCodeExport,
                            fileName: `campaigns`,
                            t
                        }))}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default DiscountCode