import { emit } from "actions"
import Buttons from "components/Buttons"
import Inputs from "components/Inputs"
import { pick, times } from "lodash"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useForm, useWatch, Controller, useFormState } from "react-hook-form"
import "./styles.scss"
import { Alerts, getDistanceFromLatLonInKm } from "utilities"
import { PLOVDIV_COORDS, SOFIA_COORDS } from "config/constants"
import { useTranslation } from "react-i18next"

const CartGroupForm = ({ close, onSuccess, cartGroup, }) => {
    const { t } = useTranslation()
    const autoCompleteRef = useRef(null)
    const { control, trigger, register, handleSubmit, formState: { errors }, reset, setError, clearErrors, setValue } = useForm({
        defaultValues: {
            locationCommissionType: "fixed",
            workingHours: times(7).map(i => ({ isoWeekday: i + 1, open: true, opensAt: "08:00", closesAt: "22:00" }))
        }
    })
    const locationCommissionType = useWatch({ control, name: "locationCommissionType", });
    const workingHours = useWatch({ control, name: "workingHours", });
    const { isSubmitted } = useFormState({ control });

    const handleCreate = (payload) => {
        if (!payload.location) {
            setError('address', { type: "custom", message: "Missing coordinates" })
            return
        }
        if (payload.locationCommissionType === 'percent') payload.locationCommission = payload.locationCommission / 100
        emit({
            action: 'cartGroup/add',
            payload,
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ cartGroup }) => {
                if (onSuccess) onSuccess({ cartGroup })
                close()
            }
        })
    }

    const handleDelete = () => { }
    const handleEdit = payload => {
        if (payload.locationCommissionType === 'percent') payload.locationCommission = payload.locationCommission / 100
        emit({
            action: 'cartGroup/edit',
            payload: { _id: cartGroup._id, ...payload },
            startLoading: true,
            stopLoading: true,
            onSuccess: ({ cartGroup }) => {
                if (onSuccess) onSuccess({ cartGroup, edit: true })
                close()
            }
        })
    }

    useEffect(() => {
        if (cartGroup) reset({ ...pick(cartGroup, ['name', 'address', 'locationCommission', 'locationCommissionType', 'workingHours', 'location', 'city']) })
    }, [cartGroup, reset])

    const [cities, setCities] = useState([])
    useEffect(() => { emit({ action: 'city/browse', payload: { pagination: false }, onSuccess: ({ result: { docs } }) => setCities(docs) }) }, [])
    const cityName = useWatch({ control, name: 'cityName' }) || ''
    useEffect(() => { if (cityName) { setValue('city', cities.find(({ name }) => name === cityName)._id); setValue('cityName', undefined) } }, [cities, cityName, setValue])

    return <div className="cart-group-form-container">
        <div className="cart-group-form-header row">
            <div className="icon icon-close" onClick={close} />
            <h2>{cartGroup ? t('shared.editing') : t('shared.creating')} {t('cart.ofCartGroup')}</h2>
        </div>
        <div className="cart-group-form-content">
            <div className="row">
                <div className="col">
                    <span>{t('cart.name')} <span className="icon icon-required" /></span>
                    <Inputs.Text
                        className={errors.name ? "invalid" : ''}
                        {...register('name', { required: true })}
                    />
                </div>
                <div className="col col-double">
                    <span>{t('cart.address')}  <span className="icon icon-required" /></span>

                    {(!cartGroup || cartGroup?.address) && <Controller
                        control={control}
                        name={`address`}
                        render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Address
                            ref={autoCompleteRef}
                            value={value || ""}
                            onChange={({ target: { value } }) => {
                                onChange(value)
                                setValue('location', undefined)
                            }}
                            onPlaceSelected={({ geometry }) => {
                                const { lng, lat } = geometry?.location?.toJSON() ?? {}
                                const sofiaAvailableDistance = getDistanceFromLatLonInKm(SOFIA_COORDS.latitude, SOFIA_COORDS.longitude, lat, lng)
                                const plovdivAvailableDistance = getDistanceFromLatLonInKm(PLOVDIV_COORDS.latitude, PLOVDIV_COORDS.longitude, lat, lng)

                                if (sofiaAvailableDistance <= 13 || plovdivAvailableDistance <= 13) {
                                    setValue('address', autoCompleteRef.current.value)
                                    setValue('location', geometry ? { type: 'Point', coordinates: [lng, lat] } : undefined)

                                    if (sofiaAvailableDistance <= 13) setValue('cityName', 'София')
                                    else if (plovdivAvailableDistance <= 13) setValue('cityName', 'Пловдив')

                                    if (geometry) clearErrors('address')
                                    else setError('address', { type: "custom", message: "Missing coordinates" })

                                } else {
                                    Alerts.error({ title: t('reservation.invalidAddress'), text: t('reservation.addressOutOfZone') });
                                    setValue('address', '')
                                    setValue('location', undefined)
                                }
                            }}
                            className={error ? "invalid" : ''}
                        />}
                        rules={{ required: true }}
                    />}
                </div>
                <div className="col">
                    <span>{t('cart.partnerTax')} <span className="icon icon-required" /></span>
                    <div className="row">
                        <Inputs.Text
                            className={errors.locationCommission ? "invalid" : ''}
                            {...register('locationCommission', {
                                required: true,
                                pattern: locationCommissionType === 'fixed'
                                    ? /^\d*$/ : /^\d{1,2}$/
                            })}
                            label={locationCommissionType === 'fixed' ? t('shared.lv') : "%"}
                        />
                        <Controller
                            control={control}
                            name={`locationCommissionType`}
                            render={({ field: { value, onChange } }) => <Inputs.Switch
                                labelOff="%"
                                labelOn={t('shared.lv')}
                                value={value === 'percent'}
                                onChange={() => onChange(locationCommissionType === 'fixed' ? 'percent' : 'fixed')} />}
                        />

                    </div>

                </div>
            </div>
            <div className="working-hours-container">
                <span>{t('cart.workingHours')} <span className="icon icon-required" /></span>
                <div className="row">
                    {times(7).map((i, index) => <div
                        key={`single-day-${index}`}
                        className="single-day-container row">
                        <span>{moment().startOf('week').add(i, 'days').format('ddd')}</span>
                        <Controller
                            control={control}
                            name={`workingHours.${index}.opensAt`}
                            rules={{ required: workingHours?.[index]?.open, pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]/ }}
                            render={({ field: { onChange, value, ...rest } }) => <Inputs.Datepicker
                                value={value ? moment(value, 'HH:mm') : null}
                                placeholder="-- : --"
                                dateFormat="HH:mm"
                                className={errors?.workingHours?.[index]?.opensAt ? "invalid" : ''}
                                disabled={!workingHours?.[index]?.open}
                                onChange={(value) => onChange(moment(value).format("HH:mm"))}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                            />}
                        />
                        - <Controller
                            control={control}
                            name={`workingHours.${index}.closesAt`}
                            rules={{ required: workingHours?.[index]?.open, pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]/ }}
                            render={({ field: { onChange, value, ...rest } }) => <Inputs.Datepicker
                                value={value ? moment(value, 'HH:mm') : null}
                                placeholder="-- : --"
                                dateFormat="HH:mm"
                                className={errors?.workingHours?.[index]?.closesAt ? "invalid" : ''}
                                disabled={!workingHours?.[index]?.open}
                                onChange={(value) => onChange(moment(value).format("HH:mm"))}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                            />}
                        />
                        <Controller
                            control={control}
                            name={`workingHours.${index}.open`}
                            render={({ field: { value, onChange } }) => <Inputs.Switch
                                value={value}
                                onChange={() => {
                                    onChange(!value)
                                    if (isSubmitted) setTimeout(() => trigger('workingHours'), 100)
                                }} />}
                        />
                        <div className="placeholder" />
                    </div>)}
                </div>
            </div>
        </div>
        <div className="cart-group-form-footer row">
            {cartGroup ? <>
                <Buttons.Raised className="btn-delete" text={t('shared.delete')} onClick={handleSubmit(handleDelete)} />
                <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
            </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
        </div>
    </div>
}

export default CartGroupForm