/* eslint-disable react-hooks/exhaustive-deps */
import { emit, setReservationsFields } from "actions"
import { Buttons, Shared } from "components"
import { reservationsColumns, reservationStatuses } from "config/constants"
import { useQuery } from "hooks"
import { isEmpty, debounce } from "lodash"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import { xlsxExport, renderCell, mapSearchQuery } from "utilities"
import { useTranslation } from "react-i18next";
import "./styles.scss"

const Reservations = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const availableFilters = {
        status: { type: 'idDropdown', values: Object.entries(reservationStatuses).map(([key, value]) => ({ _id: key, name: t(value) })), single: true },
        orderedFrom: { type: 'idDropdown', values: [{ name: 'Amarant', _id: 'amarant' }, { name: 'MobiWash', _id: 'geowash' }, { name: t('renderCell.manual'), _id: 'manual' },], single: true },
        reservationStart: { type: 'date' },
        phoneNumber: { type: 'regex' },
        city: { type: 'boolean' }
    }
    const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapSearchQuery(sort, filter, availableFilters) }), [sortParam, filterParam])

    const { reservations, page, nextPage, hasNextPage, stats } = useSelector(({ reservations }) => reservations)
    const { mobileReservations, stationaryReservations, total, totalNet, totalReservations, } = stats || {}

    const fetch = ({ pageNumber, pagination = true, onSuccess = () => { } } = {}) => emit({
        action: 'reservation/browse',
        payload: { pageSizeLimit: 100, pageNumber: pageNumber || nextPage, stats: page > 1 ? stats : {}, pagination, ...searchQuery },
        startLoading: isEmpty(reservations) || nextPage !== 1,
        stopLoading: true,
        onSuccess
    })
    const fetchDebounced = debounce(fetch, 300)

    const dispatch = useDispatch()
    useEffect(() => { fetch({ pageNumber: 1 }) }, [searchQuery])
    useEffect(() => { emit({ action: 'room/join', payload: { room: 'reservations' } }) }, [])
    useEffect(() => () => {
        emit({ action: 'room/leave', payload: { room: "reservations" } })
        dispatch(setReservationsFields({ nextPage: 1, hasNextPage: true }))
    }, [dispatch])


    const sortingComponents = reservationsColumns?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
        return {
            ...acc,
            [value]: <Popup
                className="popup-shared-sorting-component"
                trigger={<div className="icon icon-arrow-down" />}
                position="bottom left"
                offsetY={5}
            >
                {close => <Shared.SortingComponent
                    hide={close}
                    column={value}
                    availableFilters={availableFilters}
                    keyMap={{ "reservationStart": 'reservationStartDate' }}
                />}
            </Popup>
        }
    }, {})

    return <div className="screen-reservations-container">
        <div className="screen-reservations-inner-container">
            <div className="screen-reservations-content">
                <Shared.Table
                    columns={reservationsColumns}
                    data={reservations}
                    renderCell={(reservation, field) => renderCell.reservations(reservation, field, { navigate, t })}
                    handlePagination={() => hasNextPage && fetchDebounced()}
                    headerHight={65}
                    sortingComponent={sortingComponents}
                />
            </div>
            <div className="screen-reservations-footer row">
                <div className="row row-stats">
                    <p>{t('reservations.orders')}: <span>{totalReservations}</span> ({t('shared.mobile')} <span>{mobileReservations}</span>, {t('shared.stationary')} <span>{stationaryReservations}</span>)</p>
                    <p>{t('reservations.totalAmount')}: <span>{total} {t('reservations.lv')}</span> ({t('reservations.netIncome')} <span>{totalNet} {t('reservations.lv')}</span>) </p>
                </div>
                <div className="row row-buttons">
                    <Buttons.Raised text={t('reservations.createReservation')} onClick={() => navigate(`/reservation/form`)} />
                    <div className="icon icon-export" onClick={() => fetch({
                        pagination: false,
                        onSuccess: (response) => xlsxExport({
                            fields: reservationsColumns.toSpliced(13, 0, { value: 'address', label: t('reservation.address') }),
                            data: response.reservations,
                            render: renderCell.reservations,
                            fileName: `reservations`,
                            t
                        })
                    })} />
                </div>
            </div>
        </div>
    </div>
}

export default Reservations