import { default as Raised } from './Raised'
import { default as Back } from './Back'
import { default as Upload } from './Upload'

const Buttons = {
    Raised,
    Back,
    Upload
}

export default Buttons