import { forwardRef } from "react"
import "./styles.scss"

const Text = forwardRef(({ label, className = "", inputClassName = "", ...props }, ref) => {
    return <div className={`inputs-text-container row ${className} ${label ? 'with-label' : ''}`}>
        <input ref={ref} className={`inputs-text ${inputClassName}`} {...props} />
        {label && <div className="label-container row"><p>{label}</p></div>}
    </div>
})

export default Text