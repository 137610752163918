import { Buttons, Inputs } from "components"
import { Controller, useForm, useWatch } from "react-hook-form";
import "./styles.scss"
import { useTranslation } from "react-i18next";

const UploadOdometerData = ({ onSuccess, close }) => {
    const { t } = useTranslation()
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const pictures = useWatch({ control, name: 'pictures' }) || []

    return <div className="modal-upload-odometer-data-container">
        <div className="modal-upload-odometer-data-header row">
            <h2>{t('modalUploadOdometerData.title')}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-upload-odometer-data-content">
            <div className="col">
                <span>{t('modalUploadOdometerData.millageByOdometer')}</span>
                <Inputs.Text
                    label={t('shared.km')}
                    className={errors.odometer ? "invalid" : ''}
                    {...register("odometer", { required: true, pattern: /^\d*$/ })}
                />
            </div>
            <div className="row row-pictures">
                <span className={errors.pictures && 'invalid'}>{t('modalUploadOdometerData.photo')}: </span>
                <Controller control={control} name={`pictures`} rules={{ required: true }}
                    render={({ field: { onChange } }) =>
                        <Buttons.Upload onChange={(pictures) => onChange([...pictures])}>
                            <div className="icon icon-plus" />
                        </Buttons.Upload>
                    }
                />
                <div className="row row-files">
                    {pictures?.map(({ name, url }, i) => <div
                        key={`file-${i}`}
                        className="single-file row">
                        <a href={url} target="_blank" rel="noreferrer">{name}</a>
                        <Controller control={control} name={`pictures`}
                            render={({ field: { value, onChange } }) =>
                                <div className="icon icon-remove" onClick={() => {
                                    const newTasks = [...value]
                                    newTasks.splice(i, 1)
                                    onChange(newTasks)
                                }} />
                            }
                        />
                    </div>)}
                </div>
            </div>
        </div>
        <div className="modal-upload-odometer-data-footer row">
            <Buttons.Raised className="cancel" text={t('shared.cancel')} onClick={close} />
            <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(onSuccess)} />
        </div>
    </div>
}

export default UploadOdometerData