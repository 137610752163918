/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from "react-dom/server"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { MAPBOX_API_KEY } from "config/settings";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./styles.scss"
import { isEmpty, partition } from 'lodash';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';

const Map = ({ vehicles }) => {
    const { t } = useTranslation()
    const [vehiclesWithData, vehiclesWithoutData] = useMemo(() => {
        return partition(vehicles, (v) => v?.lastDrivingSession?.lastLocation?.coordinates)
    }, [vehicles])

    const coordinates = useMemo(() => vehiclesWithData?.map(({ lastDrivingSession }) => lastDrivingSession?.lastLocation?.coordinates), [vehiclesWithData])
    const map = useRef(null)
    const mapContainer = useRef(null)
    let markers = useRef({})

    useEffect(() => {
        if (map.current) return
        map.current = new mapboxgl.Map({
            accessToken: MAPBOX_API_KEY,
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: coordinates[0],
            zoom: 8,
            attributionControl: false
        })
        map.current.addControl(new mapboxgl.FullscreenControl());
    }, [])

    useEffect(() => {
        if (!map.current) return
        map.current.on('load', () => {
            const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
            for (const coord of coordinates) { bounds.extend(coord); }
            map.current.fitBounds(bounds, { padding: 50 });
        })
    }, [])
    useEffect(() => {
        if (!map.current) return
        vehiclesWithData.forEach(({ _id, lastDrivingSession, carMake, licensePlate }) => {
            const coordinates = lastDrivingSession?.lastLocation?.coordinates
            if (!coordinates) return
            const inUse = lastDrivingSession && !lastDrivingSession.drivingEnd
            if (markers.current[_id]) markers.current[_id].remove()

            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                renderToStaticMarkup(<div className={`fleet-marker-popup-container ${inUse && 'active'}`}>
                    <h2 className="row">{carMake} | {licensePlate}</h2>
                    {!inUse && <p className="">{lastDrivingSession?.endAddress}</p>}
                </div>)
            ).setMaxWidth('250px');
            const newMarker = new mapboxgl.Marker(getMarker(_id, inUse)).setLngLat(coordinates).setPopup(popup).addTo(map.current);
            markers.current[_id] = newMarker

        })
    }, [vehiclesWithData])

    const getMarker = (_id, inUse) => {
        const el = document.createElement('div')
        el.classList.add(`marker`,)
        if (inUse) {
            el.classList.add(`active`, `vehicle-${_id}`)
            el.innerHTML = `<div class="icon icon-vehicle-green" />`
        } else el.innerHTML = `<div class="icon icon-vehicle" />`
        return el
    }

    return <div className="fleet-map-container" style={{ height: '100%', width: '100%' }}>
        <div ref={mapContainer} className="map-container" style={{ height: '100%', borderRadius: "6px" }} />
        {!isEmpty(vehiclesWithoutData) && <div className="vehicles-without-data-container row">
            {t('fleet.emptyState')}:
            <div className="scroll-container row">
                {vehiclesWithoutData.map(({ _id, carMake, licensePlate, lastDrivingSession }) =>
                    <Popup
                        key={_id}
                        trigger={<div className={`marker vehicle-${_id}`}>
                            <div className="icon icon-vehicle no-active" />
                        </div>}
                        position='top center'
                        className='popup-leet-marker-popup-container'
                        on={['hover', 'focus']}
                        arrow={false}
                        offsetY={10}
                    >
                        <div className={`fleet-marker-popup-container row`}>
                            <h2 className="row">{carMake} | {licensePlate}</h2>
                            <p className="">{lastDrivingSession?.endAddress}</p>
                        </div>
                    </Popup>)}
            </div>
        </div>}
    </div>
}

export default Map