import { Buttons, Inputs } from "components"
import { useState } from "react"
import "./styles.scss"
import { useTranslation } from "react-i18next"

const UploadOdometerDataReason = ({ close, onSuccess }) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState('')
  return <div className="modal-upload-odometer-data-reason-container">
    <div className="modal-upload-odometer-data-reason-header row">
      <h2>{t('modalUploadOdometerDataReason.title')}</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    <div className="modal-upload-odometer-data-reason-content">
      <h3>{t('modalUploadOdometerDataReason.description')}</h3>
      <span>{t('modalUploadOdometerDataReason.reason')}</span>
      <Inputs.Text value={reason} onChange={({ target: { value } }) => setReason(value)} />
    </div>
    <div className="modal-upload-odometer-data-reason-footer row">
      <Buttons.Raised className="cancel" text={t('shared.cancel')} onClick={close} />
      <Buttons.Raised text={t('shared.create')} onClick={() => onSuccess(reason)} disabled={!reason} />
    </div>
  </div>
}

export default UploadOdometerDataReason