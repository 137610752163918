import store, { setModal } from "actions";
import {
    reservationStatuses,
    reservationSelectedPaymentMethods,
    cartTypes,
    cartStatuses,
    discountCodeTypes,
    reservationPaymentMethods
} from "config/constants";
import { min } from "lodash";
import moment from "moment";
import Popup from "reactjs-popup";

const reservations = (reservation, field, { navigate, forExport, t } = {}) => {
    let text = ''
    switch (field) {
        case "status":
            text = reservation[field] === 'created' && reservation?.user ? t('reservationStatuses.taken') : t(reservationStatuses[reservation[field]])
            if (forExport) return text
            return <div className="row" style={{ justifyContent: 'space-between', width: '100%', paddingRight: 14 }}>
                {text}
                {reservation?.chat?.unreadMessages > 0 && <div className="unreadMessages-indicator" />}
                <div className="icon icon-edit" style={{ width: 20, height: 20 }} onClick={() => navigate(`/reservation?_id=${reservation._id}`)} />
            </div>
        case 'reservationStartDate':
            return reservation.reservationStart ? moment(reservation.reservationStart).format("DD.MM.YYYY") : ""
        case 'reservationStart':
        case 'cleaningStart':
            return reservation[field] ? moment(reservation[field]).format("HH:mm") : ""
        case 'orderedFrom':
            return reservation.amarantId ? 'Amarant' : reservation.geoWashClient ? 'MobiWash' : t('renderCell.manual')
        case 'phoneNumber':
            return reservation?.clientInfo?.[field] || reservation?.client?.[field] || ""
        case 'clientVehicles':
            return reservation?.clientVehicles
                ?.map(({ carMake, licensePlate }) => carMake || licensePlate)
                ?.filter(Boolean)
                ?.join(", ") || ""
        case 'client':
            return reservation?.clientInfo?.fullName || reservation?.[field]?.fullName || ''
        case 'user':
            return reservation?.[field]?.fullName || ''
        case 'vehicle':
            return [reservation?.vehicle?.carMake, reservation?.vehicle?.licensePlate].filter(Boolean).join(" | ")
        case 'cartBySystem':
            if (!reservation?.[field]) return null
            return <div className="row" style={{ justifyContent: 'space-between', width: '100%', paddingRight: 14 }}>
                {reservation?.[field]?.identifier}
                {['pendingAmountBlocking', 'created'].includes(reservation.status) && <div
                    className="icon icon-edit"
                    style={{ width: 20, height: 20 }}
                    onClick={() => store.dispatch(setModal({ isOpen: true, type: 'ReservationForward', props: { reservation } }))}
                />}
            </div>
        case 'paymentMethod':
            if (reservation?.organization) return ""
            if (!reservation.selectedPaymentMethod) return t(reservationPaymentMethods[reservation[field]])
            return `${t(reservationSelectedPaymentMethods[reservation.selectedPaymentMethod])}${reservation?.selectedPaymentMethod === 'manual' && reservation?.paymentStatus === 'paid' ? ` - ${t(reservationPaymentMethods[reservation[field]])}` : ''}`
        case 'reservationType':
            return t(cartTypes[reservation[field]])
        case 'cartGroup':
            return reservation?.cartGroup?.name
        case 'cart':
            return reservation?.cart?.identifier

        case "expectedDuration":
        case "extended":
        case "duration":
            return (reservation?.[field] || 0) + ` ${t('renderCell.minutes')}`

        case "price":
            return (reservation?.cancellationPrice ?? reservation?.[field] ?? "--") + ` ${t('renderCell.lv')}`
        case "stripeTax":
        case "partnerTax":
        case "amarantTax":
        case "net":
            return reservation?.[field] + ` ${t('renderCell.lv')}`
        case 'ratings':
            return reservation?._ratings?.[0]?.rating ? reservation?._ratings?.[0]?.rating + '/5' : ""
        case 'chat':
            text = `${reservation?.chat?.messageCount ?? 0} ${t('renderCell.messages')}`
            if (forExport) return text
            return <div className="row">
                {text}
                <div
                    className="icon icon-arrow-right-white"
                    onClick={() => navigate && navigate(`/reservation/chat?_id=${reservation?.chat?._id}&reservation=${reservation?._id}`)}
                />
            </div>
        case 'notes':
            text = `${reservation?.notes?.length ?? 0}  ${t('renderCell.notes')}`
            if (forExport) return text
            return <div className="row">
                {text}
                <div
                    className="icon icon-arrow-right-white"
                    onClick={() => navigate && navigate(`/reservation/notes?_id=${reservation?._id}`)}
                />
            </div>
        case "organization":
            return reservation?.[field]?.name
        case "discountCampaign":
            if (!reservation?.[field]?.discountAmount) return ""
            if (reservation?.[field]?.discountInPercent) return <span className="row">{(reservation?.[field]?.discountAmount * 100) + "%"}
                <Popup
                    on={['hover']}
                    contentStyle={{ width: 'auto' }}
                    trigger={<span style={{ display: 'inline-block', height: 20, width: 20, marginLeft: 8 }} className="icon icon-info" />}
                >{reservation?.[field]?.name}</Popup>
            </span>
            return <span className="row">{reservation?.[field]?.discountAmount + ` ${t('renderCell.lv')}`}
                <Popup
                    on={['hover']}
                    contentStyle={{ width: 'auto' }}
                    trigger={<span style={{ display: 'inline-block', height: 20, width: 20, marginLeft: 8 }} className="icon icon-info" />}
                >{reservation?.[field]?.name}</Popup>
            </span>
        case 'city':
            return reservation?.city?.name ?? ''
        default:
            return reservation[field] || ""
    }
}


const carts = (cart, field, { navigate, t }) => {
    switch (field) {
        case 'cartGroup':
            return cart?.[field]?.name
        case "status":
            return t(cartStatuses[cart[field]])
        case "cartType":
            return t(cartTypes[cart[field]])
        case 'identifier':
            return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/cart?_id=${cart?._id}`)}>
                <div className="icon icon-open" style={{ minWidth: 20, height: 20, width: 20, marginRight: 8 }} />
                {cart[field]}
            </div>
        case 'employee':
            return cart[field]
        case 'vehicle':
            return [cart?.vehicle?.carMake, cart?.vehicle?.licensePlate].filter(Boolean).join(" | ")
        case 'services':
            const minPrice = min(cart.services.filter(({ vehicleType }) => vehicleType).map(({ service }) => service?.price))
            return minPrice + ` ${t('renderCell.lv')}`
        default:
            return cart[field] || ""
    }
}


const services = (service, field, { t }) => {
    switch (field) {
        case 'vehicleType':
            return service?.vehicleType?.name
        case "duration":
        case "breakAfter":
            return service?.[field] + ` ${t('renderCell.minutes')}`
        case "price":
            return service?.[field] + ` ${t('renderCell.lv')}`
        default:
            return service[field] || ""
    }
}


const employees = (employee, field, { navigate, t }) => {
    switch (field) {
        case "fullName":
            return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/employee?_id=${employee?._id}`)}>
                <div className="icon icon-profile" style={{ height: 20, width: 20, marginRight: 8 }} />
                {employee[field]}
            </div>
        case 'cartGroup':
            return employee?.[field]?.name
        case "cartType":
            return t(cartTypes[employee[field]])
        case 'lastCart':
            return employee?._workDays?.at(0)?.cart?.identifier
                ? <div
                    className="row"
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => navigate && navigate(`/cart?_id=${employee?._workDays?.at(0)?.cart?._id}`)}>
                    <div className="icon icon-open" style={{ height: 20, width: 20, marginRight: 8 }} />
                    {employee?._workDays?.at(0)?.cart?.identifier}
                </div>
                : ""
        case "grossSalary":
        case "netSalary":
            return employee?.[field] ? employee?.[field] + ` ${t('renderCell.lv')}` : ""
        case "holidays":
            return employee?.[field] ? employee?.[field] + ` ${t('renderCell.days')}` : ""
        default:
            return employee[field] || ""
    }
}

const users = (user, field, { navigate, t }) => {
    switch (field) {
        case 'role':
            return user[field] === 'admin' ? t('users.admin') : t('users.team')
        case 'fullName':
            return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/user?_id=${user?._id}`)}>
                <div
                    className="icon icon-profile"
                    style={{ height: 20, width: 20, marginRight: 8 }}
                />
                {user[field]}
            </div>
        default:
            return user[field]
    }
}

const employeeStats = (employee, field, { t }) => {
    switch (field) {
        case "day":
            return employee.workStart ? moment(employee.workStart).format("DD.MM.YYYY") : ""
        case "workStart":
        case "workEnd":
            return employee[field] ? moment(employee[field]).format("HH:mm") : ""
        case "workDayDuration":
        case "breaksDuration":
            return Math.trunc(employee?.[field] / 60) + ` ${t('renderCell.hours')} ` + employee?.[field] % 60 + ` ${t('renderCell.min')}`
        case "reservations":
            return employee[field] + ` ${t('renderCell.orders')}`
        default:
            return employee[field]
    }
}

const cartHistory = (history, field, { navigate, t }) => {
    switch (field) {
        case 'date':
            return history?.createdAt ? moment(history.createdAt).format('DD.MM.YYYY') : ""
        case 'time':
            return history?.createdAt ? moment(history.createdAt).format('HH:mm') : ""
        case 'user':
            return history?.user?.fullName ? <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/user?_id=${history?.user?._id}`)}>
                <div
                    className="icon icon-profile"
                    style={{ height: 20, width: 20, marginRight: 8 }}
                />
                {history?.user?.fullName}
            </div> : ""
        case 'status':
            return t(cartStatuses[history.status]) || "-- --"
        default:
            return ""
    }
}

const discountCodes = (discountCode, field, { navigate, t }) => {
    switch (field) {
        case "name":
            return discountCode?.[field] ?? ""
        case "status":
            if (moment().isBefore(moment(discountCode?.campaignStart))) return t('discountCode.upcoming')
            else if (moment().isAfter(moment(discountCode?.campaignEnd))) return t('discountCode.expired')
            else return t('discountCode.active')
        case "campaignStart":
        case "campaignEnd":
            return discountCode?.[field] ? moment(discountCode?.[field]).format("DD.MM.YYYY") : ""
        case "discountAmount":
            if (discountCode?.discountInPercent) return `${discountCode?.[field] * 100} %`
            else return `${discountCode?.[field]} ${t('shared.lv')}`
        case "discountCondition":
            if (discountCode?.discountCondition === 0) return t('discountConditionTypes.0')
            else if (discountCode?.discountCondition === 1) return t('discountConditionTypes.1')
            else return t('discountConditionTypes.5')
        case "codeType":
            return t(discountCodeTypes.find(({ value }) => value === discountCode?.[field])?.label) || ""
        case "_discountCodesCount":
            return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/discount-code?_id=${discountCode?._id || "1"}`)}>
                <div className="icon icon-info" style={{ height: 20, width: 20, marginRight: 8 }} />
                {discountCode?.[field] ?? 0}
            </div>
        default:
            return ""
    }
}

const discountCode = (discountCode, field, { navigate, handleDelete, t } = {}) => {
    switch (field) {
        case "code":
            return <div className="row" style={{ width: '100%' }} >
                {discountCode?.[field] ?? ""}
                {!discountCode?.deletedAt && !discountCode.disabledAt && <div
                    onClick={() => handleDelete(discountCode?._id)}
                    className="icon icon-delete"
                    style={{ height: 20, width: 20, marginLeft: 'auto', marginRight: 8 }}
                />}
            </div>
        case "_reservationsCount":
            return `${discountCode?._reservationsCount} ${t(discountCode?._reservationsCount === 1 ? 'renderCell.time' : 'renderCell.times')}${discountCode?.deletedAt ? ` (${t('discountCode.revoked')})` : ""}`
        case "phoneNumber":
            if (discountCode?.usedBy?.length > 1) return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => store.dispatch(setModal({ isOpen: true, type: 'DiscountCodeUsers', props: { discountCode } }))}
            >
                <div className="icon icon-info" style={{ height: 20, width: 20, marginRight: 8 }} />
                {discountCode?.usedBy?.length}{" "}{t('discountCode.users')}
            </div>

            return discountCode?.usedBy?.[0]?.[field] ? <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => navigate && navigate(`/?filter=%7B"phoneNumber"%3A"%2B${discountCode?.usedBy?.[0]?.[field].slice(1)}"%7D`)}>
                <div className="icon icon-history" style={{ height: 25, width: 25, marginRight: 8 }} />
                {discountCode?.usedBy?.[0]?.[field]}
            </div> : "--"
        case "fullName":
            if (discountCode?.usedBy?.length > 1) return <div
                className="row"
                style={{ cursor: 'pointer', width: '100%' }}
                onClick={() => store.dispatch(setModal({ isOpen: true, type: 'DiscountCodeUsers', props: { discountCode } }))}
            >
                <div className="icon icon-info" style={{ height: 20, width: 20, marginRight: 8 }} />
                {discountCode?.usedBy?.length}{" "}{t('discountCode.users')}
            </div>

            return discountCode?.usedBy?.[0]?.[field] ?? "--"
        default:
            return ""
    }
}

const discountCodeExport = (discountCode, field) => {
    switch (field) {
        case 'code':
            return discountCode?.[field]
        case 'phoneNumber':
        case "fullName":
            return discountCode?.usedBy?.[0]?.[field]
        default:
            return renderCell.discountCode(discountCode, field)
    }
}

export const businessClients = (businessClient, field, { navigate }) => {
    switch (field) {
        case 'files':
            return businessClient?.[field]?.length
        case 'name':
        case '_clientsCount':
        case '_commentsCount':
            return businessClient?.[field]
        case 'open':
            return <div className="row" style={{ width: '100%', paddingRight: 14, justifyContent: "center" }}>
                <div
                    className="icon icon-open"
                    onClick={() => navigate && navigate(`/business-client?_id=${businessClient?._id}`)}
                    style={{ minWidth: 20, height: 20, width: 20 }}
                />
            </div>
        default:
            return ""
    }
}

export const renderCell = {
    reservations,
    carts,
    services,
    employees,
    users,
    employeeStats,
    cartHistory,
    discountCodes,
    discountCode,
    discountCodeExport,
    businessClients
}